import * as React from "react";
import { useTranslation } from "react-i18next";

import { MANUAL_ENTRY_TABLE_NUM_ROWS } from "../constants/constants";
import TableWrapper from "./TableWrapper";
import PrimaryText from "./styledComponents/PrimaryText";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { initializeForManualInput } from "../thunks/initial_data";
import { selectMatchableFieldSpecs } from "../store/reducers/fields";

interface IManualInputTable {
  manualInputOnly?: boolean;
}

function generateEmptyTableData(numColumns: number): any[][] {
  const data: any[][] = [];
  for (let i = 0; i < MANUAL_ENTRY_TABLE_NUM_ROWS; i++) {
    data.push(Array(numColumns));
  }

  return data;
}

function ManualInputTableWrapper(props: IManualInputTable): React.ReactElement {
  const dispatch = useAppDispatch();
  const rowLength = useAppSelector(
    (state) => selectMatchableFieldSpecs(state.fields).length
  );
  const tableData = generateEmptyTableData(rowLength);
  const { t } = useTranslation();

  return (
    <div>
      {props.manualInputOnly ? null : (
        <PrimaryText className="modalBodyElement" as="h5">
          {t("v1.dataUploadModal.manualInputTableWrapper.enterData")}
        </PrimaryText>
      )}
      <TableWrapper
        v1ManualInput={true}
        tableData={tableData}
        onCellDataChange={(data, changes, selectedCell) =>
          dispatch(initializeForManualInput(data, changes, selectedCell))
        }
      />
    </div>
  );
}

export default ManualInputTableWrapper;
