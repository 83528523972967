import * as React from "react";
import { RootState } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";

interface ISecondaryTextProps {
  as: string;
}

const mapState = (state: RootState) => {
  return {
    styleOverrides: state.settings.styleOverrides,
  };
};

const reduxConnector = connect(mapState, {});
type ISecondaryTextFullProps = ISecondaryTextProps &
  React.HTMLAttributes<HTMLDivElement> &
  ConnectedProps<typeof reduxConnector>;

function SecondaryText(props: ISecondaryTextFullProps) {
  const elementProps = {
    style: {
      color: props.styleOverrides.global.secondaryTextColor,
      ...(props.style || {}),
    },
    className: props.className,
  };

  return React.createElement(props.as, elementProps, props.children);
}

SecondaryText.defaultProps = {
  as: "span",
};

export default reduxConnector(SecondaryText);
