import { enableMapSet } from "immer";
import { combineReducers } from "redux";
import modalsReducer from "./reducers/modals";
import coredataReducer from "./reducers/coredata";
import settingsReducer from "./reducers/settings";
import commonComponentsReducer from "./reducers/commonComponents";
import errorsReducer from "./reducers/errors";
import fieldsReducer from "./reducers/fields";

enableMapSet();

export const rootReducer = combineReducers({
  modals: modalsReducer,
  coredata: coredataReducer,
  fields: fieldsReducer,
  settings: settingsReducer,
  commonComponents: commonComponentsReducer,
  errors: errorsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
