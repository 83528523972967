import * as React from "react";

import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";

import { RootState } from "../../store/reducers";
import { ICommonComponentsOpenModal } from "../../store/reducers/commonComponents";
import { useTranslation } from "react-i18next";

export default function ProcessingModal() {
  const isShowing = useSelector(
    (state: RootState) =>
      state.commonComponents.openModal === ICommonComponentsOpenModal.PROCESSING
  );
  const { t } = useTranslation();

  return (
    <Modal
      show={isShowing}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="fileUploadModal"
      enforceFocus={false}
      autoFocus={false}
    >
      <Modal.Body>
        <div>
          <span>
            <Spinner animation="border" role="status" />
            <span className="fileUploadSpinnerText">
              {t("common.processing")}
            </span>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
}
