import { FileWithPath } from "react-dropzone";
import { IDeveloperField, ITableMessage, IDeveloperSettings } from ".";

export type TBackendType = "AWS" | "AZURE";

export interface ITableMessageInternal extends ITableMessage {
  type: "user-generated" | "field-transform" | "validation";
  // user-generated: added by the user in a hook
  // field-transform: the raw value could not be parsed by the field's transform function
  // validation: generated by a failed validation on a field
}

export type ITableMessages = Map<string, ITableMessageInternal[]>;

// Uploaded file data & metadata interfaces

export type UPLOAD_TYPE =
  | "FILE"
  | "MANUAL_INPUT"
  | "INITIAL_DATA_MAPPED"
  | "INITIAL_DATA_UNMAPPED";

export interface IData {
  // Can either be a "File" or "String" which
  // represents a remote file URL
  file: FileWithPath | string | null;
  uploadType: UPLOAD_TYPE;
  valCountsInColumn: Map<number, Map<string, number>> | null;
  percentHasValueInColumn: Map<number, number> | null;
  previewData: any[][];
  rawPreviewData: any[][];
}

export interface INewCellValue {
  row: number;
  column: number;
  value: any;
}

export interface INewCellData {
  row: number;
  column: number;
  value: any;
}

export interface IProgress {
  message?: string;
  count: number;
}

export interface IBackendCapabilities {
  accept_json_results: boolean;
  write_only_storage: boolean;
  saved_schema_only: boolean;
  ai_matching: boolean;
  transform_data: boolean;
}

export interface ISavedSchema {
  id: string;
  name: string;
  fields: IDeveloperField[];
  settings: Partial<IDeveloperSettings>;
}

export interface IWorksheet {
  id: string;
  label: string;
}

export interface IRowMeta {
  originalIndex: number | null;
  rowId: string;
}

// a map of new table messages that will be added to the table.
// keys are cell keys in the form of "rowIndex,colIndex"
export type INewTableMessages = Map<string, ITableMessageInternal[]>;

export enum MATCH_TYPES {
  AI_SUGGESTED = "columnMatchModalCard.aiSuggestedChip",
  AUTO_MATCHED = "columnMatchModalCard.autoMatchedChip",
  USER_MATCHED = "columnMatchModalCard.userMatchedChip",
  UNMATCHED = "columnMatchModalCard.unmatched",
  IGNORED = "columnMatchModalCard.ignoredChip",
}
