import {
  textRenderer,
  checkboxRenderer,
  autocompleteRenderer,
} from "handsontable/renderers";

import { BaseEditor, TextEditor } from "handsontable/editors";
import type { CellProperties } from "handsontable/settings";

import { IField, IAbstractField } from ".";
import { CheckboxField } from "./checkbox";

type IHotRenderer = (
  instance: any,
  td: any,
  row: any,
  col: any,
  prop: any,
  value: any,
  cellProperties: any
) => void;

interface IHotColumn {
  renderer: IHotRenderer;
  editor: string | typeof BaseEditor;
  validator?: string;
  checkedTemplate?: string;
  uncheckedTemplate?: string;
  source?: (_query: string, callback: (options: string[]) => void) => void;
  trimDropdown?: boolean;
  strict?: boolean;
  filter?: boolean;
}

/**
 * Takes a field and returns a column spec that can be passed to Handsontable.
 * https://handsontable.com/docs/cell-function/
 */
export function buildHotColumn(
  field: IField,
  hasTransformError: (key: string) => boolean
): IHotColumn {
  switch (field.type) {
    case "checkbox":
      return {
        renderer: wrapRenderer(field, checkboxRenderer, hasTransformError),
        editor: "checkbox",
        checkedTemplate: CheckboxField.CHECKED_VALUE,
        uncheckedTemplate: CheckboxField.UNCHECKED_VALUE,
      };
    case "select":
      return {
        renderer: wrapRenderer(field, autocompleteRenderer, hasTransformError),
        editor: "autocomplete",
        trimDropdown: false,
        strict: !field.opts.allowCustom,
        filter: false,
        source: function (_query, callback) {
          // we're using `this` here because handsontable binds the cell row/col instead of passing as arguments, who knows why
          // https://github.com/handsontable/handsontable/blob/develop/handsontable/src/editors/autocompleteEditor/autocompleteEditor.js#L177
          // 'this' introspected to have row, col, visualRow, visualCol, etc
          const { row }: any = { ...this };
          let labels = field.labels;
          if (field.selectOptionOverrideMap.has(row)) {
            labels = [...field.selectOptionOverrideMap.get(row)!.keys()];
          }
          callback(labels);
        },
      };
    default:
      return {
        renderer: wrapRenderer(field, textRenderer, hasTransformError),
        editor: buildTextEditor(field, hasTransformError),
      };
  }
}

/**
 * Wraps a Handsontable rendering function to fetch the value
 * using the field's getDisplayValue function, unless there was a
 * transform error in which case we just show the raw value
 */
function wrapRenderer(
  field: IAbstractField,
  renderer: IHotRenderer,
  hasTransformError: (key: string) => boolean
): IHotRenderer {
  return function (
    this: any,
    instance: any,
    td: any,
    visualRow: any,
    visualCol: any,
    prop: any,
    value: any,
    cellProperties: any
  ) {
    const physicalRow = instance.toPhysicalRow(visualRow);
    const physicalCol = instance.toPhysicalColumn(visualCol);

    const key = `${physicalRow},${physicalCol}`;
    let displayValue: string;

    if (hasTransformError(key)) {
      displayValue = value;
    } else {
      try {
        displayValue = field.getDisplayValueChecked(value ?? "", physicalRow);
      } catch {
        // we might end up here when a user enters an invalid value,
        // but the transform error has not been updated in the state yet
        displayValue = value;
      }
    }

    renderer.apply(this, [
      instance,
      td,
      visualRow,
      visualCol,
      prop,
      displayValue,
      cellProperties,
    ]);
  };
}

const buildTextEditor = (
  field: IField,
  hasTransformError: (key: string) => boolean
): typeof BaseEditor => {
  return class extends TextEditor {
    prepare(
      row: number,
      col: number,
      prop: number | string,
      td: HTMLTableCellElement,
      value: any,
      cellProperties: CellProperties
    ) {
      const physicalRow = this.hot.toPhysicalRow(row);
      const physicalCol = this.hot.toPhysicalColumn(col);

      const key = `${physicalRow},${physicalCol}`;
      let editValue: string;

      if (hasTransformError(key)) {
        editValue = value;
      } else {
        try {
          editValue = field.getEditValueChecked(value ?? "", physicalRow);
        } catch {
          // we might end up here when a user enters an invalid value,
          // but the transform error has not been updated in the state yet
          editValue = value;
        }
      }

      super.prepare(row, col, prop, td, editValue, cellProperties);
    }
  };
};
