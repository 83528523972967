import { EFileUploadStage } from "../constants/EFileUploadStage";

import { AppThunk } from "../store/configureStore";
import { setStage } from "../store/reducers/modals";
import { hideProcessingModal } from "../store/reducers/commonComponents";

import {
  tryAutoMapHeaders,
  tryFullExactMatch,
} from "../thunks/column_matching";
import { VERSION_TWO } from "../constants/constants";
import { setRawDataHeaderRow } from "../store/reducers/coredata";
import { selectMappedSelectSpecs } from "../store/selectors";
import { parseSelectedSheet } from "../thunks/file_processing";

export const nextStepFromUpload = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { headerRowOverride } = state.settings;
    const { data } = state.coredata;

    if (headerRowOverride !== null || data.previewData.length === 1) {
      // if an explicit header row override was set, or if there is only one row,
      // we don't need the user to select the header row, so we can skip the
      // metadata step
      await dispatch(nextStepFromMetadata());
    } else {
      dispatch(setStage(EFileUploadStage.DATA_METADATA));
    }
  };
};

export const nextStepFromMetadata = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const version = getState().settings.version;
    const autoMapSuccessful = await dispatch(tryAutoMapHeaders());
    if (autoMapSuccessful === "HEADERS_AND_SELECT_OPTIONS") {
      dispatch(hideProcessingModal());
      dispatch(setStage(EFileUploadStage.DATA_REVIEW));
      return;
    } else if (
      autoMapSuccessful === "HEADERS_ONLY" &&
      version === VERSION_TWO
    ) {
      dispatch(hideProcessingModal());
      dispatch(setStage(EFileUploadStage.AUTO_CLEAN_DATA));
      return;
    }

    const fullExactMatch = dispatch(tryFullExactMatch());
    if (fullExactMatch) {
      dispatch(hideProcessingModal());
      dispatch(setStage(EFileUploadStage.DATA_REVIEW));
      return;
    }

    dispatch(hideProcessingModal());
    dispatch(setStage(EFileUploadStage.COLUMN_MATCH));
  };
};

export const nextStepFromColumnMatching = (): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const matchedSelectFields = selectMappedSelectSpecs(state);
    // need to have at least one select field that has values to match
    const needsSelectMatching =
      matchedSelectFields.size > 0 &&
      [...matchedSelectFields.keys()].some((colIndex) => {
        const valCounts = state.coredata.data.valCountsInColumn?.get(colIndex);
        return valCounts && valCounts.size > 0;
      });

    const version = state.settings.version;

    if (needsSelectMatching && version === VERSION_TWO) {
      dispatch(setStage(EFileUploadStage.AUTO_CLEAN_DATA));
    } else {
      dispatch(setStage(EFileUploadStage.DATA_REVIEW));
    }
  };
};

const goToMetadataStep = (): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    dispatch(setRawDataHeaderRow(null));
    await dispatch(parseSelectedSheet(true));
    dispatch(setStage(EFileUploadStage.DATA_METADATA));
  };
};

export const previousStepFromColumnMatching = (): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { headerRowOverride } = state.settings;
    const { data } = state.coredata;

    if (headerRowOverride !== null || data.previewData.length === 1) {
      // if an explicit header row override was set, or if there is only one row,
      // we don't need the user to select the header row, so we can skip the
      // metadata step
      dispatch(setStage(EFileUploadStage.DATA_UPLOAD));
    } else {
      await dispatch(goToMetadataStep());
    }
  };
};
