import * as React from "react";
import { ReactComponent as ExclamationIcon } from "../assets/exclamation-2.svg";
import { ReactComponent as FilterIcon } from "../assets/filter.svg";
import { ReactComponent as CloseIcon } from "../assets/remove.svg";
import { twMerge } from "tailwind-merge";
import { useTranslation } from "react-i18next";

export const HeaderErrorFilter = ({
  columnIndex,
  errorsInColumn,
  currentFilter,
  shouldExpandError,
}: {
  columnIndex: number;
  errorsInColumn: number;
  currentFilter?: number | null | "all";
  shouldExpandError: boolean;
}) => {
  const { t } = useTranslation();
  const isActive = currentFilter === columnIndex || shouldExpandError;

  return (
    <button
      className="flex gap-1 !bg-red-50 !px-1 !py-[2px] !border !border-red-50 hover:!border-red-700 ui-active:!border-red-700 !text-red-700 !rounded-full ml-auto cursor-pointer group"
      data-error-filter
      data-cy="column-error-filter"
      data-headlessui-state={isActive ? "active" : undefined}
      title={isActive ? "Remove filter" : "Filter column"}
    >
      <ExclamationIcon className="w-4 h-4 pointer-events-none ui-active:w-0 group-hover:w-0 transition-all overflow-hidden" />
      <span className="text-xs pointer-events-none">{errorsInColumn}</span>
      <span
        className={twMerge(`
          text-xs
          flex
          items-center
          pointer-events-none
          max-w-0
          group-hover:max-w-[8ch]
          ui-active:max-w-[8ch]
          overflow-hidden
          transition-all
        `)}
      >
        {
          t("dataReviewModal.errorWithCount", {
            count: errorsInColumn,
          }).split(" ")[1]
        }
        <FilterIcon
          className={twMerge(
            "h-4 overflow-hidden transition-all ml-[2px]",
            isActive ? "w-4 group-hover:w-0" : "w-4"
          )}
        />
        {isActive && (
          <CloseIcon className="w-0 overflow-hidden group-hover:w-4 h-4 mt-[1px] transition-all ml-[2px]" />
        )}
      </span>
    </button>
  );
};
