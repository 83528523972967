import { Transition } from "@headlessui/react";
import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { ReactComponent as ArrowRight } from "../../assets/arrow-right.svg";
import { ReactComponent as HideIcon } from "../../assets/hide.svg";
import { Button } from "../commonComponents/Button";
import { Select } from "../commonComponents/Select";
import Text from "../commonComponents/Text";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { selectHasEmptyHeaders } from "../../store/reducers/coredata";
import {
  MatchType,
  selectMatchableFieldSpecs,
  mapColumn,
  setColumnIgnored,
} from "../../store/reducers/fields";
import {
  selectDuplicateMatchesForCol,
  selectHeaderNameForCol,
} from "./ui_helpers";
import { IDeveloperField } from "../../interfaces";

export const matchTypeToI18nKey: Record<MatchType, string> = {
  EXACT: "columnMatchModalCard.autoMatchedChip",
  FUZZY: "columnMatchModalCard.aiSuggestedChip",
  AI: "columnMatchModalCard.aiSuggestedChip",
  USER: "columnMatchModalCard.userMatchedChip",
  CUSTOM: "columnMatchModalCard.userMatchedChip",
  AUTOMAP: "columnMatchModalCard.autoMatchedChip",
  VIRTUAL: "",
};

export const ignoredI18nKey = "columnMatchModalCard.ignoredChip";
export const unmatchedI18nKey = "columnMatchModalCard.unmatched";

export default function ColumnMatchCard({
  columnIndex,
  columnData,
}: {
  columnIndex: number;
  columnData: any[];
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);
  const dispatch = useAppDispatch();

  const {
    allowCustom,
    fieldSpec,
    matchType,
    hasEmptyHeaders,
    header,
    matchableFields,
    isIgnored,
    duplicateMatches,
    customHeader,
  } = useAppSelector((state) => {
    const mapping = state.fields.columnMapping.get(columnIndex);

    return {
      allowCustom: state.settings.allowCustomFields,
      fieldSpec: mapping ? state.fields.fieldSpecs.get(mapping.key)! : null,
      matchType: mapping ? mapping.matchType : null,
      hasEmptyHeaders: selectHasEmptyHeaders(state.coredata),
      header: state.coredata.headers
        ? state.coredata.headers[columnIndex]
        : null,
      matchableFields: selectMatchableFieldSpecs(state.fields),
      isIgnored: state.fields.ignoredColumns.has(columnIndex),
      duplicateMatches: selectDuplicateMatchesForCol(state, columnIndex),
      customHeader: selectHeaderNameForCol(state, columnIndex),
    };
  });

  const matchTypeKey: string = isIgnored
    ? ignoredI18nKey
    : matchType
    ? matchTypeToI18nKey[matchType]
    : unmatchedI18nKey;

  useEffect(() => {
    if (hasEmptyHeaders) {
      setIsOpen(true);
    }
  }, [hasEmptyHeaders]);

  const matchCustom = useCallback(
    (name: string) => {
      dispatch(mapColumn(columnIndex, name, "CUSTOM"));
    },
    [dispatch, columnIndex]
  );

  const handleChange = useCallback(
    (field: IDeveloperField) => {
      dispatch(mapColumn(columnIndex, field.key, "USER"));
    },
    [dispatch, columnIndex]
  );

  const handleIgnore = useCallback(() => {
    dispatch(setColumnIgnored(columnIndex, true));
  }, [dispatch, columnIndex]);

  const fieldWithMatchType = fieldSpec
    ? {
        ...fieldSpec,
        matchType: matchTypeKey,
      }
    : undefined;

  const optionsWithMatchType = matchableFields.map((field) => {
    if (field.key === fieldSpec?.key) return fieldWithMatchType;

    return {
      ...field,
      matchType: undefined,
    };
  });

  const duplicateMatchMessage =
    !fieldSpec || duplicateMatches.size === 0
      ? null
      : `${[...duplicateMatches].join(", ")} ${t(
          "columnMatchModal.alreadyMatched",
          { count: duplicateMatches.size }
        )} ${fieldSpec.label}`;

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <section>
      <div
        className="grid grid-cols-2 items-center divide-x divide-ice-300"
        data-cy="column-match-card"
      >
        <div
          className="!px-4 py-2 flex gap-2 items-center text-ice-900"
          onClick={handleToggle}
          role="button"
        >
          <button
            onClick={handleToggle}
            className="!p-1 grid place-items-center hover:bg-ice-100 rounded-lg cursor-pointer"
          >
            <ArrowRight
              className={twMerge(
                "w-6 h-6 transition-all",
                isOpen && "rotate-90"
              )}
            />
          </button>
          {header ? (
            <Text
              type="h1"
              className="text-base"
              data-cy="column-match-card-title"
            >
              {header}
            </Text>
          ) : (
            <Text
              type="h1"
              className="text-base !opacity-30 italic"
              data-cy="column-match-card-title"
            >
              {/* TODO: ADD missing key */}
              No header
              {/* {t("columnMatchModalCard.noHeader")} */}
            </Text>
          )}
        </div>
        <div className="!px-4 py-2 flex gap-7 justify-between">
          <Select
            className={twMerge("!w-full grow")}
            onCreate={allowCustom ? matchCustom : undefined}
            data-cy="column-match-select"
            error={duplicateMatchMessage}
            isIgnored={isIgnored}
            name={`column-match-${columnIndex}`}
            onChange={handleChange}
            options={optionsWithMatchType}
            value={fieldWithMatchType}
            initialCustomValue={customHeader}
          />
          <Button
            theme="ghost"
            onClick={handleIgnore}
            className={twMerge(
              "gap-2 p-0 !text-sm font-medium max-h-10",
              (isIgnored || !fieldSpec) && "opacity-0 pointer-events-none"
            )}
            data-cy="column-match-ignore-button"
          >
            <HideIcon />
            {t("columnMatchModal.ignore")}
          </Button>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition-all overflow-hidden"
        enterFrom="max-h-0"
        enterTo="max-h-96"
        leave="transition-all overflow-hidden"
        leaveFrom="max-h-96"
        leaveTo="max-h-0"
      >
        <div className="border-t border-ice-300 divide-y divide-ice-300 bg-white">
          {columnData.map((data, index) => (
            <div key={`${index}-data`} className="px-4 py-2 h-10">
              <Text type="body" className="text-sm">
                {`${data}` || " "}
              </Text>
            </div>
          ))}
        </div>
      </Transition>
    </section>
  );
}
