import { AppThunk } from "../store/configureStore";
import { EFileUploadStage } from "../constants/EFileUploadStage";
import { setRawDataHeaderRow } from "../store/reducers/coredata";
import { setStage } from "../store/reducers/modals";
import { parseSelectedSheet } from "./file_processing";

export const setModalStage = (stage: EFileUploadStage): AppThunk => {
  return (dispatch) => {
    if (stage === EFileUploadStage.DATA_METADATA) {
      // parse the file again with null headers, this will reset the preview data and reset the header row
      dispatch(setRawDataHeaderRow(null));
      dispatch(parseSelectedSheet(true));
    }

    dispatch(setStage(stage));
  };
};
