import * as React from "react";
import { twMerge } from "tailwind-merge";
import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.css";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckIcon } from "../assets/check.svg";
import PrimaryText from "./styledComponents/PrimaryText";
import { useSettings, useStyles } from "../store/hooks";

interface IModalHeaderProps {
  stepperCompletedIdx: number;
}

const STEPS = [
  "modalHeader.upload",
  "modalHeader.setHeader",
  "modalHeader.matchColumns",
  "modalHeader.review",
  "modalHeader.done",
];

const ModalHeader = (props: IModalHeaderProps) => {
  const { t } = useTranslation();
  const stepperBar = useStyles((styles) => styles.stepperBar)!;
  const { title, importIdentifier: type } = useSettings();

  const renderSteps = () => {
    return (
      <div className="flex flex-wrap sm:flex-no-wrap gap-4 sm:gap-8">
        {STEPS.map((name, idx: number) => {
          const isDone = idx < props.stepperCompletedIdx;
          const isCurrent = idx === props.stepperCompletedIdx;

          const color = isCurrent
            ? stepperBar.currentColor!
            : isDone
            ? stepperBar.completeColor!
            : stepperBar.incompleteColor!;

          return (
            <p
              key={name}
              style={{ color }}
              className={twMerge(
                "flex gap-2 items-center",
                isCurrent && "font-bold",
                isDone && "font-bold"
              )}
            >
              {isDone ? (
                <CheckIcon />
              ) : (
                <span className="w-4 h-4 grid place-items-center bg-currentColor rounded-full">
                  <span className="text-white text-xs">{idx + 1}</span>
                </span>
              )}
              {t(name)}
            </p>
          );
        })}
      </div>
    );
  };

  return (
    <Modal.Header className="modalHeader" closeButton>
      <Modal.Title className="modalTitle">
        <PrimaryText>
          {title || `${t("modalHeader.titleStarter")} ${type}`}
        </PrimaryText>
      </Modal.Title>
      <div className="ml-auto">{renderSteps()}</div>
    </Modal.Header>
  );
};

export default ModalHeader;
