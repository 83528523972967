import React from "react";
import Select from "react-select";
import { HotTable } from "@handsontable/react";
import { Col, Modal, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HANDSONTABLE_LICENSE_KEY } from "../../constants/constants";

import { AlertModal } from "../AlertModal";
import ModalHeader from "../ModalHeader";
import { NotProductionBanner } from "../NotProductionBanner";
import PrimaryText from "../styledComponents/PrimaryText";
import SecondaryText from "../styledComponents/SecondaryText";
import StyledPrimaryButton from "../styledComponents/StyledPrimaryButton";
import StyledSecondaryButton from "../styledComponents/StyledSecondaryButton";
import StyledTertiaryButton from "../styledComponents/StyledTertiaryButton";

import { UIDataMetadataProps } from ".";

export function DataMetadataLegacy({
  alertModal,
  setShowAlert,
  handleAlertPrimaryButtonClick,
  handleAlertSecondaryButtonClick,
  globalStyle,
  handleCloseModal,
  headerSelectRow,
  updateHeaderRow,
  setHeaderSelectRow,
  dataPreview,
  encodings,
  radioButtonComponents,
  changeEncoding,
  data,
  displayEncoding,
  handleGoBack,
}: UIDataMetadataProps) {
  const { t } = useTranslation();

  return (
    <div>
      <AlertModal
        show={alertModal.show}
        setShow={setShowAlert}
        message={alertModal.message}
        primaryButtonText={t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={handleAlertPrimaryButtonClick}
        onSecondaryButtonClick={handleAlertSecondaryButtonClick}
        showSecondaryButton
      />
      <Modal
        dialogClassName="fullScreenModal"
        show
        backdrop={false}
        keyboard={false}
        style={globalStyle}
        onHide={handleCloseModal}
        data-cy="DataMetadataModal"
      >
        <NotProductionBanner />
        <ModalHeader stepperCompletedIdx={1} />
        <div className="modalBodyElement">
          <div className="dataMetadataModalContainer">
            <div data-cy="headerRowSelect" className="rowSelectorContainer">
              {/** If we haven't selected header metadata yet */}
              {headerSelectRow === null ? (
                <>
                  <PrimaryText className="rowSelectorTitle" as="h5">
                    {t("v1.dataMetadataModal.firstRowColName")}
                  </PrimaryText>
                  <StyledTertiaryButton
                    className="rowSelectorButton"
                    onClick={() => updateHeaderRow(null)}
                  >
                    {t("v1.dataMetadataModal.noHeaderRow")}
                  </StyledTertiaryButton>
                  <StyledTertiaryButton
                    className="rowSelectorButton"
                    onClick={() => setHeaderSelectRow(0)}
                  >
                    {t("v1.dataMetadataModal.selectHeaderRowButton")}
                  </StyledTertiaryButton>
                  <StyledPrimaryButton
                    className="rowSelectorButton"
                    onClick={() => updateHeaderRow(0)}
                  >
                    {t("common.yes")}
                  </StyledPrimaryButton>
                </>
              ) : (
                //  We are selecting a specific row
                <>
                  <PrimaryText className="rowSelectorTitle" as="h5">
                    {t("v1.dataMetadataModal.selectRowHeaderRow")}
                  </PrimaryText>
                  <StyledPrimaryButton
                    className="rowSelectorButton"
                    onClick={() => updateHeaderRow(headerSelectRow)}
                  >
                    {t("common.ok")}
                  </StyledPrimaryButton>
                </>
              )}
            </div>

            {displayEncoding && data.uploadType === "FILE" ? (
              <div className="encodingSelectorContainer">
                <div className="encodingSelectorField" style={{ zIndex: 999 }}>
                  <SecondaryText as="h6">
                    {t("v1.dataMetadataModal.selectEncoding")}
                  </SecondaryText>
                  <Select
                    className="dataMetadataModalDropdown"
                    options={encodings.options}
                    onChange={changeEncoding}
                    value={encodings.selectedValue}
                  />
                </div>
              </div>
            ) : null}
            <Row>
              <Col>
                {radioButtonComponents}
                <HotTable
                  data={dataPreview}
                  disableVisualSelection
                  readOnly
                  stretchH="all"
                  colHeaders
                  rowHeaders
                  width="100%"
                  height="auto"
                  licenseKey={HANDSONTABLE_LICENSE_KEY}
                />
              </Col>
            </Row>
          </div>
        </div>
        <Modal.Footer className="modalFooter">
          <StyledSecondaryButton
            className="modalFooterSecondaryButton"
            onClick={handleGoBack}
          >
            {t("common.goBack")}
          </StyledSecondaryButton>
          {/* Powered by Dromo link and logo */}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
