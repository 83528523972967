import * as React from "react";

// Dropzone library
import { FileWithPath, useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import StyledPrimaryButton from "./styledComponents/StyledPrimaryButton";
import { AlertModal } from "./AlertModal";
import SecondaryText from "./styledComponents/SecondaryText";
import { selectAllowedExtensions } from "../store/reducers/settings";
import { useAppSelector } from "../store/hooks";

interface IFileSelectProps {
  onFileSelected: (file: FileWithPath) => void;
  shouldShowTemplateDownloadLink: boolean;
  onTemplateDownloadClick: () => void;
}

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#888888",
};

export function FileSelect(props: IFileSelectProps): React.ReactElement {
  const styleOverrides = useAppSelector(
    (state) => state.settings.styleOverrides
  );

  const allowedExtensions = useAppSelector(selectAllowedExtensions);

  const onDrop = React.useCallback(
    (
      acceptedFiles: FileWithPath[],
      onFileUploaded: (file: FileWithPath) => void
    ) => {
      acceptedFiles.map((file: FileWithPath) => onFileUploaded(file));
    },
    []
  );

  const [showInvalidFileAlert, setShowInvalidFileAlert] = React.useState(false);

  const {
    open,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles: FileWithPath[]) => {
      onDrop(acceptedFiles, props.onFileSelected);
    },
    onDropRejected() {
      setShowInvalidFileAlert(true);
    },
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: allowedExtensions,
  });

  const style = React.useMemo(
    () => ({
      ...styleOverrides.dropzone,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept, styleOverrides.dropzone]
  );

  const { t } = useTranslation();

  return (
    <div className="fileSelectContainer">
      <div className="fileSelectContainerGrid" {...getRootProps({ style })}>
        <div className="fileSelectButton">
          <StyledPrimaryButton onClick={open} style={{ padding: 15 }}>
            {t("v1.dataUploadModal.fileSelect.uploadDataFromFile")}
          </StyledPrimaryButton>
          <SecondaryText as="p" className="fileSelectButtonDescriptionText">
            {allowedExtensions.join(", ") +
              " " +
              t("v1.dataUploadModal.fileSelect.accepted")}
          </SecondaryText>
        </div>
        <div className="fileSelectDivider">&nbsp;</div>
        <div className="fileSelectDescriptionTextContainer">
          <input {...getInputProps()} />
          <SecondaryText as="p" className="fileSelectDescriptionText">
            {t("v1.dataUploadModal.fileSelect.uploadDescription", {
              acceptedFileTypes: allowedExtensions.join(", "),
            })}{" "}
            {props.shouldShowTemplateDownloadLink && (
              <a
                onClick={props.onTemplateDownloadClick}
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {t("v1.dataUploadModal.fileSelect.downloadTemplate")}
              </a>
            )}
          </SecondaryText>
        </div>
      </div>
      <AlertModal
        show={showInvalidFileAlert}
        message="File format not supported."
        caption={`Please upload only ${allowedExtensions.join(", ")} file(s).`}
        primaryButtonText="Close"
        secondaryButtonText=""
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={() => setShowInvalidFileAlert(false)}
        showSecondaryButton={false}
      />
    </div>
  );
}
