import * as React from "react";

import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setSelectedSheet } from "../store/reducers/coredata";
import { useTranslation } from "react-i18next";
import StyledPrimaryButton from "./styledComponents/StyledPrimaryButton";
import { IWorksheet } from "../interfaces";
import Text from "./commonComponents/Text";
import { Button } from "./commonComponents/Button";

interface ISelectSheetProps {
  show: boolean;
  onHide: (selectedSheetId: string) => void;
}

export function SelectSheetModal(props: ISelectSheetProps): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sheets = useAppSelector((state) => state.coredata.sheets);
  const selectedSheet = useAppSelector(
    (state) => state.coredata.selectedSheetId!
  );

  if (!sheets) return null;
  const selectedOption = sheets.find((sheet) => sheet.id === selectedSheet)!;

  const onChange = (option: IWorksheet | null) => {
    if (!option) return;
    dispatch(setSelectedSheet(option.id));
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(selectedSheet)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      className="fileUploadModal"
    >
      <Modal.Body>
        <div className="fileUploadSelectText">
          {t("dataUploadModal.fileUpload.selectSheet")}
        </div>
        <Select
          className="fileUploadSelectField"
          classNamePrefix="select-sheet"
          options={sheets}
          onChange={onChange}
          value={selectedOption}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.id}
        />
      </Modal.Body>
      <Modal.Footer>
        <StyledPrimaryButton onClick={() => props.onHide(selectedSheet)}>
          {t("common.continue")}
        </StyledPrimaryButton>
      </Modal.Footer>
    </Modal>
  );
}

export function SelectSheetModalRedesign(
  props: ISelectSheetProps
): JSX.Element | null {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const sheets = useAppSelector((state) => state.coredata.sheets);
  const selectedSheet = useAppSelector(
    (state) => state.coredata.selectedSheetId!
  );

  if (!sheets) return null;
  const selectedOption = sheets.find((sheet) => sheet.id === selectedSheet)!;

  const onChange = (option: IWorksheet | null) => {
    if (!option) return;
    dispatch(setSelectedSheet(option.id));
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.onHide(selectedSheet)}
      centered
      backdrop="static"
      className="fileUploadModal redesign"
      data-cy="select-sheet-modal"
    >
      <Modal.Body className="shadow rounded-lg p-6">
        <Text type="display">
          {t("dataUploadModal.fileUpload.selectSheet")}
        </Text>
        <Select
          // Data-cy doesn't work with react-select
          className="mt-2 select-sheet-select"
          classNamePrefix="select-sheet"
          options={sheets}
          onChange={onChange}
          value={selectedOption}
          getOptionLabel={(opt) => opt.label}
          getOptionValue={(opt) => opt.id}
        />
        <Button
          onClick={() => props.onHide(selectedSheet)}
          className="w-full mt-6"
          data-cy="select-sheet-continue-button"
        >
          {t("common.continue")}
        </Button>
      </Modal.Body>
    </Modal>
  );
}
