import React, { useEffect } from "react";
import { twMerge } from "tailwind-merge";

import { TranslatedAlertModalRedesign } from "../AlertModal";
import ModalHeader, { IModalHeaderProps } from "../ModalHeaderRedesign";
import { useStyles, useAppDispatch, useAppSelector } from "../../store/hooks";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { handleCancelModal } from "../../thunks/parent_connection_handlers";
import { setExitRequested } from "../../store/reducers/modals";

interface IModalProps {
  "data-cy"?: string;
  children: React.ReactNode;
  onBack?: IModalHeaderProps["onBack"];
  step: IModalHeaderProps["step"];
  contentClassName?: string;
}

function Modal({
  step,
  onBack,
  "data-cy": dataCy,
  children,
  contentClassName,
}: IModalProps) {
  const dispatch = useAppDispatch();
  const connection = useParentConnectionContext();
  const overrideStyles = useStyles((styles) => ({
    backgroundColor: styles.global.backgroundColor,
    borderRadius: styles.global.borderRadius,
    borderStyle: styles.global.borderStyle,
    borderWidth: styles.global.borderWidth,
    borderColor: styles.global.borderColor,
  }));

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        dispatch(setExitRequested(true));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [dispatch]);

  const showExitModal = useAppSelector((state) => state.modals.exitRequested);

  return (
    <div>
      <TranslatedAlertModalRedesign
        show={showExitModal}
        setShow={(show: boolean) => dispatch(setExitRequested(show))}
        i18nMessage="common.exitAlert"
        i18nPrimaryButtonText="common.yes"
        i18nSecondaryButtonText="common.no"
        onPrimaryButtonClick={() => dispatch(handleCancelModal(connection))}
        onSecondaryButtonClick={() => dispatch(setExitRequested(false))}
        data-cy="exit-importer-alert"
        showSecondaryButton
      />
      <div
        className="bg-[#00000050] w-full min-h-screen max-h-screen fixed top-0 left-0 overflow-auto"
        data-cy="modal-container"
      >
        <div
          className="redesign max-w-[1440px] mx-auto w-[90%] h-fit-content my-10 shadow-md bg-ice-50 overflow-hidden"
          data-cy={dataCy}
          style={overrideStyles}
        >
          <ModalHeader step={step} onBack={onBack} />
          <section
            className={twMerge(
              "!px-6 !py-3 sm:!px-12 sm:!py-6 md:!px-24 md:!py-12",
              contentClassName
            )}
          >
            {children}
          </section>
        </div>
      </div>
    </div>
  );
}

Modal.Header = ModalHeader;

export default Modal;
