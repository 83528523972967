import "bootstrap/dist/css/bootstrap.css";
import React, { useEffect, useState, useCallback } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "react-toggle/style.css";

import { EFileUploadStage } from "../../constants/EFileUploadStage";
import {
  EInvalidDataBehavior,
  EStepHook,
  IData,
  IDeveloperSettingsStrict,
} from "../../interfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  hideProcessingModal,
  showProcessingModal,
} from "../../store/reducers/commonComponents";
import {
  resetStateBackDateUpload,
  resetStatePreReview,
  selectColErrors,
  selectRowsWithErrors,
} from "../../store/reducers/coredata";
import { createSnapshot, restoreSnapshot } from "../../store/reducers/fields";
import { setRehydrationComplete } from "../../store/reducers/modals";
import { selectGlobalStyle } from "../../store/reducers/settings";
import {
  FullDataWithMeta,
  ParsedData,
  addRowMetaToData,
  addEmptyRows,
  changeCells,
  initializeForReview,
  removeConsecutiveRows,
  runRowHooks,
  addOrRemoveQueuedRows,
  FindAndReplaceOpts,
  findAndReplace,
  runTransformDataChanges,
} from "../../thunks/data_actions";
import { parseSelectedSheet } from "../../thunks/file_processing";
import {
  handleColumnHooks,
  handleRowDeleteHooks,
  handleRowHooks,
  handleStepHook,
  handleBeforeFinish as onBeforeFinish,
  handleResults as onResults,
  handleCancelModal,
  handleCloseModal,
} from "../../thunks/parent_connection_handlers";
import { generateResultData } from "../../thunks/result_data";
import { finalizeMapping } from "../../thunks/virtual_fields";
import { downloadExport } from "../../thunks/error_export";
import SecondaryText from "./../styledComponents/SecondaryText";
import StyledButtonLink from "./../styledComponents/StyledButtonLink";
import StyledSecondaryButton from "./../styledComponents/StyledSecondaryButton";
import { DataReviewLegacy } from "./DataReviewLegacy";
import { DataReviewView } from "./DataReviewView";
import { VERSION_TWO } from "../../constants/constants";
import StyledPrimaryButton from "../styledComponents/StyledPrimaryButton";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import { setModalStage } from "../../thunks/modal_stage";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { selectMappedSelectSpecs } from "../../store/selectors";
import { TransformDataSuccess } from "../../thunks/user_functions";

interface IAlertModalState {
  show: boolean;
  nextModalStage: EFileUploadStage | "cancel";
}

const DataReviewModal = () => {
  const connection = useParentConnectionContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    data,
    globalStyle,
    importerMode,
    invalidDataBehavior,
    numRegisteredColHooks,
    progress,
    rehydrateStage,
    rehydrationComplete,
    rehydrateTempData,
    rowsWithErrors,
    selectedHandsonCell,
    stepSettings,
    version,
    manualInputOnly,
    colErrors,
    rowsToAdd,
    rowsToRemove,
    matchedAnySelectFields,
  } = useAppSelector((state) => ({
    data: state.coredata.data,
    globalStyle: selectGlobalStyle(state),
    importerMode: state.settings.importerMode,
    invalidDataBehavior: state.settings.invalidDataBehavior,
    numRegisteredColHooks: state.coredata.numRegisteredColHooks,
    originalFilename: state.coredata.originalFilename,
    progress: state.modals.progress,
    rehydrateStage: state.modals.rehydrateStage,
    rehydrationComplete: state.modals.rehydrationComplete,
    rehydrateTempData: state.modals.rehydrateTempData,
    rowsWithErrors: selectRowsWithErrors(state.coredata),
    colErrors: selectColErrors(state),
    selectedHandsonCell: state.commonComponents.selectedHandsonCell,
    stepSettings: state.settings.reviewStep,
    version: state.settings.version,
    manualInputOnly: state.settings.manualInputOnly,
    rowsToAdd: state.coredata.rowsToAdd,
    rowsToRemove: state.coredata.rowsToDelete,
    matchedAnySelectFields: selectMappedSelectSpecs(state).size > 0,
  }));

  const [isLoading, setIsLoading] = useState(true);
  const [fullData, setFullData] = useState<FullDataWithMeta>([]);
  const [hiddenRows, setHiddenRows] = useState<number[]>([]);
  const [showOnlyRowsWithProblemsToggle, setShowOnlyRowsWithProblemsToggle] =
    useState<boolean>(false);
  const [noRowsWithErrorsOnToggle, setNoRowsWithErrorsOnToggle] =
    useState(false);
  const [isChangedByUser, setIsChangedByUser] = useState(false);
  const [stepErrorMessage, setStepErrorMessage] = useState<string | null>(null);
  const [showUpsellModal, setShowUpsellModal] = useState<boolean>(false);
  const [alertModal, setAlertModal] = useState<IAlertModalState>({
    show: false,
    nextModalStage: "cancel",
  });
  const [currentFilter, setCurrentFilter] = useState<"all" | number | null>(
    null
  );

  const initReview = useCallback(async () => {
    dispatch(createSnapshot("PREREVIEW"));

    await dispatch(handleStepHook(connection, EStepHook.REVIEW_STEP));

    let rawData: ParsedData = [];

    if (data.file === null) {
      rawData = data.previewData.map((r) => [...r]);
    } else {
      try {
        const parseResult = await dispatch(parseSelectedSheet(false));

        if (parseResult.success) {
          rawData = parseResult.data;
        } else {
          setAlertModal({
            show: true,
            nextModalStage: EFileUploadStage.DATA_UPLOAD,
          });
          return;
        }
      } catch (error) {
        setAlertModal({
          show: true,
          nextModalStage: EFileUploadStage.DATA_UPLOAD,
        });
        return;
      }
    }

    // Now that we've loaded all the data, we need to add hidden and virtual fields.
    // These fields are only available in the Review Modal and can only be set via
    // row or column hooks
    rawData = dispatch(finalizeMapping(rawData));

    // Add the row meta to each row including the uuid
    const rawDataWithMeta = addRowMetaToData(rawData);

    setFullData(rawDataWithMeta);

    const processedData = await dispatch(
      initializeForReview(
        rawDataWithMeta,
        (...args) => dispatch(handleColumnHooks(connection, ...args)),
        (...args) => dispatch(handleRowHooks(connection, ...args))
      )
    );

    await dispatch(
      handleStepHook(connection, EStepHook.REVIEW_STEP_POST_HOOKS)
    );
    setFullData(processedData);
    setIsLoading(false);
  }, [connection, data.file, data.previewData, dispatch]);

  const rehydrate = useCallback(async () => {
    setFullData(rehydrateTempData);
    setIsLoading(false);
    dispatch(setRehydrationComplete());
  }, [dispatch, rehydrateTempData]);

  const rowsToAddOrRemove = rowsToAdd.length > 0 || rowsToRemove.length > 0;
  const shouldRehydrate =
    !rehydrationComplete && rehydrateStage === "DATA_REVIEW";

  useEffect(() => {
    async function init() {
      if (shouldRehydrate) {
        await rehydrate();
      } else {
        await initReview();
      }
    }

    init();
  }, [shouldRehydrate, initReview, rehydrate]);

  useEffect(() => {
    return () => {
      setFullData([]);
    };
  }, [connection, dispatch]);

  useEffect(() => {
    if (rowsToAddOrRemove && !isLoading) {
      const newData = dispatch(addOrRemoveQueuedRows(fullData));
      setFullData(newData);
    }
  }, [dispatch, fullData, rowsToAddOrRemove, isLoading]);

  const downloadRichXLSX = (): void => {
    dispatch(downloadExport(fullData, false));
  };

  const downloadRichXLSXOnlyErrors = (): void => {
    dispatch(downloadExport(fullData, true));
  };

  const handleBeforeFinish = async (): Promise<boolean> => {
    const tableOutput = dispatch(generateResultData(fullData, false));
    const callbackResult = await dispatch(
      onBeforeFinish(connection, tableOutput)
    );

    if (callbackResult && callbackResult.cancel) {
      setStepErrorMessage(callbackResult.message);
      return false;
    } else {
      return true;
    }
  };

  const handleResults = async (): Promise<void> => {
    const tableOutput = dispatch(generateResultData(fullData));

    await dispatch(onResults(connection, tableOutput));
  };

  const onFinish = async (): Promise<void> => {
    dispatch(showProcessingModal());

    const beforeFinishSuccess = await handleBeforeFinish();

    if (beforeFinishSuccess) {
      await handleResults();
      dispatch(hideProcessingModal());
      dispatch(handleCloseModal(connection));
    } else {
      dispatch(hideProcessingModal());
    }
  };

  const showOnlyRowsWithErrors = useCallback(() => {
    const hiddenRowSet = new Set<number>(
      Array.from(Array(fullData.length).keys())
    );

    rowsWithErrors.forEach((rowIndex) => hiddenRowSet.delete(rowIndex));

    setShowOnlyRowsWithProblemsToggle(true);
    setHiddenRows(Array.from(hiddenRowSet));

    const noRowsWithErrors = rowsWithErrors.size === 0;
    setNoRowsWithErrorsOnToggle(noRowsWithErrors);
  }, [fullData.length, rowsWithErrors]);

  const showAllRows = useCallback(() => {
    setShowOnlyRowsWithProblemsToggle(false);
    setHiddenRows([]);
  }, []);

  const onProblemOnlyToggleChange = useCallback(() => {
    const newShowOnlyRowsWithProblems = !showOnlyRowsWithProblemsToggle;
    if (newShowOnlyRowsWithProblems) {
      showOnlyRowsWithErrors();
    } else {
      showAllRows();
    }
  }, [showOnlyRowsWithProblemsToggle, showOnlyRowsWithErrors, showAllRows]);

  useEffect(() => {
    // If the user is only showing rows with errors, but then fixes the last error,
    // we want to automatically go back to showing all rows.
    // BUT, if the user toggles only showing rows with errors when there are already
    // zero errors, we want to show an empty table so they can see there are no errors
    if (
      showOnlyRowsWithProblemsToggle &&
      rowsWithErrors.size === 0 &&
      !noRowsWithErrorsOnToggle
    ) {
      showAllRows();
    }
  }, [
    onProblemOnlyToggleChange,
    rowsWithErrors.size,
    showOnlyRowsWithProblemsToggle,
    noRowsWithErrorsOnToggle,
    showAllRows,
  ]);

  const handleFilterAllRows = useCallback(() => {
    const hiddenRowSet = new Set<number>(
      Array.from(Array(fullData.length).keys())
    );

    rowsWithErrors.forEach((rowIndex) => hiddenRowSet.delete(rowIndex));

    setShowOnlyRowsWithProblemsToggle((prev) => !prev);
    setHiddenRows(Array.from(hiddenRowSet));
    setCurrentFilter("all");
  }, [fullData.length, rowsWithErrors]);

  const handleFilterColumn = (index: number | null) => {
    if (index === null) {
      handleClearFilter();
      return;
    }

    const hiddenRowSet = new Set<number>(
      Array.from(Array(fullData.length).keys())
    );

    const errors = colErrors.get(index);

    if (errors !== undefined) {
      errors.forEach((rowIndex) => hiddenRowSet.delete(rowIndex));
    }

    setHiddenRows(Array.from(hiddenRowSet));
    setCurrentFilter(index);
  };

  const handleClearFilter = () => {
    setHiddenRows([]);
    setCurrentFilter(null);
  };

  useEffect(() => {
    if (currentFilter === "all" && rowsWithErrors.size === 0) {
      handleClearFilter();
    }

    if (typeof currentFilter === "number" && !colErrors.get(currentFilter)) {
      handleClearFilter();
    }
  }, [
    colErrors,
    currentFilter,
    rowsWithErrors.size,
    showOnlyRowsWithProblemsToggle,
  ]);

  const onAlertModalPrimaryButtonClick = () => {
    if (alertModal.nextModalStage === EFileUploadStage.DATA_REVIEW) {
      // Close modal to prevent annoying flicker of modals
      setAlertModal({
        show: false,
        nextModalStage: "cancel",
      });

      onFinish();
    } else if (alertModal.nextModalStage === "cancel") {
      // trying to close the modal
      dispatch(handleCancelModal(connection));
    } else {
      goBack();
    }
  };

  const goBackDestination = (): EFileUploadStage => {
    if (data.file === null) {
      return EFileUploadStage.DATA_UPLOAD;
    }

    if (matchedAnySelectFields && version === VERSION_TWO) {
      return EFileUploadStage.AUTO_CLEAN_DATA;
    }

    return EFileUploadStage.COLUMN_MATCH;
  };

  const hideBackButton =
    goBackDestination() === EFileUploadStage.DATA_UPLOAD &&
    rehydrateStage !== null;

  const goBack = (): void => {
    const destination = goBackDestination();
    if (destination === EFileUploadStage.DATA_UPLOAD) {
      dispatch(setModalStage(EFileUploadStage.DATA_UPLOAD));
      dispatch(resetStateBackDateUpload());
    } else {
      dispatch(setModalStage(destination));
      dispatch(resetStatePreReview());
      dispatch(restoreSnapshot("PREREVIEW"));
    }
  };

  const onGoBackButtonClick = (): void => {
    if (isChangedByUser) {
      // show the "u sure?" modal
      setAlertModal({
        show: true,
        nextModalStage: goBackDestination(),
      });
    } else {
      // user didn't make any changes, so just go back
      goBack();
    }
  };

  const getAlertModalData = () => {
    // `nextModalStage` is one of four stages
    // If it is `DATA_REVIEW`, it means the user has submitted the data and needs to confirm prior
    // to submitting it
    // If it is `NOT_OPEN`, it means the user is trying to exit out of the data upload flow so
    // we show them a confirmation message to ensure they really want to exit out of the flow
    // If it is `DATA_UPLOAD`, it means the user wants to go back and previously entered in data
    // using the manual input table. In this case we show them the same alert modal as `NOT_OPEN`
    // If it is `COLUMN_MATCH`, it means the user wants to go back and previously uploaded
    // data using the dropzone. In this case we show them the same alert modal as `NOT_OPEN`
    if (alertModal.nextModalStage === EFileUploadStage.DATA_REVIEW) {
      if (rowsWithErrors.size === 0) {
        return {
          alertModalMessage: t("dataReviewModal.submitAlert"),
          primaryButtonText: t("common.yes"),
          secondaryButtonText: t("common.no"),
          primaryButtonDescriptionText: "",
          secondaryButtonDescriptionText: "",
          onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
        };
      }

      const allRowsHaveErrors = rowsWithErrors.size === fullData.length;

      const unresolvedMessage = t(
        allRowsHaveErrors
          ? "dataReviewModal.unresolvedErrorsAlert.titleAll"
          : "dataReviewModal.unresolvedErrorsAlert.title",
        {
          count: rowsWithErrors.size,
          defaultValue: t("dataReviewModal.unresolvedErrorsAlert.title_other", {
            count: rowsWithErrors.size,
          }),
        }
      );

      if (invalidDataBehavior === EInvalidDataBehavior.BLOCK_SUBMIT) {
        // We get here because of a race condition - the user should have never been able to click
        // "Finish" in the first place, because the button is disabled if there are any invalid rows.
        // However, if there are no invalid rows when the user clicks "Finish", but then an async
        // callback adds an error after this modal is already open, we're in a pickle.
        return {
          alertModalMessage: unresolvedMessage,
          primaryButtonText: t("common.goBack"),
          primaryButtonDescriptionText: t(
            "v1.dataReviewModal.unresolvedErrorsAlert.reviewAndFix"
          ),
          secondaryButtonText: "",
          secondaryButtonDescriptionText: "",
          onPrimaryButtonClick: () =>
            setAlertModal({
              show: false,
              nextModalStage: "cancel",
            }),
        };
      }

      return {
        alertModalMessage: unresolvedMessage,
        primaryButtonText: t(
          "dataReviewModal.unresolvedErrorsAlert.submitButton"
        ),
        secondaryButtonText: t("common.goBack"),
        primaryButtonDescriptionText:
          invalidDataBehavior === EInvalidDataBehavior.INCLUDE_INVALID_ROWS
            ? t("v1.dataReviewModal.unresolvedErrorsAlert.submitAnyway")
            : t(
                rowsWithErrors.size === fullData.length
                  ? "v1.dataReviewModal.unresolvedErrorsAlert.discardAllAndSubmit"
                  : "v1.dataReviewModal.unresolvedErrorsAlert.discardAndSubmit",
                {
                  count: rowsWithErrors.size,
                  defaultValue: t(
                    "v1.dataReviewModal.unresolvedErrorsAlert.discardAndSubmit",
                    {
                      count: rowsWithErrors.size,
                    }
                  ),
                }
              ),
        secondaryButtonDescriptionText: t(
          "v1.dataReviewModal.unresolvedErrorsAlert.reviewAndFix"
        ),
        hasAlertModalError: true,
        alertModalCaption:
          invalidDataBehavior === EInvalidDataBehavior.INCLUDE_INVALID_ROWS
            ? t(
                "dataReviewModal.unresolvedErrorsAlert.includeInvalidRows.subtitle"
              )
            : allRowsHaveErrors
            ? t(
                "dataReviewModal.unresolvedErrorsAlert.removeInvalidRows.subtitleAll"
              )
            : t(
                "dataReviewModal.unresolvedErrorsAlert.removeInvalidRows.subtitle",
                {
                  count: rowsWithErrors.size,
                }
              ),
        children:
          version === VERSION_TWO ? (
            <Button
              theme="ghost"
              className="px-0 mt-6 font-medium gap-2"
              onClick={downloadRichXLSX}
              data-cy="download-xlsx-with-errors"
            >
              <ExportIcon />
              <Text type="body" as="span">
                {t(
                  "dataReviewModal.unresolvedErrorsAlert.downloadXLSXWithErrorsNew"
                )}
              </Text>
            </Button>
          ) : (
            <SecondaryText as="p" style={{ padding: "0 16px", margin: 0 }}>
              <StyledButtonLink
                data-cy="DownloadXLSXWithErrors"
                onClick={downloadRichXLSX}
              >
                {t("v1.dataReviewModal.unresolvedErrorsAlert.clickHere")}
              </StyledButtonLink>{" "}
              {t("v1.dataReviewModal.unresolvedErrorsAlert.downloadXLSX")}
            </SecondaryText>
          ),
        onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
      };
    } else if (
      alertModal.nextModalStage === EFileUploadStage.DATA_UPLOAD &&
      data.file !== null
    ) {
      return {
        alertModalMessage: t("common.errorLocalFile"),
        primaryButtonText: t("common.ok"),
        secondaryButtonText: "",
        primaryButtonDescriptionText: "",
        secondaryButtonDescriptionText: "",
        onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
      };
    }

    return {
      alertModalMessage: t("common.clearAllProgressAlert"),
      primaryButtonText: t("common.yes"),
      secondaryButtonText: t("common.no"),
      primaryButtonDescriptionText: "",
      secondaryButtonDescriptionText: "",
      onPrimaryButtonClick: onAlertModalPrimaryButtonClick,
    };
  };

  // TODO: Make sure versions are different
  const FinishButton = () => {
    const errorRowCount = rowsWithErrors.size;

    const DisabledButtonWithToolTip = () => {
      return (
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip-disabled">
              {t("dataReviewModal.unresolvedErrorsAlert.title", {
                count: errorRowCount,
              })}
            </Tooltip>
          }
        >
          <span style={{ marginLeft: 0, marginRight: 0 }}>
            {version === VERSION_TWO ? (
              <Button
                data-cy="finish-button"
                className="m-0 pointer-events-none"
                onClick={() => {}}
                disabled
              >
                {t("common.finish")}
              </Button>
            ) : (
              <StyledSecondaryButton
                onClick={() => {}}
                className="modalFooterPrimaryButton"
                disabled
                style={{ pointerEvents: "none" }}
              >
                {t("common.finish")}
              </StyledSecondaryButton>
            )}
          </span>
        </OverlayTrigger>
      );
    };

    const NormalFinishButton = () => {
      const handleClick = () => {
        if (importerMode === "DEMO") {
          setShowUpsellModal(true);
        } else {
          setAlertModal({
            show: true,
            nextModalStage: EFileUploadStage.DATA_REVIEW,
          });
        }
      };

      if (version === VERSION_TWO) {
        return (
          <Button
            data-cy="finish-button"
            className="m-0"
            onClick={handleClick}
            autoFocus
          >
            {t("common.finish")}
          </Button>
        );
      }

      return (
        <StyledPrimaryButton
          className="modalFooterPrimaryButton"
          onClick={handleClick}
        >
          {t("common.finish")}
        </StyledPrimaryButton>
      );
    };

    if (isLoading) {
      return null;
    } else if (
      invalidDataBehavior === EInvalidDataBehavior.BLOCK_SUBMIT &&
      errorRowCount > 0
    ) {
      return <DisabledButtonWithToolTip />;
    } else {
      return <NormalFinishButton />;
    }
  };

  const {
    alertModalMessage,
    primaryButtonText,
    secondaryButtonText,
    primaryButtonDescriptionText,
    secondaryButtonDescriptionText,
    children,
    onPrimaryButtonClick,
    hasAlertModalError,
    alertModalCaption,
  } = getAlertModalData();

  const handleShowAlert = (show: boolean) =>
    setAlertModal({
      show,
      nextModalStage: "cancel",
    });

  const handleAlertSecondaryButtonClick = () =>
    setAlertModal({
      show: false,
      nextModalStage: "cancel",
    });

  const handleCloseUpsellModal = () => {
    setShowUpsellModal(false);
    setAlertModal({
      show: true,
      nextModalStage: EFileUploadStage.DATA_REVIEW,
    });
  };

  const handleHideModal = () =>
    setAlertModal({
      show: true,
      nextModalStage: "cancel",
    });

  const handleCellDataChange = async (
    _: any[][],
    changes: any[]
  ): Promise<void> => {
    setIsChangedByUser(true);
    const postChangeData = dispatch(changeCells(fullData, changes));
    setFullData(postChangeData);

    const postHookData = await dispatch(
      runRowHooks(
        postChangeData,
        (...args) => dispatch(handleRowHooks(connection, ...args)),
        "update",
        changes
      )
    );
    setFullData(postHookData);
  };

  const handleRemoveRows = (removedRows: number[]): void => {
    const newFullData = dispatch(
      removeConsecutiveRows(fullData, removedRows, (...args) =>
        dispatch(handleRowDeleteHooks(connection, ...args))
      )
    );

    setFullData(newFullData);
    setIsChangedByUser(true);
  };

  const handleCreateRow = (addedRows: number[]): void => {
    const newFullData = dispatch(addEmptyRows(fullData, addedRows));
    setFullData(newFullData);
    setIsChangedByUser(true);
  };

  const handleFindAndReplace = async (
    query: string,
    replacement: string,
    opts: FindAndReplaceOpts
  ) => {
    const newFullData = await dispatch(
      findAndReplace(
        fullData,
        (...args) => dispatch(handleRowHooks(connection, ...args)),
        query,
        replacement,
        opts
      )
    );
    setFullData(newFullData);
    setIsChangedByUser(true);
  };

  const handleUserFunctionChange = async (
    transformResult: TransformDataSuccess
  ) => {
    const newFullData = await dispatch(
      runTransformDataChanges(
        fullData,
        transformResult,
        (...args) => dispatch(handleRowHooks(connection, ...args)),
        (...args) => dispatch(handleRowDeleteHooks(connection, ...args))
      )
    );
    setFullData(newFullData);
    setIsChangedByUser(true);
  };

  const props = {
    alertModal,
    alertModalMessage,
    children,
    data,
    FinishButton,
    fullData,
    globalStyle,
    handleAlertSecondaryButtonClick,
    handleCellDataChange,
    handleCloseUpsellModal,
    handleCreateRow,
    handleHideModal,
    handleRemoveRows,
    handleShowAlert,
    handleFindAndReplace,
    hiddenRows,
    isLoading,
    numRegisteredColHooks,
    onGoBackButtonClick,
    onPrimaryButtonClick,
    onProblemOnlyToggleChange,
    primaryButtonDescriptionText,
    primaryButtonText,
    progress,
    secondaryButtonDescriptionText,
    secondaryButtonText,
    selectedHandsonCell,
    showOnlyRowsWithProblemsToggle,
    showUpsellModal,
    stepErrorMessage,
    stepSettings,
    downloadRichXLSX,
    downloadRichXLSXOnlyErrors,
    hasAlertModalError,
    alertModalCaption,
    rowsWithErrorsCount: rowsWithErrors.size,
    manualInputOnly,
    onFilterAllRows: handleFilterAllRows,
    onFilterColumn: handleFilterColumn,
    onClearFilter: handleClearFilter,
    onConfirmChanges: handleUserFunctionChange,
    currentFilter,
    hideBackButton,
  };

  return version === VERSION_TWO ? (
    <DataReviewView {...props} />
  ) : (
    <DataReviewLegacy {...props} />
  );
};

export interface UIDataReviewProps {
  alertModal: IAlertModalState;
  alertModalMessage: string;
  children: React.ReactNode;
  data: IData;
  FinishButton: React.FC;
  fullData: FullDataWithMeta;
  globalStyle: { [key: string]: string };
  handleAlertSecondaryButtonClick: () => void;
  handleCellDataChange: (_: any[][], changes: any[]) => Promise<void>;
  handleCloseUpsellModal: () => void;
  handleCreateRow: (addedRows: number[]) => void;
  handleHideModal: () => void;
  handleRemoveRows: (removedRows: number[]) => void;
  handleShowAlert: (show: boolean) => void;
  handleFindAndReplace: (
    query: string,
    replacement: string,
    opts: FindAndReplaceOpts
  ) => void;
  hiddenRows: number[];
  isLoading: boolean;
  numRegisteredColHooks: number;
  onGoBackButtonClick: () => void;
  onPrimaryButtonClick: () => void;
  onProblemOnlyToggleChange: () => void;
  primaryButtonDescriptionText: string;
  primaryButtonText: string;
  secondaryButtonDescriptionText: string;
  secondaryButtonText: string;
  selectedHandsonCell: number[] | null;
  showOnlyRowsWithProblemsToggle: boolean;
  showUpsellModal: boolean;
  stepErrorMessage: string | null;
  stepSettings: IDeveloperSettingsStrict["reviewStep"];
  downloadRichXLSX: () => void;
  downloadRichXLSXOnlyErrors: () => void;
  hasAlertModalError?: boolean;
  alertModalCaption?: string;
  rowsWithErrorsCount: number;
  manualInputOnly: boolean;
  onFilterAllRows: () => void;
  onClearFilter: () => void;
  onFilterColumn: (columnIndex: number | null) => void;
  onConfirmChanges: (transformResult: TransformDataSuccess) => Promise<void>;
  currentFilter: "all" | number | null;
  hideBackButton: boolean;
}

export default DataReviewModal;
