import { AnyAction } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import type { ThunkAction } from "redux-thunk";
import { rootReducer, RootState } from "./reducers";
import * as Sentry from "@sentry/react";

export function configureAppStore(preloadedState?: RootState) {
  const sentryReduxEnhancer = Sentry.createReduxEnhancer();

  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ serializableCheck: false }),
    enhancers: [sentryReduxEnhancer],
    devTools:
      process.env.NODE_ENV === "production"
        ? false
        : { serialize: { options: true } },
    preloadedState,
  });

  if (process.env.NODE_ENV !== "production" && module.hot) {
    module.hot.accept("./reducers", () => store.replaceReducer(rootReducer));
  }

  return store;
}

export const store = configureAppStore();

export type AppStore = typeof store;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  AnyAction
>;

export type AppDispatch = typeof store.dispatch;
