import {
  EBackendSyncMode,
  EInvalidDataBehavior,
  ITableMessages,
} from "../interfaces";
import { IAbstractField } from "../fields";
import { FullDataWithMeta } from "./data_actions";
import { AppThunk } from "../store/configureStore";
import { cellIsEmpty } from "../helpers/TableHelpers";
import { getAPIClient } from "../helpers/APIHelpers";
import {
  convertColumnMappingDataToAxiosFriendly,
  selectColumnMappingCache,
  IColumnMappingCacheSerialized,
} from "../helpers/ColumnMappingCacheHelper";
import { selectRowsWithErrors } from "../store/reducers/coredata";
import { getOriginalFields } from "../store/reducers/fields";
import {
  selectErrorsForExport,
  selectMappedSpecs,
  selectMappedFieldInstances,
} from "../store/selectors";

export const generateResultData = (
  fullData: FullDataWithMeta,
  includeInvalid = false
): AppThunk<Record<string, unknown>[]> => {
  return (_dispatch, getState) => {
    const state = getState();
    const { invalidDataBehavior, maxRecords } = state.settings;
    const { transformErrorCells, tableMessages } = state.coredata;
    const mappedSpecs = selectMappedSpecs(state);
    const mappedInstances = selectMappedFieldInstances(state);

    const resultData: Record<string, unknown>[] = [];
    const errorRows = getRowsWithErrors(tableMessages);
    let rowsProcessed = 0;

    fullData.forEach((row: unknown[], rowIndex: number) => {
      if (
        !includeInvalid &&
        invalidDataBehavior === EInvalidDataBehavior.REMOVE_INVALID_ROWS &&
        errorRows.has(rowIndex)
      )
        return;
      if (maxRecords != null && rowsProcessed >= maxRecords) return;

      // are all values in the row null
      let allNull = true;
      const rowOutput: Record<string, unknown> = {};
      // for each column mapping that has not been ignored
      mappedSpecs.forEach((fieldSpec, colIndex) => {
        const key = `${rowIndex},${colIndex}`;
        const value = row[colIndex];
        const field = mappedInstances.get(colIndex)! as IAbstractField;
        const fieldKey = fieldSpec.key;

        const outputValue = transformErrorCells.has(key)
          ? includeInvalid
            ? value
            : null
          : field.getOutputValueChecked(value, rowIndex);

        if (fieldSpec.manyToOne) {
          rowOutput[fieldKey] ??= [];
          (rowOutput[fieldKey] as unknown[]).push(outputValue);
        } else {
          rowOutput[fieldKey] = outputValue;
        }
        if (outputValue !== "" && outputValue !== false && outputValue != null)
          allNull = false;
      });

      // if values in row are not all null, add to output
      // otherwise ignore
      if (!allNull) resultData.push(rowOutput);
      rowsProcessed += 1;
    });

    return resultData;
  };
};

export const sendResultMetadata = (
  numDataRows: number
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();
    const { coredata, settings } = state;
    const api = getAPIClient(state);
    const originalSettings = settings.originalSettings;
    const originalFields = getOriginalFields(state.fields);

    let columnMapping: IColumnMappingCacheSerialized | null = null;
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA ||
      settings.backendSyncMode === EBackendSyncMode.MAPPINGS_ONLY
    ) {
      const cacheData = selectColumnMappingCache(state);
      if (cacheData)
        columnMapping = convertColumnMappingDataToAxiosFriendly(cacheData);
    }

    await api.onCompletedUpload({
      rawDataUploadId: coredata.uploadId,
      backendSync: settings.backendSyncMode === EBackendSyncMode.FULL_DATA,
      developmentMode: settings.importerMode !== "PRODUCTION",
      webhookUrl: settings.webhookUrl,
      columnMapping,
      numDataRows,
      importIdentifier: settings.importIdentifier,
      importSchemaId: settings.savedSchema?.id ?? null,
      originalSettings,
      originalFields,
      isHeadless: settings.isHeadless,
      headlessImportId: coredata.headlessImportId,
    });

    // The metadata update is only for Full Data sync, otherwise we don't have an uploadId
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
      coredata.uploadId &&
      // If we're storing JSON in s3, we will have a CleanedDataUpload even for 0 data rows
      // Otherwise, don't bother sending, there's nowhere for this to go
      (numDataRows > 0 || settings.backendCapabilities.accept_json_results)
    ) {
      try {
        const errorRows = selectRowsWithErrors(coredata);
        const errorRowsArray = Array.from(errorRows).sort((a, b) => a - b);
        const errors = selectErrorsForExport(state);

        await api.updateCompletedUploadMetadata({
          rawDataUploadId: coredata.uploadId,
          invalidRowIndexes: errorRowsArray,
          errors,
        });
      } catch (err) {
        console.error(err);
        // we don't reject the upload here since this isn't a catastrophic
        // failure. Only metadata will be inconsistent.
      }
    }
  };
};

const getRowsWithErrors = (tableMessages: ITableMessages) => {
  const errorRows = new Set<number>();
  // Get count of all table message items that are "errors"
  tableMessages.forEach((messages, key) => {
    if (messages.find((message) => message.level === "error")) {
      const rowNumber = parseInt(key.split(",")[0]);
      errorRows.add(rowNumber);
    }
  });

  return errorRows;
};

export const generateExportData = (
  fullData: FullDataWithMeta
): AppThunk<unknown[][]> => {
  return (_dispatch, getState) => {
    const state = getState();
    const { transformErrorCells } = state.coredata;
    const mappedSpecs = selectMappedSpecs(state);
    const mappedInstances = selectMappedFieldInstances(state);

    const resultData: unknown[][] = [];

    fullData.forEach((row, rowIndex) => {
      // are all values in the row null
      let allNull = true;
      const rowOutput: unknown[] = [];

      row.forEach((value, colIndex) => {
        const field = mappedSpecs.get(colIndex);
        if (!field) return;

        const fieldInstance = mappedInstances.get(colIndex)!;

        const key = `${rowIndex},${colIndex}`;
        const outputValue = transformErrorCells.has(key)
          ? value
          : (fieldInstance as IAbstractField).getDisplayValueChecked(
              value,
              rowIndex
            );

        rowOutput.push(outputValue);

        if (!cellIsEmpty(value)) allNull = false;
      });

      // if values in row are not all null, add to output
      // otherwise ignore
      if (!allNull) resultData.push(rowOutput);
    });

    return resultData;
  };
};
