import { EBackendSyncMode, IWorksheet } from "../interfaces";
import { AppThunk } from "../store/configureStore";
import {
  setOriginalFilename,
  setSheets,
  setSelectedSheet,
  setProcessedDataId,
  setHeaders,
  setData,
  setEncoding,
} from "../store/reducers/coredata";
import { ISettingsReduxState } from "../store/reducers/settings";
import { addError } from "../store/reducers/errors";
import { uploadRawFileHelper, getAPIClient } from "../helpers/APIHelpers";
import {
  localFiletypes,
  serverFiletypes,
  NUM_ROWS_FOR_PREVIEW,
} from "../constants/constants";
import jschardet from "jschardet";
import {
  IParseFileResponse,
  parseFile as parseFileWithPapaParse,
  parseWorkbook as parseFileWithSheetJS,
  parseWorksheet as parseWorksheetWithSheetJS,
  parseLocalFile as processParsedData,
} from "../helpers/TableHelpers";
import { humanFileSize } from "../util/filesize";
import { handleNumOfRows } from "./initialization";
import type { WorkBook } from "@sheet/core";

interface IProcessSelectedFileReturn {
  success: boolean;
  requiresSheetSelection: boolean;
  requiresMaxRecordsDisclaimer: boolean;
}
type ProcessFileThunk = AppThunk<Promise<IProcessSelectedFileReturn>>;

type IParseSheetReturn =
  | { success: false; requiresMaxRecordsDisclaimer: boolean }
  | { success: true; data: string[][]; requiresMaxRecordsDisclaimer: boolean };
type ParseSheetThunk = AppThunk<Promise<IParseSheetReturn>>;

export const processSelectedFile = (file: File): ProcessFileThunk => {
  return async (dispatch, getState) => {
    const { settings } = getState();
    const fileExtension = file.name.split(".").pop()!;

    if (file.size > settings.maxFileSize) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_TOO_BIG",
          messageKey: "dataUploadModal.fileUpload.error.fileTooBig",
          messageValues: { maxFileSize: humanFileSize(settings.maxFileSize) },
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer: false,
      };
    }

    dispatch(
      setData({
        file,
        uploadType: "FILE",
      })
    );
    dispatch(setOriginalFilename(file.name));

    switch (parser(fileExtension, settings)) {
      case "BACKEND":
        return dispatch(processFileWithBackend(file));
      case "SHEETJS":
        return dispatch(processFileWithSheetJS(file));
      case "PAPAPARSE":
        return dispatch(processFileWithPapaParse(file));
    }
  };
};

export const parseSelectedSheet = (previewOnly: boolean): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const { coredata, settings } = getState();
    const filename = coredata.originalFilename!;
    const fileExtension = filename.split(".").pop()!;

    switch (parser(fileExtension, settings)) {
      case "BACKEND":
        return dispatch(parseSheetWithBackend(previewOnly));
      case "SHEETJS":
        return dispatch(parseSheetWithSheetJS(previewOnly));
      case "PAPAPARSE":
        return dispatch(parseSheetWithPapaParse(previewOnly));
    }
  };
};

export const processFileWithBackend = (file: File): ProcessFileThunk => {
  return async (dispatch) => {
    const { response } = await dispatch(uploadRawFileHelper(file));

    if (response.upload_status === "FAILED") {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer: false,
      };
    }

    const sheets = response.processed_data;

    if (sheets.length === 0) {
      // This happens if the file format is bad (i.e. openpyxl can't handle OpenXML)
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_NO_SHEETS",
          messageKey: "dataUploadModal.fileUpload.error.noSheets",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer: false,
      };
    }

    const worksheets: IWorksheet[] = sheets.map(({ id, label }) => ({
      id,
      label,
    }));
    dispatch(setSheets(worksheets));
    dispatch(setSelectedSheet(worksheets[0].id));

    if (sheets.length === 1) {
      const { success, requiresMaxRecordsDisclaimer } = await dispatch(
        parseSheetWithBackend(true)
      );

      return {
        success,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer,
      };
    } else {
      return {
        success: true,
        requiresSheetSelection: true,
        requiresMaxRecordsDisclaimer: false,
      };
    }
  };
};

export const parseSheetWithBackend = (
  previewOnly: boolean
): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    const api = getAPIClient(state);
    const { coredata, settings } = state;
    const sheetId = coredata.selectedSheetId;
    if (!sheetId) throw new Error("No sheet selected to parse");

    dispatch(setProcessedDataId(sheetId));

    const csvUrl = await api.getProcessedDownloadUrl(sheetId);
    let parsedData: IParseFileResponse;
    dispatch(setData({ file: csvUrl }));

    try {
      parsedData = await parseFileWithPapaParse(
        csvUrl,
        coredata.encoding,
        coredata.rawDataHeaderRow,
        previewOnly,
        settings.delimiter
      );
    } catch (err) {
      // S3 throws an error if the CSV is empty
      if (err === "Requested Range Not Satisfiable") {
        dispatch(
          addError({
            type: "data",
            code: "E_FILE_EMPTY",
            messageKey: "dataUploadModal.fileUpload.error.cannotBeEmpty",
          })
        );
      } else {
        dispatch(
          addError({
            type: "data",
            code: "E_FILE_UNPROCESSABLE",
            messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
          })
        );
      }
      return { success: false, requiresMaxRecordsDisclaimer: false };
    }

    if (previewOnly) dispatch(setDataForPreview(parsedData));
    const success = dispatch(checkDataEmpty(parsedData.data));
    const processedData = dispatch(handleNumOfRows(parsedData.data));

    return {
      success,
      data: processedData,
      requiresMaxRecordsDisclaimer:
        parsedData.data.length !== processedData.length,
    };
  };
};

export const processFileWithSheetJS = (file: File): ProcessFileThunk => {
  return async (dispatch, getState) => {
    const { coredata, settings } = getState();
    let workbook: WorkBook;

    try {
      workbook = await parseFileWithSheetJS(file, true, coredata.encoding);
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer: false,
      };
    }

    const sheets = workbook.SheetNames;

    // We only want to upload file if the backendSync flag is set to true and it hasn't already been uploaded
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
      coredata.uploadId === null
    ) {
      await dispatch(uploadRawFileHelper(file));
    }

    if (sheets.length === 0) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_NO_SHEETS",
          messageKey: "dataUploadModal.fileUpload.selectSheet",
        })
      );

      return {
        success: false,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer: false,
      };
    }

    const worksheets: IWorksheet[] = sheets.map((name) => ({
      id: name,
      label: name,
    }));
    dispatch(setSheets(worksheets));
    dispatch(setSelectedSheet(worksheets[0].id));

    if (sheets.length === 1) {
      const { success, requiresMaxRecordsDisclaimer } = await dispatch(
        parseSheetWithSheetJS(true)
      );

      return {
        success,
        requiresSheetSelection: false,
        requiresMaxRecordsDisclaimer,
      };
    } else {
      return {
        success: true,
        requiresSheetSelection: true,
        requiresMaxRecordsDisclaimer: false,
      };
    }
  };
};

export const parseSheetWithSheetJS = (
  previewOnly: boolean
): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const { coredata } = getState();

    const sheetId = coredata.selectedSheetId;
    if (!sheetId) throw new Error("No sheet selected to parse");

    const workbook = await parseFileWithSheetJS(
      coredata.data.file as File,
      previewOnly,
      coredata.encoding
    );
    let parsedData: IParseFileResponse;
    try {
      const data = await parseWorksheetWithSheetJS(workbook.Sheets[sheetId]);
      const headerRow = coredata.rawDataHeaderRow;
      parsedData = processParsedData({ data, headerRow });

      if (previewOnly) {
        parsedData.data.splice(NUM_ROWS_FOR_PREVIEW);
      }
    } catch (err) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_UNPROCESSABLE",
          messageKey: "dataUploadModal.fileUpload.error.cannotProcess",
        })
      );
      return { success: false, requiresMaxRecordsDisclaimer: false };
    }

    if (previewOnly) dispatch(setDataForPreview(parsedData));
    const success = dispatch(checkDataEmpty(parsedData.data));
    const processedData = dispatch(handleNumOfRows(parsedData.data));
    return {
      success,
      data: processedData,
      requiresMaxRecordsDisclaimer:
        parsedData.data.length !== processedData.length,
    };
  };
};

export const processFileWithPapaParse = (file: File): ProcessFileThunk => {
  return async (dispatch, getState) => {
    const { settings, coredata } = getState();

    // We only want to upload file if the backendSync flag is set to true and it hasn't already been uploaded
    if (
      settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
      coredata.uploadId === null
    ) {
      await dispatch(uploadRawFileHelper(file));
    }

    // we attempt to guess the correct encoding with jschardet, but in order for it to
    // work properly, we need to pass it a "binary string" (i.e. a string of raw bytes)
    // we need to go from array buffer -> Uint8Array -> string for this to work.
    const buffer = await file.arrayBuffer();
    const binaryString = new Uint8Array(buffer).reduce(
      (str, char) => str + String.fromCharCode(char),
      ""
    );
    const encoding = jschardet.detect(binaryString).encoding;
    if (encoding) dispatch(setEncoding(encoding));

    const { success, requiresMaxRecordsDisclaimer } = await dispatch(
      parseSheetWithPapaParse(true)
    );

    if (success) {
      // We set sheets here for consistency with other parsing methods
      // We use the filename without the extension to match SheetJS CSV behavior
      const sheetName = file.name.replace(/\.[^.]*$/, "");
      dispatch(setSheets([{ id: sheetName, label: sheetName }]));
      dispatch(setSelectedSheet(sheetName));
    }

    return {
      success,
      requiresSheetSelection: false,
      requiresMaxRecordsDisclaimer,
    };
  };
};

export const parseSheetWithPapaParse = (
  previewOnly: boolean
): ParseSheetThunk => {
  return async (dispatch, getState) => {
    const { coredata, settings } = getState();
    let parsedData: IParseFileResponse;

    if (process.env.JS_PLATFORM === "headless") {
      const { parseFile } = await import("../headless/csv_parsing");

      // @ts-expect-error custom extension added to the Blob polyfill to get a native node buffer
      const buffer = await coredata.data.file.nodeBuffer();
      parsedData = await parseFile(
        buffer,
        coredata.encoding,
        coredata.rawDataHeaderRow,
        settings.delimiter
      );
    } else {
      parsedData = await parseFileWithPapaParse(
        coredata.data.file!,
        coredata.encoding,
        coredata.rawDataHeaderRow,
        previewOnly,
        settings.delimiter
      );
    }

    if (previewOnly) dispatch(setDataForPreview(parsedData));
    const success = dispatch(checkDataEmpty(parsedData.data));
    const processedData = dispatch(handleNumOfRows(parsedData.data));

    return {
      success,
      data: processedData,
      requiresMaxRecordsDisclaimer:
        parsedData.data.length !== processedData.length,
    };
  };
};

const setDataForPreview = (
  parsedData: IParseFileResponse
): AppThunk<boolean> => {
  return (dispatch) => {
    dispatch(setHeaders(parsedData.headers));
    const processedData = dispatch(handleNumOfRows(parsedData.data));

    dispatch(
      setData({
        previewData: processedData,
        rawPreviewData: processedData,
        valCountsInColumn: parsedData.valCountsInColumn,
        percentHasValueInColumn: parsedData.percentHasValueInColumn,
      })
    );

    return true;
  };
};

const checkDataEmpty = (data: string[][]): AppThunk<boolean> => {
  return (dispatch) => {
    if (data.length === 0) {
      dispatch(
        addError({
          type: "data",
          code: "E_FILE_EMPTY",
          messageKey: "dataUploadModal.cannotBeEmpty",
        })
      );
      return false;
    }

    return true;
  };
};

const parser = (
  fileExtension: string,
  settings: ISettingsReduxState
): "BACKEND" | "SHEETJS" | "PAPAPARSE" => {
  if (
    serverFiletypes.has(fileExtension) &&
    settings.backendSyncMode === EBackendSyncMode.FULL_DATA &&
    !settings.backendCapabilities.write_only_storage &&
    !settings.browserExcelParsing
  ) {
    return "BACKEND";
  } else if (localFiletypes.has(fileExtension)) {
    return "PAPAPARSE";
  } else {
    return "SHEETJS";
  }
};
