import React from "react";
import { Alert, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Toggle from "react-toggle";
import { AlertModal } from "../AlertModal";
import ModalHeader from "../ModalHeader";
import { NotProductionBanner } from "../NotProductionBanner";
import TableWrapper from "../TableWrapper";
import { UpsellModal } from "../UpsellModal";
import HelpText from "../styledComponents/HelpText";
import SecondaryText from "../styledComponents/SecondaryText";
import StyledSecondaryButton from "../styledComponents/StyledSecondaryButton";
import { UIDataReviewProps } from ".";

export function DataReviewLegacy({
  alertModal,
  alertModalMessage,
  children,
  data,
  FinishButton,
  fullData,
  globalStyle,
  handleAlertSecondaryButtonClick,
  handleCellDataChange,
  handleCloseUpsellModal,
  handleCreateRow,
  handleHideModal,
  handleRemoveRows,
  handleShowAlert,
  hiddenRows,
  isLoading,
  onGoBackButtonClick,
  onPrimaryButtonClick,
  onProblemOnlyToggleChange,
  primaryButtonDescriptionText,
  primaryButtonText,
  secondaryButtonDescriptionText,
  secondaryButtonText,
  selectedHandsonCell,
  showOnlyRowsWithProblemsToggle,
  showUpsellModal,
  stepErrorMessage,
  stepSettings,
}: UIDataReviewProps) {
  const { t } = useTranslation();

  return (
    <div>
      <AlertModal
        show={alertModal.show}
        setShow={handleShowAlert}
        message={alertModalMessage}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        primaryButtonDescriptionText={primaryButtonDescriptionText}
        secondaryButtonDescriptionText={secondaryButtonDescriptionText}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={handleAlertSecondaryButtonClick}
        showSecondaryButton={secondaryButtonText !== ""}
      >
        {children}
      </AlertModal>
      <UpsellModal
        show={showUpsellModal}
        onClose={handleCloseUpsellModal}
      ></UpsellModal>
      <Modal
        animation={false}
        dialogClassName="fullScreenModal"
        show
        backdrop={false}
        keyboard={false}
        style={globalStyle}
        onHide={handleHideModal}
        data-cy="DataReviewModal"
      >
        <NotProductionBanner />
        <ModalHeader stepperCompletedIdx={3} />
        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}
        {stepErrorMessage && (
          <Alert variant="danger" className="helpText">
            <div
              dangerouslySetInnerHTML={{
                __html: stepErrorMessage,
              }}
            />
          </Alert>
        )}
        {isLoading ? (
          <Modal.Body>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">{t("common.loading")}</span>
              </Spinner>
              {stepSettings.processingText && (
                <SecondaryText as="div" style={{ marginTop: "1rem" }}>
                  {stepSettings.processingText}
                </SecondaryText>
              )}
            </div>
          </Modal.Body>
        ) : (
          <div style={{ borderBottom: "1px solid #eee" }}>
            <div style={{ marginLeft: 40, marginBottom: 16 }}>
              <Toggle
                checked={showOnlyRowsWithProblemsToggle}
                icons={false}
                onChange={onProblemOnlyToggleChange}
              />
              <SecondaryText className="problemsToggleSpan">
                {t("v1.dataReviewModal.showErrorsToggle")}
              </SecondaryText>
            </div>
            <Modal.Body className="dataReviewModalBody">
              <TableWrapper
                tableData={fullData.map((r) => [...r])}
                onCellDataChange={handleCellDataChange}
                onRemoveRow={handleRemoveRows}
                onCreateRow={handleCreateRow}
                hiddenRows={hiddenRows}
                tableHeight="67vh"
                autoAddRow={!showOnlyRowsWithProblemsToggle}
                selectedCell={selectedHandsonCell}
              />
            </Modal.Body>
          </div>
        )}
        <Modal.Footer className="modalFooter">
          {data.uploadType !== "INITIAL_DATA_MAPPED" && (
            <StyledSecondaryButton
              className="modalFooterSecondaryButton"
              onClick={onGoBackButtonClick}
            >
              {t("common.goBack")}
            </StyledSecondaryButton>
          )}
          <FinishButton />
        </Modal.Footer>
      </Modal>
    </div>
  );
}
