// This file is used as the default configuration when running the frontend
// in "direct mount" mode, with `npm run start:direct_mount`

/* eslint-disable no-console, @typescript-eslint/no-unused-vars */

import { IWrapperConfig } from "./components/UploaderTestWrapper";
import { VERSION_TWO } from "./constants/constants";

const standardConfig: IWrapperConfig = {
  licenseKey: "528b243d-b053-4be1-adaf-c1418d5a5713",
  fields: [
    {
      label: "First Name",
      key: "firstName",
    },
    {
      label: "Last Name",
      key: "lastName",
    },
    {
      label: "Email",
      key: "email",
      type: "email",
      validators: [
        {
          validate: "required",
        },
      ],
    },
  ],
  settings: {
    importIdentifier: "test",
    version: VERSION_TWO,
    browserExcelParsing: true,
  },
  user: { id: "test" },
  columnHooks: [],
  rowHooks: [],
  bulkRowHooks: [],
  stepHooks: [],
  rowDeleteHooks: [],
  resultsCallback: (data, _metadata) => {
    console.table(data);
  },
  cancelCallback: () => {
    console.log("cancelled");
  },
};

const savedSchemaConfig: IWrapperConfig = {
  licenseKey: "ae153fbd-c96f-4705-a7b3-ad792db3ef75",
  schemaName: "basic",
};

export default standardConfig;
// export default savedSchemaConfig;
