import { ITableMessageInternal } from "../interfaces/interfaces";

export type ITransformResult<ValueType> =
  | { success: true; value: ValueType; empty?: false }
  | { success: false; message: ITableMessageInternal; empty?: false }
  | { empty: true };

export interface BaseTypeOpts {
  invalidValueMessage?: string;
}

/**
 * AbstractField is a blueprint for different field types.
 * Fields handle four different types of values:
 * Raw value - strings that come from user input (imported file, manual input, data from hooks)
 * Internal value - the canonical format that we use to store data in our state
 * Display value - the string value that is shown to the user in the review interface
 * Output value - The value provided to the user in onResults
 */
export abstract class AbstractField<
  TypeOpts extends BaseTypeOpts,
  InternalValue
> {
  static defaultInvalidValueMessage = "Invalid value";

  /**
   * The name of this field's type
   */
  abstract readonly type: string;

  /**
   * An object with any configuration this field type takes
   */
  opts: TypeOpts;

  /**
   * The validation message used if the transform fails
   */
  invalidValueMessage: string;

  constructor(opts: TypeOpts) {
    this.opts = opts;
    this.invalidValueMessage =
      opts.invalidValueMessage ??
      (this.constructor as typeof AbstractField).defaultInvalidValueMessage;
  }

  /**
   * Takes an internal value and performs any additional validations
   * that this field provides.
   * If the value is valid, returns null.
   * If the value is invalid, returns a table message of type field-transform.
   * This is not called if the transform step was not successful.
   */
  validate(_value: InternalValue): ITableMessageInternal | null {
    return null;
  }

  /**
   * Takes the raw value from the user (either on init or update)
   * and returns an internal value . This may also return a validation error,
   * for example if the raw value was unparseable. In the event that the
   * transform function returns a validation error, the validate function
   * will not be called afterwards.
   *
   * Will not be called with empty values ("").
   *
   * This function should return a call to either this.transformSuccess
   * or this.transformFailure.
   */
  abstract transform(
    rawValue: string,
    _row: number
  ): ITransformResult<InternalValue>;

  /**
   * Runs the field's transform function, but checks for an empty value, and
   * if so, returns an empty result.
   */
  transformChecked(
    rawValue: string | null,
    row: number
  ): ITransformResult<InternalValue> {
    if (rawValue === "" || rawValue === null || rawValue === undefined)
      return { empty: true };

    return this.transform(rawValue, row);
  }

  /**
   * When a new row is added, this function will be called to set
   * this field's internal value
   */
  getInitialValue(): InternalValue | "" {
    return "";
  }

  /**
   * Takes an internal value from the table and returns a string to display to the
   * user in the review screen.
   * Never called with empty or invalid values.
   */
  abstract getDisplayValue(value: InternalValue, row: number): string;

  /**
   * Used for external consumers to get the display value with a possibly empty value
   */
  getDisplayValueChecked(value: "" | InternalValue, row: number): string {
    if (value === "" || value === null || value === undefined) return "";

    return this.getDisplayValue(value, row);
  }

  /**
   * Takes a display value from the table and returns the final output value.
   * Never called with empty or invalid values.
   */
  abstract getOutputValue(value: InternalValue, row: number): any;

  /**
   * Used for external consumers to get the output value with a possibly empty value
   */
  getOutputValueChecked(value: "" | InternalValue, row: number): any {
    if (value === "" || value === null || value === undefined) return "";

    return this.getOutputValue(value, row);
  }

  /**
   * Used as the value that is shown when a user starts editing a cell
   */
  getEditValue(value: InternalValue, row: number): any {
    return this.getDisplayValue(value, row);
  }

  getEditValueChecked(value: "" | InternalValue, row: number): any {
    if (value === "" || value === null || value === undefined) return "";

    return this.getEditValue(value, row);
  }

  protected transformSuccess(
    value: InternalValue
  ): ITransformResult<InternalValue> {
    return {
      success: true,
      value,
    };
  }

  protected transformFailure(): ITransformResult<InternalValue> {
    return {
      success: false,
      message: {
        type: "field-transform",
        level: "error",
        message: this.invalidValueMessage,
      },
    };
  }
}
