import React from "react";
import { PlaceholderProps, components } from "react-select";
import Text from "../Text";
import { useSelectContext } from "./context";
import { IgnoredLabel } from "./IgnoredLabel";

export const Placeholder = (props: PlaceholderProps<any>) => {
  const { isIgnored } = useSelectContext();

  return (
    <components.Placeholder {...props} className="!text-sm">
      <div className="relative !text-sm inline-flex gap-3 w-full justify-between items-center">
        {isIgnored ? (
          <IgnoredLabel />
        ) : (
          <Text type="body" as="span" className="opacity-50">
            Unmatched
          </Text>
        )}
      </div>
    </components.Placeholder>
  );
};
