import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";
import { Spinner } from "react-bootstrap";

import { UIColumnMatchProps } from ".";
import { ReactComponent as PlusIcon } from "../../assets/plus.svg";
import { AlertModalRedesign } from "../AlertModal";
import HelpText from "../HelpTextRedesign";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import Modal from "../commonComponents/Modal";
import Toggle from "../commonComponents/Toggle";
import { Alert } from "../commonComponents/Alert";
import { Card } from "../commonComponents/Card";
import { MissingFieldsWarning } from "../commonComponents/MissingFieldsWarning";
import {
  nextStepFromColumnMatching,
  previousStepFromColumnMatching,
} from "../../helpers/nextStep";
import { useAppDispatch } from "../../store/hooks";

export function ColumnMatchView({
  allowCustomFields,
  areAllColumnsMatched,
  cards,
  filterMatchedColumns,
  onAddAllUnmatchedAsCustomFields,
  showSpinner,
  stepSettings,
  toggleFilterMatchedColumns,
  missingRequiredFieldKeys,
  onAddEmptyColumn,
  emptyFieldCards,
  getLabelByKey,
  duplicates,
  hideBackButton,
}: UIColumnMatchProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [confirmBackModal, setConfirmBackModal] = useState(false);

  const handleConfirmBack = () => {
    dispatch(previousStepFromColumnMatching());
  };

  const handleConfirmBackCancel = () => {
    setConfirmBackModal(false);
  };

  const handleConfirmBackShow = hideBackButton
    ? undefined
    : () => {
        setConfirmBackModal(true);
      };

  const handleNextClick = () => {
    dispatch(nextStepFromColumnMatching());
  };

  return (
    <>
      <AlertModalRedesign
        show={confirmBackModal}
        setShow={setConfirmBackModal}
        message={t("common.clearAllProgressAlert")}
        primaryButtonText={t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={handleConfirmBack}
        onSecondaryButtonClick={handleConfirmBackCancel}
        showSecondaryButton={true}
        data-cy="column-match-alert"
      />
      <Modal
        data-cy="ColumnMatchModal"
        onBack={handleConfirmBackShow}
        step="matchColumns"
      >
        <div className="flex flex-wrap lg:flex-nowrap items-center lg:justify-between">
          <div>
            <Text type="h1" className="mb-2">
              {t("columnMatchModal.title")}
            </Text>
            <Text type="body">{t("columnMatchModal.subtitle")}</Text>
          </div>
          <div className="flex gap-4 items-center self-end ml-auto mt-4 lg:mt-0">
            {filterMatchedColumns && allowCustomFields && (
              <Button
                theme="ghost"
                className="flex gap-2 items-center self-end font-normal p-0"
                onClick={onAddAllUnmatchedAsCustomFields}
              >
                <PlusIcon />
                <span> {t("columnMatchModal.addAllCustomFieldsButton")}</span>
              </Button>
            )}
            <div
              className={twMerge(
                "flex gap-2 items-center self-end",
                areAllColumnsMatched && "opacity-60"
              )}
            >
              <Toggle
                onChange={toggleFilterMatchedColumns}
                checked={filterMatchedColumns}
                disabled={areAllColumnsMatched}
                label={t("columnMatchModal.showUnmatchedToggle")}
              />
            </div>
          </div>
        </div>

        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}

        <Card className="my-4 mt-6">
          <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
            <div className="grid grid-cols-2 divide-x divide-ice-300">
              <Text type="medium" className="px-4 py-2">
                {t("columnMatchModal.yourColumn")}
              </Text>
              <Text type="medium" className="px-4 py-2">
                {t("columnMatchModal.matchesTo")}
              </Text>
            </div>

            {showSpinner ? (
              <div className="flex flex-col items-center py-4 bg-white">
                <Spinner animation="border" role="status">
                  <span className="sr-only">{t("common.loading")}</span>
                </Spinner>
                <Text className="mt-2">
                  {t("columnMatchModal.aiMatchesLoading", "common.loading")}
                </Text>
              </div>
            ) : (
              <>
                {cards}

                {emptyFieldCards}
              </>
            )}
          </div>
        </Card>

        <div className="space-y-2">
          {duplicates.length > 0 && (
            <Alert type="error">
              {t("columnMatchModal.duplicateFieldsAlert")}{" "}
              {duplicates.join(", ")}
            </Alert>
          )}

          {missingRequiredFieldKeys.length > 0 && !showSpinner && (
            <MissingFieldsWarning
              missingRequiredFieldKeys={missingRequiredFieldKeys}
              onAddEmptyColumn={onAddEmptyColumn}
              getLabelByKey={getLabelByKey}
            />
          )}
        </div>

        <div className="flex justify-end items-center gap-2 w-full mt-6">
          <Button
            onClick={handleNextClick}
            disabled={
              missingRequiredFieldKeys.length > 0 || duplicates.length > 0
            }
            data-cy="column-match-continue-button"
            autoFocus
          >
            {t("columnMatchModal.continue")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
