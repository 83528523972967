import React, { useMemo } from "react";
import type { Connection } from "penpal";
import { ParentConnectionContext } from "../components/ParentConnectionContext";
import {
  EStepHook,
  IColumnHook,
  IColumnHookInput,
  IColumnHookOutput,
  IRowDeleteHook,
  IResultMetadata,
  IReviewStepData,
  IRowHook,
  IRowHookInput,
  IRowHookOutput,
  IRowHookOutputInternal,
  IStepHook,
  ITableMessage,
  IUploadStepData,
  IBulkRowHook,
  IBeforeFinishCallback,
  IBeforeFinishOutput,
  IPublicConnectionMethods,
  IParentConnectionMethods,
  IRowCell,
  IRow,
  IRowCellBasic,
  IRowHookCellBasic,
} from "../interfaces";

const fieldOutputEmpty = (fieldOutput: IRowCell): boolean =>
  fieldOutput.value === undefined &&
  fieldOutput.info === undefined &&
  fieldOutput.selectOptions === undefined &&
  fieldOutput.manyToOne === undefined;

export interface IUploaderInternal extends IPublicConnectionMethods {
  columnHooks: IColumnHook[];
  rowHooks: { callback: IRowHook }[];
  bulkRowHooks: IBulkRowHook[];
  stepHooks: IStepHook[];
  rowDeleteHooks: IRowDeleteHook[];
  beforeFinishCallback?: IBeforeFinishCallback;
  resultsCallback?: (
    data: any,
    metadata: IResultMetadata
  ) => Promise<void> | void;
  cancelCallback?: () => void;
  close: () => void;
}

type ProviderProps = React.PropsWithChildren<{ parent: IUploaderInternal }>;

export const MockParentConnectionProvider: React.FC<ProviderProps> = ({
  parent,
  children,
}) => {
  const connection = useMemo(() => createMockConnection(parent), [parent]);

  return (
    <ParentConnectionContext.Provider value={connection}>
      {children}
    </ParentConnectionContext.Provider>
  );
};

export function createMockConnection(
  self: IUploaderInternal
): Connection<IParentConnectionMethods> {
  return {
    destroy: () => {
      /* do nothing */
    },
    promise: Promise.resolve({
      /**
       * Called for each column/field. Sends all data and expects all the
       * relevant column hooks to be evaluated.
       *
       * @param fieldName the fieldname key for the column
       * @param data the full column of data for the particular key
       */
      async handleColumnHooks(
        fieldName: string,
        data: IColumnHookInput[]
      ): Promise<IColumnHookOutput[]> {
        // Get all the hooks for this particular field
        const hooks = self.columnHooks.filter((h) => h.fieldName === fieldName);
        // key is the index in the column (row). Value stores newValue and info.
        // This is the final object that is passed to the app to apply to the
        // global data state.
        const changes = new Map<
          number,
          { newValue?: string; info?: ITableMessage[] }
        >();
        // Each hook call updates this tmpData object with the latest values.
        // This allows us to run hooks sequentially with each subsequent call
        // having the most up to date data
        const tmpData: IColumnHookInput[] = data;

        // For each registered hook, call the developer defined callback
        await hooks.reduce(async (previousHookPromise, currentHook) => {
          await previousHookPromise.then(async () => {
            try {
              const hookResponse = await currentHook.callback(tmpData);
              hookResponse.forEach((hookOutput: IColumnHookOutput) => {
                // If the user has updated the value or info for a particular
                // index update the changes map, and the tmpData
                if (
                  hookOutput.value !== undefined ||
                  hookOutput.info !== undefined
                ) {
                  const change: {
                    value?: string;
                    info?: ITableMessage[];
                  } = {};
                  if (hookOutput.value !== undefined) {
                    change.value = hookOutput.value;
                    tmpData[hookOutput.index] = hookOutput.value;
                  }
                  if (hookOutput.info !== undefined)
                    change.info = hookOutput.info;
                  changes.set(hookOutput.index, change);
                }
              });
            } catch (err) {
              console.error(
                "[Dromo-External-Error] There was an error running your col hook.",
                err
              );
            }
          });
        }, Promise.resolve());

        // Change the map into IColumnHookOuput for the application to process
        const columnHookOutput: IColumnHookOutput[] = [];
        changes.forEach((change, index) =>
          columnHookOutput.push({ ...change, index })
        );
        return columnHookOutput;
      },

      /**
       * Called singularly for each row. Goes through all row hooks and
       * evaluates them.
       *
       * @param data an array of row data objects
       */
      async handleRowHooks(
        data: IRowHookInput[],
        mode: "init" | "update"
      ): Promise<IRowHookOutputInternal[]> {
        // Changes is the final changes that will be sent to the frontend
        const changes = new Map<number, IRow>();
        const inputRowMap = new Map(
          data.map((inputRow) => [inputRow.index, inputRow])
        );

        // a helper function called for each row that updates the final changes
        // as well as the row data which will be passed to subsequent hooks
        const updateForRow = (
          rowIndex: number,
          rowOutput: IRowHookOutput["row"]
        ) => {
          const inputRow = inputRowMap.get(rowIndex)!;

          for (const [fieldName, fieldOutput] of Object.entries(
            rowOutput ?? {}
          )) {
            if (fieldOutputEmpty(fieldOutput as IRowCell)) continue;

            const inputRowField = inputRow.row[fieldName];
            const inputFieldIsManyToOne = Array.isArray(
              inputRowField.manyToOne
            );
            const outputFieldIsManyToOne =
              "manyToOne" in fieldOutput &&
              Array.isArray(fieldOutput.manyToOne);

            if (inputFieldIsManyToOne && !outputFieldIsManyToOne) {
              throw new Error(
                `${fieldName} should have manyToOne defined as an array.`
              );
            } else if (
              inputFieldIsManyToOne &&
              outputFieldIsManyToOne &&
              inputRowField.manyToOne!.length !== fieldOutput.manyToOne.length
            ) {
              throw new Error(
                `${fieldName} should be array of length ${
                  inputRowField.manyToOne!.length
                }`
              );
            }

            const rowChanges = changes.get(rowIndex) || {};
            const cellChange: IRowCell = rowChanges[fieldName] ?? {};

            const fieldChanges: {
              fieldOutput: IRowCellBasic;
              rowDataField: IRowHookCellBasic;
              cellChange: IRowCellBasic;
            }[] = [];

            if (outputFieldIsManyToOne) {
              cellChange.manyToOne = [];

              fieldOutput.manyToOne.forEach((fieldOutputCell, index) => {
                if (!fieldOutputEmpty(fieldOutputCell as IRowCell)) {
                  const manyToOneCellChange: IRowCellBasic = {};
                  cellChange.manyToOne!.push(manyToOneCellChange);
                  fieldChanges.push({
                    fieldOutput: fieldOutputCell,
                    rowDataField: inputRowField.manyToOne![index],
                    cellChange: manyToOneCellChange,
                  });
                }
              });
            } else {
              fieldChanges.push({
                fieldOutput: fieldOutput as IRowCellBasic,
                rowDataField: inputRowField,
                cellChange,
              });
            }
            // if there are any changes, we want to both update the changes
            // object sent to the app, and the rowInput sent to subsequent
            // row hooks running on this row so they see the changes from
            // prior row hooks
            fieldChanges.forEach(
              ({ fieldOutput, rowDataField, cellChange }) => {
                if (fieldOutput.value !== undefined) {
                  cellChange.value = fieldOutput.value;
                  rowDataField.value = fieldOutput.value;
                }

                if (fieldOutput.info !== undefined) {
                  cellChange.info = fieldOutput.info;
                  rowDataField.info = fieldOutput.info;
                }

                if (fieldOutput.selectOptions !== undefined) {
                  cellChange.selectOptions = fieldOutput.selectOptions;
                  rowDataField.selectOptions = fieldOutput.selectOptions;
                }
              }
            );
            rowChanges[fieldName] = cellChange;
            changes.set(rowIndex, rowChanges);
          }
        };

        // First we run the bulk row hooks with all of the input data
        for (const bulkRowHook of self.bulkRowHooks) {
          try {
            const hookOutput = await bulkRowHook(data, mode);
            for (const rowOutput of hookOutput) {
              updateForRow(rowOutput.index, rowOutput.row);
            }
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error running your bulk row hook.",
              err
            );
          }
        }

        // We want to loop through each row independently. Because each
        // row's execution is independent, we can do row by row first vs
        // hook by hook
        await Promise.all(
          data.map(async (rowInput: IRowHookInput) => {
            for (const rowHook of self.rowHooks) {
              try {
                const hookOutput = await rowHook.callback(rowInput, mode);
                updateForRow(rowInput.index, hookOutput.row);
              } catch (err) {
                console.error(
                  "[Dromo-External-Error] There was an error running your row hook.",
                  err
                );
              }
            }
          })
        );

        const finalChanges: IRowHookOutputInternal[] = [];

        changes.forEach((rowChanges, rowIndex) => {
          finalChanges.push({ index: rowIndex, row: rowChanges });
        });

        return finalChanges;
      },
      /**
       * Called singularly for each row. Goes through all delete hooks and
       * evaluates them.
       *
       * @param data an array of row data objects
       */
      async handleRowDeleteHooks(deletedRows: IRowHookInput[]): Promise<void> {
        // We want to loop through each row independently. Because each
        // row's execution is independent, we can do row by row first vs
        // hook by hook
        await Promise.all(
          deletedRows.map(async (rowData: IRowHookInput) => {
            // Each hook call updates this tmpData object with the latest values.
            // This allows us to run hooks sequentially with each subsequent call
            // having the most up to date data

            self.rowDeleteHooks.forEach(async (currentHook) => {
              try {
                await currentHook(rowData);
              } catch (err) {
                console.error(
                  "[Dromo-External-Error] There was an error running your row hook.",
                  err
                );
              }
            });
          })
        );
      },

      async handleStepHook(
        step: EStepHook,
        data: IUploadStepData | IReviewStepData
      ) {
        self.stepHooks.forEach((hook) => {
          if (step !== hook.type) return;
          try {
            hook.callback(self, data);
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your step callback.",
              err
            );
          }
        });
      },

      async handleBeforeFinishCallback(
        data: Record<string, any>[],
        metadata: IResultMetadata
      ): Promise<IBeforeFinishOutput> {
        if (self.beforeFinishCallback) {
          try {
            return await self.beforeFinishCallback(data, metadata, self);
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your beforeFinish callback.",
              err
            );
          }
        }
      },
      /**
       * Called after the user has completed the Dromo flow, send the
       * cleaned data to the developer
       *
       * @param data cleaned data after the flow is complete
       */
      async handleResults(
        data: Record<string, any>[],
        metadata: IResultMetadata
      ): Promise<void> {
        if (self.resultsCallback) {
          try {
            await self.resultsCallback(data, metadata);
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your onResult callback.",
              err
            );
          }
        }
        self.close();
      },
      /**
       * Called when the modal has been closed by the finishing of the flow
       */
      async handleCloseModal() {
        self.close();
      },
      /**
       * Called when the modal has been closed due to the user canceling the
       * flow
       */
      async handleCancel() {
        if (self.cancelCallback) {
          try {
            self.cancelCallback();
          } catch (err) {
            console.error(
              "[Dromo-External-Error] There was an error in your cancel callback.",
              err
            );
          }
        }
        self.close();
      },
    }),
  };
}
