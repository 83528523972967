import {
  IData,
  IDeveloperSelectOption,
  ITableMessageInternal,
  IWorksheet,
  IRowToAdd,
} from "../../interfaces";
import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { setFromDeveloperSettings } from "./settings";
import { RootState } from "../reducers";
import {
  aiMatchColumns,
  aiOrFuzzyMatchSelectOptions,
} from "../../thunks/column_matching";
import { SelectOptionOverride } from "../../thunks/data_actions";
import { SelectField } from "../../fields/select";
import Fuse from "fuse.js";

// Top level map is field key -> map of rows that have overrides
// Next level is rowIndex -> overrides
// Overrides is label -> value
export type SelectOptionOverrides = Map<
  string,
  Map<number, Map<string, string>>
>;

interface ICoreDataReduxState {
  data: IData;
  // originalFilename will be null if data came from
  // manual entry or initialData
  originalFilename: string | null;
  originalNumRows: number;
  // if the uploaded file is an excel file, an array of sheets
  sheets: IWorksheet[] | null;
  // if the uploaded file is an excel file, the sheet ID being used for this import
  selectedSheetId: string | null;
  rawDataHeaderRow: number | null;
  headers: string[] | null;
  encoding: string;
  // Cells in `row,column` form that had an error when doing
  // the field transform on the data
  transformErrorCells: Set<string>;
  // Map of matches for each unique value of each column
  matchResults: Map<
    number,
    { [x: string]: any } // Fuse.FuseResult<IDeveloperSelectOption> }
  >;
  tableMessages: Map<string, ITableMessageInternal[]>;
  // Map of stringified array of options to label->value map, which we most often use
  selectOptionOverrideSets: Map<string, Map<string, string>>;
  selectOptionOverrides: SelectOptionOverrides;
  // Raw upload id
  uploadId: string | null;
  // Processed data id
  processedDataId: string | null;
  // The HeadlessImport id, if this is a headless import
  headlessImportId: string | null;
  // count of registered webhooks
  numRegisteredColHooks: number;
  numRegisteredRowHooks: number;
  numRegisteredRowDeleteHooks: number;
  aiColMatchStatus: "idle" | "pending" | "fulfilled";
  aiMatchSelectOptionStatus: Map<number, "idle" | "pending" | "fulfilled">;
  // An array of field keys that the user has opted to add empty columns for
  initialized: boolean;
  rowsToAdd: IRowToAdd[];
  rowsToDelete: string[];
}

const initialState: ICoreDataReduxState = {
  data: {
    previewData: [[]],
    rawPreviewData: [[]],
    file: null,
    uploadType: "FILE",
    valCountsInColumn: null,
    percentHasValueInColumn: null,
  },
  encoding: "UTF-8",
  originalFilename: null,
  originalNumRows: 0,
  sheets: null,
  selectedSheetId: null,
  rawDataHeaderRow: null,
  headers: null,
  transformErrorCells: new Set(),
  matchResults: new Map(),
  tableMessages: new Map(),
  selectOptionOverrideSets: new Map(),
  selectOptionOverrides: new Map(),
  uploadId: null,
  processedDataId: null,
  headlessImportId: null,
  numRegisteredColHooks: 0,
  numRegisteredRowHooks: 0,
  numRegisteredRowDeleteHooks: 0,
  aiColMatchStatus: "idle",
  aiMatchSelectOptionStatus: new Map(),
  initialized: false,
  rowsToAdd: [],
  rowsToDelete: [],
};

const coredataSlice = createSlice({
  name: "coredata",
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },

    resetStateBackDateUpload: (state) => {
      return {
        ...initialState,
        numRegisteredColHooks: state.numRegisteredColHooks,
        numRegisteredRowHooks: state.numRegisteredRowHooks,
        numRegisteredRowDeleteHooks: state.numRegisteredRowDeleteHooks,
      };
    },

    resetStatePreReview: (state) => {
      state.tableMessages = initialState.tableMessages;
      state.transformErrorCells = initialState.transformErrorCells;
      state.selectOptionOverrideSets = initialState.selectOptionOverrideSets;
      state.selectOptionOverrides = initialState.selectOptionOverrides;
    },

    setEncoding: (state, action: PayloadAction<string>) => {
      state.encoding = action.payload;
    },

    setOriginalFilename: (state, action: PayloadAction<string | null>) => {
      state.originalFilename = action.payload;
    },

    setOriginalNumRows: (state, action: PayloadAction<number>) => {
      state.originalNumRows = action.payload;
    },

    setHeaders: {
      prepare: (headers: string[] | null, rawDataHeaderRow?: number | null) => {
        return { payload: { headers, rawDataHeaderRow } };
      },
      reducer: (
        state,
        action: PayloadAction<{
          headers: string[] | null;
          rawDataHeaderRow: number | null | undefined;
        }>
      ) => {
        state.headers = action.payload.headers;
        if (action.payload.rawDataHeaderRow !== undefined)
          state.rawDataHeaderRow = action.payload.rawDataHeaderRow;
      },
    },

    setData: (state, action: PayloadAction<Partial<IData>>) => {
      state.data = { ...state.data, ...action.payload };
    },

    setDataOverwrittenValuesTableMessages: (
      state,
      action: PayloadAction<Map<string, ITableMessageInternal[]>>
    ) => {
      state.tableMessages = action.payload;
    },

    addMatchResult: (
      state,
      action: PayloadAction<{
        columnIndex: number;
        uniqueColumnValue: string;
        result: Fuse.FuseResult<IDeveloperSelectOption>;
      }>
    ) => {
      const { columnIndex, result, uniqueColumnValue } = action.payload;

      if (!state.matchResults.has(columnIndex)) {
        state.matchResults.set(columnIndex, {
          [uniqueColumnValue]: result,
        });
      } else {
        state.matchResults.get(columnIndex)![uniqueColumnValue] = result;
      }
    },

    setTableMessages: (
      state,
      action: PayloadAction<Map<string, ITableMessageInternal[]>>
    ) => {
      state.tableMessages = action.payload;
    },

    setNewSelectOptionOverrideSets: (
      state,
      action: PayloadAction<Map<string, Map<string, string>>>
    ) => {
      state.selectOptionOverrideSets = action.payload;
    },

    setSelectOptionOverrides: (
      state,
      action: PayloadAction<SelectOptionOverrides>
    ) => {
      state.selectOptionOverrides = action.payload;
    },

    updateSelectOptionOverrides: (
      state,
      action: PayloadAction<SelectOptionOverride[]>
    ) => {
      const overridesByField = action.payload.reduce(
        (byField, [index, fieldKey, selectOptions]) => {
          byField[fieldKey] ??= [];
          byField[fieldKey].push([index, selectOptions]);
          return byField;
        },
        {} as Record<string, [number, IDeveloperSelectOption[]][]>
      );

      for (const [fieldKey, optionsSets] of Object.entries(overridesByField)) {
        if (!state.selectOptionOverrides.has(fieldKey)) {
          state.selectOptionOverrides.set(fieldKey, new Map());
        }

        for (let [rowIndex, selectOptions] of optionsSets) {
          selectOptions = selectOptions.map(SelectField.normalizeOption);
          const optionsString = JSON.stringify(selectOptions);

          let optionsSet: Map<string, string>;

          // check to see if we have a 'master copy' of these options
          if (state.selectOptionOverrideSets.has(optionsString)) {
            optionsSet = state.selectOptionOverrideSets.get(optionsString)!;
          } else {
            optionsSet = new Map<string, string>(
              selectOptions.map(({ label, value }) => [label, value])
            );
            state.selectOptionOverrideSets.set(optionsString, optionsSet);
          }

          state.selectOptionOverrides.get(fieldKey)!.set(rowIndex, optionsSet);
        }
      }
    },

    setUploadId: (state, action: PayloadAction<string | null>) => {
      state.uploadId = action.payload;
    },

    setProcessedDataId: (state, action: PayloadAction<string>) => {
      state.processedDataId = action.payload;
    },

    setHeadlessImportId: (state, action: PayloadAction<string>) => {
      state.headlessImportId = action.payload;
    },

    setRawDataHeaderRow: (state, action: PayloadAction<number | null>) => {
      state.rawDataHeaderRow = action.payload;
    },

    setSheets: (state, action: PayloadAction<IWorksheet[] | null>) => {
      state.sheets = action.payload;
    },

    setSelectedSheet: (state, action: PayloadAction<string | null>) => {
      state.selectedSheetId = action.payload;
    },

    setNumRegisteredColHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredColHooks = action.payload;
    },

    setNumRegisteredRowHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredRowHooks = action.payload;
    },

    setNumRegisteredRowDeleteHooks: (state, action: PayloadAction<number>) => {
      state.numRegisteredRowDeleteHooks = action.payload;
    },

    setCellTransformErrors: (state, action: PayloadAction<Set<string>>) => {
      state.transformErrorCells = action.payload;
    },

    addCellTransformError: (state, action: PayloadAction<string>) => {
      state.transformErrorCells.add(action.payload);
    },

    removeCellTransformError: (state, action: PayloadAction<string>) => {
      state.transformErrorCells.delete(action.payload);
    },

    setInitialized: (state) => {
      state.initialized = true;
    },

    enqueueAddRows: (state, action: PayloadAction<IRowToAdd[]>) => {
      state.rowsToAdd = [...state.rowsToAdd, ...action.payload];
    },

    clearAddRowQueue: (state) => {
      state.rowsToAdd = [];
    },

    enqueueRemoveRows: (state, action: PayloadAction<string[]>) => {
      state.rowsToDelete = [...state.rowsToDelete, ...action.payload];
    },

    clearRemoveRowQueue: (state) => {
      state.rowsToDelete = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setFromDeveloperSettings, (state, action) => {
      if (action.payload.matchingStep.headerRowOverride !== null) {
        state.rawDataHeaderRow = action.payload.matchingStep.headerRowOverride;
      }
    });
    builder.addCase(aiMatchColumns.fulfilled, (state, _action) => {
      state.aiColMatchStatus = "fulfilled";
    });
    builder.addCase(aiMatchColumns.pending, (state, _action) => {
      state.aiColMatchStatus = "pending";
    });
    builder.addCase(aiOrFuzzyMatchSelectOptions.fulfilled, (state, action) => {
      state.aiMatchSelectOptionStatus = new Map([
        ...state.aiMatchSelectOptionStatus,
      ]);
      state.aiMatchSelectOptionStatus.set(action.meta.arg, "fulfilled");
    });
    builder.addCase(aiOrFuzzyMatchSelectOptions.pending, (state, action) => {
      state.aiMatchSelectOptionStatus = new Map([
        ...state.aiMatchSelectOptionStatus,
      ]);
      state.aiMatchSelectOptionStatus.set(action.meta.arg, "pending");
    });
  },
});

export const selectHasEmptyHeaders = (state: ICoreDataReduxState): boolean =>
  state.headers ? state.headers.every((header) => !header) : true;

export const selectRowsWithErrors = (
  state: ICoreDataReduxState
): Set<number> => {
  const errorRows = new Set<number>();
  // Get count of all table message items that are "errors"
  state.tableMessages.forEach((messages, key) => {
    if (messages.find((message) => message.level === "error")) {
      const rowNumber = parseInt(key.split(",")[0]);
      errorRows.add(rowNumber);
    }
  });

  return errorRows;
};

export const selectHasValidationErrors = (state: RootState): boolean => {
  for (const messages of state.coredata.tableMessages.values()) {
    if (messages.some((m) => m.level === "error")) return true;
  }
  return false;
};

export const selectUniqueValsInColumn = (
  data: IData
): Map<number, Set<string>> => {
  const valueCountMap = data.valCountsInColumn;
  const uniqValMap = new Map<number, Set<string>>();
  if (valueCountMap !== null) {
    valueCountMap.forEach((valueCounts, colIdx) =>
      uniqValMap.set(colIdx, new Set(valueCounts.keys()))
    );
  }
  return uniqValMap;
};

export const selectColErrors = createSelector(
  (state: RootState) => state.coredata.tableMessages,
  (tableMessages): Map<number, number[]> => {
    const errors = new Map<number, number[]>();

    tableMessages.forEach((messages, key) => {
      if (messages.some((message) => message.level === "error")) {
        const [rowString, colString] = key.split(",");
        const col = parseInt(colString, 10);
        const row = parseInt(rowString, 10);

        if (!errors.has(col)) {
          errors.set(col, [row]);
        } else {
          errors.get(col)!.push(row);
        }
      }
    });

    return errors;
  }
);

export const selectRawRowWidth = (
  columnMapping: Map<number, unknown>
): number => {
  if (columnMapping.size === 0) return 0;
  return Math.max(...columnMapping.keys()) + 1;
};

export const {
  resetState,
  resetStateBackDateUpload,
  resetStatePreReview,
  setEncoding,
  setOriginalFilename,
  setOriginalNumRows,
  setHeaders,
  setRawDataHeaderRow,
  setData,
  setDataOverwrittenValuesTableMessages,
  addMatchResult,
  setNewSelectOptionOverrideSets,
  setSelectOptionOverrides,
  updateSelectOptionOverrides,
  setTableMessages,
  setUploadId,
  setProcessedDataId,
  setHeadlessImportId,
  setSheets,
  setSelectedSheet,
  setNumRegisteredColHooks,
  setNumRegisteredRowHooks,
  setNumRegisteredRowDeleteHooks,
  setCellTransformErrors,
  addCellTransformError,
  removeCellTransformError,
  setInitialized,
  enqueueAddRows,
  clearAddRowQueue,
  enqueueRemoveRows,
  clearRemoveRowQueue,
} = coredataSlice.actions;

export default coredataSlice.reducer;
