import * as React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "../../store/reducers";

type StyledButtonLinkProps = {
  onClick(event: any): void;
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const StyledButtonLink = ({
  style,
  className,
  onClick,
  children,
  ...restProps
}: StyledButtonLinkProps): JSX.Element => {
  const { styleOverrides } = useSelector((state: RootState) => state.settings);

  const newStyle = {
    color: styleOverrides.primaryButton.backgroundColor,
    backgroundColor: "transparent",
    padding: 0,
    border: "none",
    font: "inherit",
    lineHeight: "inherit",
    verticalAlign: "inherit",
    boxShadow: "none",
    ...style,
  };

  return (
    <Button
      type="button"
      style={newStyle}
      className={className}
      onClick={onClick}
      {...restProps}
    >
      <strong>{children}</strong>
    </Button>
  );
};

export default StyledButtonLink;
