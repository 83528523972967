import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { twMerge } from "tailwind-merge";
import { ReactComponent as ArrowDownIcon } from "../../assets/arrow-down.svg";
import { Button, IButtonProps, ButtonTheme } from "./Button";
import Text from "./Text";

interface ButtonDropdownProps {
  onClick?: (event: React.MouseEvent) => void;
  children: React.ReactNode;
  className?: string;
  options: IButtonProps[];
  theme?: ButtonTheme;
  buttonProps?: Partial<IButtonProps>;
}

export default function ButtonDropdown({
  children,
  className,
  options,
  theme,
}: ButtonDropdownProps) {
  return (
    <Menu
      as="div"
      className={twMerge("relative inline-block text-left", className)}
    >
      <div className="flex">
        <Menu.Button
          as={(props: any) => (
            <Button
              {...props}
              className="p-0 rounded-lg flex gap-2 text-sm"
              theme={theme}
            />
          )}
        >
          <span className="!px-3 flex gap-2 items-center">{children}</span>
          <span className="!border-l border-inherit pl-2 !pr-3 py-2">
            <ArrowDownIcon
              className="h-5 w-5 ui-open:rotate-180"
              aria-hidden="true"
            />
          </span>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute top-full right-0 z-[999] mt-2 w-max origin-top-right transform rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {options.map(({ className: optionClassName, ...option }, index) => (
              <Menu.Item key={index}>
                <button
                  {...option}
                  className={twMerge(
                    "block px-4 py-2 text-sm rounded-none w-full font-medium text-left text-ice-500 ui-active:bg-ice-50 ui-active:text-ice-700",
                    optionClassName
                  )}
                >
                  <Text type="body" as="span">
                    {option.children}
                  </Text>
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
