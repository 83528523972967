import { FullDataWithMeta, getOffsetTableMessages } from "./data_actions";
import { generateExportData } from "./result_data";
import { AppThunk } from "../store/configureStore";
import { selectRowsWithErrors } from "../store/reducers/coredata";
import { selectMappedSpecs } from "../store/selectors";
import { ITableMessageInternal, IDeveloperField } from "../interfaces";
import type XLSX from "@sheet/core";
import { getLabelsFromFields } from "../helpers/CoreDataHelpers";

// This will trigger a download of the excel file
export const downloadExport = (
  fullData: FullDataWithMeta,
  errorsOnly: boolean
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const { coredata, settings } = getState();

    const filename =
      coredata.originalFilename !== null
        ? `${coredata.originalFilename.split(".")[0]}_export`
        : `${settings.importIdentifier}_export`;

    const worksheet = errorsOnly
      ? await dispatch(generateErrorsOnlySheet(fullData))
      : await dispatch(generateFullExportSheet(fullData));

    await exportSheet(filename, worksheet);
  };
};

export const generateFullExportSheet = (
  fullData: FullDataWithMeta
): AppThunk<Promise<XLSX.WorkSheet>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tableMessages } = state.coredata;
    const exportData = dispatch(generateExportData(fullData));
    const columnMapping = selectMappedSpecs(state);
    return await createSheet(exportData, tableMessages, columnMapping);
  };
};

export const generateErrorsOnlySheet = (
  fullData: FullDataWithMeta
): AppThunk<Promise<XLSX.WorkSheet>> => {
  return async (dispatch, getState) => {
    const state = getState();
    const { tableMessages } = state.coredata;
    const columnMapping = selectMappedSpecs(state);
    const rowsWithErrors = selectRowsWithErrors(state.coredata);

    const errorTableMessages = getOffsetTableMessages(
      tableMessages,
      rowsWithErrors
    );

    const rowsWithErrorsData = fullData.filter((_row: any, index: number) =>
      rowsWithErrors.has(index)
    );

    const exportData = dispatch(generateExportData(rowsWithErrorsData));
    return await createSheet(exportData, errorTableMessages, columnMapping);
  };
};

export const aliceBlue = 0xf6f8fa;
export const palePink = 0xf8e0e2;
export const lightGoldenrodYellow = 0xfafad2;

export const createSheet = async (
  tableData: unknown[][],
  tableMessages: Map<string, ITableMessageInternal[]>,
  columnMapping: Map<number, IDeveloperField>
) => {
  const XLSX = await import("@sheet/core");
  const fullDataColToOutputDataCol: Map<number, number> = new Map();
  const sortedFields: IDeveloperField[] = [];

  [...columnMapping.entries()]
    .sort(([i1, _1], [i2, _2]) => i1 - i2)
    .forEach(([fullDataColIdx, field]) => {
      sortedFields.push(field);
      fullDataColToOutputDataCol.set(fullDataColIdx, sortedFields.length - 1);
    });

  const columnNames = getLabelsFromFields(sortedFields);
  const ws = XLSX.utils.aoa_to_sheet([columnNames, ...tableData]);

  // Style the header row
  for (let i = 0; i < columnNames.length; i++) {
    ws[XLSX.utils.encode_cell({ r: 0, c: i })].s = {
      fgColor: { rgb: aliceBlue },
      bold: true,
    };
  }
  // Style the sheet, embiggen rows and columns for comfort
  ws["!sheetFormat"] = {
    row: {
      hpx: 30,
    },
    col: {
      wpx: 180,
    },
  };

  // Add the messages as comments (aka notes)
  tableMessages.forEach((cellMessages, key) => {
    const [row, col] = key.split(",").map((n) => parseInt(n));
    const levels = new Set<string>();
    const comments: string[] = [];
    cellMessages.forEach((m) => {
      levels.add(m.level);
      comments.push(m.message);
    });
    // Style the cells with messages
    let color;
    if (levels.has("error")) {
      color = palePink;
    } else if (levels.has("warning")) {
      color = lightGoldenrodYellow;
    } else if (levels.has("info")) {
      color = aliceBlue;
    }

    const cellAddr = XLSX.utils.encode_cell({
      // we +1 because of the header row
      r: row + 1,
      c: fullDataColToOutputDataCol.get(col)!,
    });
    const cell = ws[cellAddr];
    cell.c = [{ a: "Dromo", t: comments.join("\n") }];
    cell.c.hidden = true;
    cell.s = { fgColor: { rgb: color } };
  });
  return ws;
};

const exportSheet = async (exportFilename: string, ws: XLSX.WorkSheet) => {
  const XLSX = await import("@sheet/core");
  const fname = `${exportFilename}.xlsx`;
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Rows");
  XLSX.writeFileXLSX(wb, fname, { cellStyles: true });
};
