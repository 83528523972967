import * as React from "react";
import { RootState } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";

interface IPrimaryTextProps {
  as: string;
}

const mapState = (state: RootState) => {
  return {
    styleOverrides: state.settings.styleOverrides,
  };
};

const reduxConnector = connect(mapState, {});
type IPrimaryTextFullProps = IPrimaryTextProps &
  React.HTMLAttributes<HTMLDivElement> &
  ConnectedProps<typeof reduxConnector>;

function PrimaryText(props: IPrimaryTextFullProps) {
  const elementProps = {
    style: { color: props.styleOverrides.global.primaryTextColor },
    className: props.className,
  };

  return React.createElement(props.as, elementProps, props.children);
}

PrimaryText.defaultProps = {
  as: "span",
};

export default reduxConnector(PrimaryText);
