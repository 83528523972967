import {
  EStepHook,
  IReviewStepData,
  IUploadStepData,
  IReviewStepPostHooksData,
  IBeforeFinishOutput,
  IResultMetadata,
} from "../interfaces";
import { AppThunk } from "../store/configureStore";
import { selectHeaderMapping, selectFieldMetadata } from "../helpers/Hooks";
import { selectErrorsForExport } from "../store/selectors";
import { selectRowsWithErrors } from "../store/reducers/coredata";
import { DEVELOPMENT_MODE_RESULT_ROW_LIMIT } from "../constants/constants";

export type HandleUploadHookFn = (
  step: EStepHook.UPLOAD_STEP,
  data: IUploadStepData
) => Promise<void>;

export type HandleReviewHookFn = (
  step: EStepHook.REVIEW_STEP,
  data: IReviewStepData
) => Promise<void>;

export type HandleReviewPostHooksHookFn = (
  step: EStepHook.REVIEW_STEP_POST_HOOKS,
  data: IReviewStepPostHooksData
) => Promise<void>;

export type HandleBeforeFinishCallbackFn = (
  data: Record<string, unknown>[],
  metadata: IResultMetadata
) => Promise<IBeforeFinishOutput>;

export const runUploadStepHook = (
  handleFn: HandleUploadHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const { originalFilename, data } = getState().coredata;

    await handleFn(EStepHook.UPLOAD_STEP, {
      filename: originalFilename,
      dataPreview: data.previewData,
    });
  };
};

export const runReviewStepHook = (
  handleFn: HandleReviewHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();
    const { headers } = state.coredata;

    await handleFn(EStepHook.REVIEW_STEP, {
      fields: selectFieldMetadata(state),
      rawHeaders: headers,
      headerMapping: selectHeaderMapping(state),
    });
  };
};

export const runReviewStepPostHooksHook = (
  handleFn: HandleReviewPostHooksHookFn
): AppThunk<Promise<void>> => {
  return async (_dispatch, getState) => {
    const state = getState();

    await handleFn(EStepHook.REVIEW_STEP_POST_HOOKS, {
      fields: selectFieldMetadata(state),
      headerMapping: selectHeaderMapping(state),
    });
  };
};

export const runBeforeFinishCallback = (
  data: Record<string, unknown>[],
  handleFn: HandleBeforeFinishCallbackFn
): AppThunk<Promise<IBeforeFinishOutput>> => {
  return async (_dispatch, getState) => {
    const state = getState();
    const { settings, coredata } = state;

    const trimmedData =
      settings.importerMode === "PRODUCTION"
        ? data
        : data.slice(0, DEVELOPMENT_MODE_RESULT_ROW_LIMIT);

    const errorRows = selectRowsWithErrors(coredata);
    const errorRowsArray = Array.from(errorRows).sort((a, b) => a - b);

    const metadata: IResultMetadata = {
      id: null,
      filename: coredata.originalFilename,
      importIdentifier: settings.importIdentifier,
      user: settings.user,
      rowsWithError: errorRowsArray,
      rawHeaders: coredata.headers,
      fields: selectFieldMetadata(state),
      errors: selectErrorsForExport(state),
    };

    return await handleFn(trimmedData, metadata);
  };
};
