import { AppThunk } from "../store/configureStore";
import {
  IDeveloperField,
  ISelectField,
  IDeveloperSettings,
  IDeveloperSettingsStrict,
  IUser,
} from "../interfaces";
import { DeveloperSettingsSchema } from "../helpers/schemas/settings";
import { DeveloperFieldsSchema } from "../helpers/schemas/fields";
import { UserSchema } from "../helpers/schemas/user";
import { setFromDeveloperSettings, setUser } from "../store/reducers/settings";
import { addError } from "../store/reducers/errors";
import { fromZodError } from "zod-validation-error";
import { initializeFromDeveloperFields } from "../store/reducers/fields";
import { setOriginalNumRows } from "../store/reducers/coredata";

export const initializeFields = (
  developerFields: IDeveloperField[]
): AppThunk<IDeveloperField[] | null> => {
  return (dispatch) => {
    const parseResult = DeveloperFieldsSchema.safeParse(developerFields);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_FIELDS",
          message: fromZodError(parseResult.error).message,
        })
      );
      return null;
    }

    validateSelectFields(parseResult.data);

    dispatch(initializeFromDeveloperFields(parseResult.data));
    return parseResult.data;
  };
};

export const initializeSettings = (
  settings: IDeveloperSettings
): AppThunk<IDeveloperSettingsStrict | null> => {
  return (dispatch) => {
    const parseResult = DeveloperSettingsSchema.safeParse(settings);

    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_SETTINGS",
          message: fromZodError(parseResult.error, {
            prefix: "Invalid Dromo settings",
          }).message,
        })
      );

      return null;
    }

    dispatch(setFromDeveloperSettings(parseResult.data));

    return parseResult.data;
  };
};

export const validateAndSetUser = (user: IUser): AppThunk<IUser | null> => {
  return (dispatch) => {
    const parseResult = UserSchema.safeParse(user);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_USER",
          message: fromZodError(parseResult.error, {
            prefix: "Invalid Dromo user",
          }).message,
        })
      );

      return null;
    }

    dispatch(setUser(parseResult.data));
    return parseResult.data;
  };
};

const validateSelectFields = (fields: IDeveloperField[]): void => {
  const selectFields = fields.filter(
    ({ type }) => type === "select"
  ) as ISelectField[];

  for (const selectField of selectFields) {
    const seenLabels = new Set<string>();
    const dupLabels = [];

    for (const { label } of selectField.selectOptions) {
      if (seenLabels.has(label)) dupLabels.push(label);
      seenLabels.add(label);
    }

    if (dupLabels.length > 0) {
      console.error(
        `[Dromo-External-Error] Warning: Select field '${
          selectField.key
        }' has select options with duplicate labels: ${dupLabels.join(", ")}.` +
          "\nHaving multiple select options with the same label results in undefined behavior."
      );
    }
  }
};

export const handleNumOfRows = <T extends any[]>(data: T): AppThunk<T> => {
  return (dispatch, getState) => {
    dispatch(setOriginalNumRows(data.length));
    const { maxRecords } = getState().settings;

    if (maxRecords === null) return data;

    const trimmedData = [...data];

    if (data.length > maxRecords) {
      trimmedData.splice(maxRecords);
    }

    return trimmedData as unknown as T;
  };
};
