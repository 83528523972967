import React from "react";
import { useTranslation } from "react-i18next";

import { EFileUploadStage } from "../../constants/EFileUploadStage";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import Modal from "../commonComponents/Modal";
import { EnhanceDataCard } from "./EnhanceDataCard";
import { Card } from "../commonComponents/Card";
import { setModalStage } from "../../thunks/modal_stage";
import { getLabelsFromFields } from "../../helpers/CoreDataHelpers";
import { selectMappedSelectSpecs } from "../../store/selectors";

export function truncate(str: string, n: number) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}

export function EnhanceData() {
  const dispatch = useAppDispatch();
  const { valCountsInColumn } = useAppSelector((state) => ({
    valCountsInColumn: state.coredata.data.valCountsInColumn,
  }));
  const mappedSelectFields = useAppSelector(selectMappedSelectSpecs);
  const selectFields = [...mappedSelectFields].map(([index, field]) => ({
    index,
    field,
  }));

  const { t } = useTranslation();

  const getValues = (columnIndex: number): string[] => {
    return Array.from(valCountsInColumn?.get(columnIndex)?.keys() || []).filter(
      (val) => val.trim() !== ""
    );
  };

  const handleGoBack = () => {
    dispatch(setModalStage(EFileUploadStage.COLUMN_MATCH));
  };

  const handleNextStep = () => {
    dispatch(setModalStage(EFileUploadStage.DATA_REVIEW));
  };

  const labels = getLabelsFromFields(selectFields.map((sf) => sf.field));

  return (
    <>
      <Modal data-cy="CleanDataModal" onBack={handleGoBack} step="matchValues">
        <Text type="h1" className="mb-2">
          {t("modalHeader.matchValues")}
        </Text>
        <Text type="body">{t("dataCleaningModal.subtitle")}</Text>
        <Card className="my-4 mt-6">
          <div className="bg-ice-50 !border !border-ice-300 divide-y divide-ice-300">
            {selectFields.map((column, idx) => {
              return (
                <EnhanceDataCard
                  key={column.field?.key}
                  name={labels[idx]}
                  options={column.field.selectOptions}
                  values={getValues(column.index)}
                  columnIndex={column.index}
                />
              );
            })}
          </div>
        </Card>
        <div className="flex justify-end items-center gap-2 w-full mt-6">
          <Button
            onClick={handleNextStep}
            data-cy="auto-clean-continue-button"
            autoFocus
          >
            {t("dataCleaningModal.continue")}
          </Button>
        </div>
      </Modal>
    </>
  );
}
