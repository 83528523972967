import * as React from "react";
import { RootState } from "../store/reducers";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DEVELOPMENT_MODE_RESULT_ROW_LIMIT } from "../constants/constants";

export const NotProductionBanner = (): React.ReactElement => {
  const { t } = useTranslation();
  const importerMode = useSelector(
    (state: RootState) => state.settings.importerMode
  );
  if (importerMode === "PRODUCTION" || importerMode === "INIT") return <></>;

  return (
    <div
      style={{
        color: "white",
        textAlign: "center",
        background: "#007bff",
        padding: "15px 35px 15px 10px",
        width: "100%",
        borderTopLeftRadius: "0.2rem",
        borderTopRightRadius: "0.2rem",
        borderBottomLeftRadius: "0.25rem",
        borderBottomRightRadius: "0.25rem",
        boxShadow: "0px 2px 2px grey",
      }}
    >
      {t(
        importerMode === "DEMO"
          ? "banner.demoModeText"
          : "banner.developmentModeText",
        {
          rowLimit: DEVELOPMENT_MODE_RESULT_ROW_LIMIT,
        }
      )}
    </div>
  );
};
