import * as Sentry from "@sentry/browser";
import {
  IDeveloperField,
  IDeveloperSettings,
  IUser,
  IPositionSpec,
  IMessagesForCell,
  ITableMessage,
  IRowToAdd,
  IImporterOptions,
} from "../interfaces";
import {
  setNumRegisteredColHooks,
  setNumRegisteredRowHooks,
  setNumRegisteredRowDeleteHooks,
  setHeadlessImportId,
  enqueueAddRows,
  enqueueRemoveRows,
  setInitialized,
} from "../store/reducers/coredata";
import { selectKeyToIndexMap } from "../store/selectors";
import { registerVirtualField, removeField } from "../store/reducers/fields";

import {
  setUser,
  setHeaderRowOverride,
  setImporterMode,
  setLicenseKey,
  setAppHost,
  setSavedSchema,
  setOriginalSettings,
} from "../store/reducers/settings";
import {
  initializeFields,
  initializeSettings,
  validateAndSetUser,
} from "./initialization";
import { validateLicenseKeyAndHost } from "./license_key_validation";
import { setInitialData } from "./initial_data";
import { AppThunk } from "../store/configureStore";
import { DeveloperFieldSchema } from "../helpers/schemas/fields";
import {
  CellMessagesSchema,
  PositionSpecSchema,
  RowsToAddSchema,
  RowsToRemoveSchema,
} from "../helpers/schemas/hooks";
import { fromZodError } from "zod-validation-error";
import { updateUserMessagesForCells } from "./data_actions";
import { addError } from "../store/reducers/errors";
import { IRehydrateState, rehydrate as rehydrateAction } from "./rehydration";
import { initLocalization } from "../helpers/i18n";
import APIClient from "../helpers/APIClient";

export const init = (
  key: string,
  fields: IDeveloperField[],
  settings: IDeveloperSettings,
  user: IUser,
  appHost?: string
): AppThunk => {
  return (dispatch) => {
    dispatch(setLicenseKey(key));
    if (appHost) dispatch(setAppHost(appHost));

    const strictSettings = dispatch(initializeSettings(settings));
    if (!strictSettings) return;

    dispatch(validateLicenseKeyAndHost());

    dispatch(validateAndSetUser(user));

    if (process.env.NODE_ENV === "production") {
      Sentry.setUser({ id: key });
    }

    dispatch(initializeFields(fields));

    initLocalization(strictSettings.locale);

    // If initial data is set, then transition to DataReviewModal
    dispatch(setInitialData(strictSettings.initialData));

    dispatch(setOriginalSettings(settings));

    dispatch(setInitialized());

    // If we're going to use SheetJS, load that chunk now so we don't have
    // to do it on-the-fly once a file is uploaded
    if (strictSettings.browserExcelParsing) {
      import("@sheet/core");
    }
  };
};

export const initFromSavedSchema = (
  key: string,
  schemaName: string,
  appHost: string,
  options?: IImporterOptions
): AppThunk => {
  return async (dispatch) => {
    dispatch(setLicenseKey(key));
    const api = new APIClient(key);
    const savedSchema = await api.getSavedSchema(schemaName);
    const user: IUser = options?.user ?? { id: "" };

    if (!savedSchema) {
      dispatch(
        addError({
          type: "developer",
          code: "E_SAVED_SCHEMA_NOT_FOUND",
          message: `Schema "${schemaName}" not found`,
        })
      );
      return;
    }

    const settings: IDeveloperSettings = {
      importIdentifier: savedSchema.name,
      ...savedSchema.settings,
      developmentMode: options?.developmentMode,
    };

    if (options?.headerRowOverride !== undefined) {
      settings.matchingStep = {
        ...settings.matchingStep,
        headerRowOverride: options.headerRowOverride,
      };
    }

    dispatch(setSavedSchema(savedSchema));
    dispatch(init(key, savedSchema.fields, settings, user, appHost));
  };
};

export const updateInfoMessages = (
  cellMessages: IMessagesForCell[]
): AppThunk => {
  return (dispatch, getState) => {
    const parseResult = CellMessagesSchema.safeParse(cellMessages);
    if (!parseResult.success) {
      const errorMsg = fromZodError(parseResult.error);
      console.error(
        `Received invalid argument to updateInfoMessages: ${errorMsg}`
      );
      return;
    }

    const state = getState();
    const keyToIndexMap = selectKeyToIndexMap(state);
    const newMessages = new Map<string, ITableMessage[]>();

    parseResult.data.forEach((messageObj) => {
      const indexEntry = keyToIndexMap.get(messageObj.fieldKey);
      if (!indexEntry) return;

      let colIndex: number;
      if (messageObj.manyToOneIndex !== undefined && indexEntry.manyToOne) {
        colIndex = indexEntry.indexes[messageObj.manyToOneIndex];
      } else if (!indexEntry.manyToOne) {
        colIndex = indexEntry.index;
      } else {
        return;
      }
      const cellRef = `${messageObj.rowIndex},${colIndex}`;
      const oldMessages = newMessages.get(cellRef) ?? [];
      newMessages.set(cellRef, oldMessages.concat(messageObj.messages));
    });

    dispatch(updateUserMessagesForCells(newMessages));
  };
};

export const addField = (
  field: IDeveloperField,
  position: IPositionSpec | undefined
): AppThunk => {
  return (dispatch) => {
    const parseResult = DeveloperFieldSchema.safeParse(field);
    if (!parseResult.success) {
      dispatch(
        addError({
          type: "developer",
          code: "E_INVALID_FIELDS",
          message: fromZodError(parseResult.error).message,
        })
      );
      return;
    }

    const positionParseResult = PositionSpecSchema.safeParse(position);
    let positionSpec: IPositionSpec | undefined;
    if (!positionParseResult.success) {
      const errorMsg = fromZodError(positionParseResult.error);
      console.error(
        `[Dromo-External-Error] Received invalid position argument in call to addField: ${errorMsg}`
      );
      positionSpec = undefined;
    } else {
      positionSpec = positionParseResult.data;
    }

    dispatch(registerVirtualField(parseResult.data, positionSpec));
  };
};

export const setDevelopmentMode = (
  developmentMode: boolean | undefined
): AppThunk => {
  return (dispatch, getState) => {
    const { settings } = getState();
    // If we're in DEMO mode, we initialized with developmentMode true and license
    // check determined we weren't subscribed, so any other mode is illegal
    if (settings.importerMode === "DEMO") {
      dispatch(
        addError({
          type: "developer",
          code: "E_SUBSCRIPTION_ERROR",
          messageKey: "alert.subscriptionError",
        })
      );
    } else {
      dispatch(setImporterMode(developmentMode ? "DEVELOPMENT" : "PRODUCTION"));
    }
  };
};

export const rehydrate = (
  rehydrateState: IRehydrateState,
  headlessImportId?: string
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (headlessImportId) {
      dispatch(setHeadlessImportId(headlessImportId));
    }

    await dispatch(rehydrateAction(rehydrateState));
  };
};

export const addRows = (rows: IRowToAdd[]): AppThunk => {
  return (dispatch) => {
    const parseResult = RowsToAddSchema.safeParse(rows);
    if (!parseResult.success) {
      const errorMsg = fromZodError(parseResult.error);
      console.error(
        `[Dromo-External-Error] Received invalid argument to addRows: ${errorMsg}`
      );
      return;
    }

    dispatch(enqueueAddRows(parseResult.data));
  };
};

export const removeRows = (rowIds: string[]): AppThunk => {
  return (dispatch) => {
    const parseResult = RowsToRemoveSchema.safeParse(rowIds);
    if (!parseResult.success) {
      const errorMsg = fromZodError(parseResult.error);
      console.error(
        `[Dromo-External-Error] Received invalid argument to removeRows: ${errorMsg}`
      );
      return;
    }

    dispatch(enqueueRemoveRows(parseResult.data));
  };
};

export default {
  init,
  initFromSavedSchema,
  addField,
  setHeaderRowOverride,
  setNumRegisteredColHooks,
  setNumRegisteredRowHooks,
  setNumRegisteredRowDeleteHooks,
  setUser,
  updateInfoMessages,
  removeField,
  setDevelopmentMode,
  rehydrate,
  addRows,
  removeRows,
};
