import React from "react";
import Modal from "react-bootstrap/Modal";
import { FileWithPath } from "react-dropzone";

import { ErrorModalRedesign } from "../AlertModal";
import { FileSelect } from "../FileSelect";
import ManualInputTableWrapper from "../ManualInputTableWrapper";
import ModalHeader from "../ModalHeader";
import { NotProductionBanner } from "../NotProductionBanner";
import { SelectSheetModal } from "../SelectSheetModal";
import HelpText from "../styledComponents/HelpText";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { handleCancelModal } from "../../thunks/parent_connection_handlers";
import { useAppDispatch } from "../../store/hooks";

import { UIComponentProps } from ".";

export function DataUpload({
  showErrorModal,
  setShowErrorModal,
  showSelectSheetModal,
  textColor,
  manualInputDisabled,
  manualInputOnly,
  templateDownloadFilename,
  stepSettings,
  onTemplateDownloadClick,
  onSelectSheet,
  processSelectedLocalFile,
}: UIComponentProps) {
  const dispatch = useAppDispatch();
  const connection = useParentConnectionContext();

  return (
    <>
      <ErrorModalRedesign
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />

      <SelectSheetModal
        show={showSelectSheetModal}
        onHide={() => onSelectSheet()}
      />

      <Modal
        animation={false}
        show
        backdrop={false}
        keyboard={false}
        dialogClassName="fullScreenModal"
        style={{ color: textColor }}
        onHide={() => dispatch(handleCancelModal(connection))}
        data-cy="DataUploadModal"
      >
        <NotProductionBanner />
        <ModalHeader stepperCompletedIdx={0} />
        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}
        {!manualInputOnly && (
          <FileSelect
            onFileSelected={(file: FileWithPath) => {
              processSelectedLocalFile(file);
            }}
            shouldShowTemplateDownloadLink={templateDownloadFilename !== null}
            onTemplateDownloadClick={onTemplateDownloadClick}
          />
        )}
        {!manualInputDisabled && (
          <ManualInputTableWrapper manualInputOnly={manualInputOnly} />
        )}
        <Modal.Footer>{/* Powered by Dromo link and logo */}</Modal.Footer>
      </Modal>
    </>
  );
}
