import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { AlertModal } from "../AlertModal";
import ModalHeader from "../ModalHeader";
import { NotProductionBanner } from "../NotProductionBanner";
import HelpText from "../styledComponents/HelpText";
import StyledPrimaryButton from "../styledComponents/StyledPrimaryButton";
import StyledSecondaryButton from "../styledComponents/StyledSecondaryButton";
import SecondaryText from "../styledComponents/SecondaryText";

import { UIColumnMatchProps } from ".";

export function ColumnMatchLegacy({
  alertModal,
  blockNextButton,
  alertModalMessage,
  stepSettings,
  alertModalShowSecondaryButton,
  globalStyle,
  cards,
  showSpinner,
  onGoBack,
  onNextClick,
  onShowAlert,
  onAlertPrimaryButtonClick,
  onAlertSecondaryButtonClick,
  onHideModal,
}: UIColumnMatchProps) {
  const { t } = useTranslation();

  return (
    <>
      <AlertModal
        show={alertModal.show}
        setShow={onShowAlert}
        message={alertModalMessage}
        primaryButtonText={blockNextButton ? t("common.ok") : t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={onAlertPrimaryButtonClick}
        onSecondaryButtonClick={onAlertSecondaryButtonClick}
        showSecondaryButton={alertModalShowSecondaryButton}
      />
      <Modal
        animation={false}
        show
        backdrop={false}
        keyboard={false}
        dialogClassName="fullScreenModal"
        style={globalStyle}
        onHide={onHideModal}
        data-cy="ColumnMatchModal"
      >
        <NotProductionBanner />
        <ModalHeader stepperCompletedIdx={2} />
        {stepSettings.helpText && <HelpText content={stepSettings.helpText} />}
        <Modal.Body>
          {showSpinner ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">{t("common.loading")}</span>
              </Spinner>
              <SecondaryText as="div" style={{ marginTop: "1rem" }}>
                {t("columnMatchModal.aiMatchesLoading", "common.loading")}
              </SecondaryText>
            </div>
          ) : null}
          {cards}
        </Modal.Body>
        <Modal.Footer className="modalFooter">
          <StyledSecondaryButton
            className="modalFooterSecondaryButton"
            onClick={onGoBack}
          >
            {t("common.goBack")}
          </StyledSecondaryButton>
          <StyledPrimaryButton
            className="modalFooterPrimaryButton"
            onClick={onNextClick}
          >
            {t("common.next")}
          </StyledPrimaryButton>
        </Modal.Footer>
      </Modal>
    </>
  );
}
