import { batch } from "react-redux";
import {
  setCellTransformErrors,
  setTableMessages,
  selectRawRowWidth,
  clearAddRowQueue,
  clearRemoveRowQueue,
  updateSelectOptionOverrides,
  setSelectOptionOverrides,
  SelectOptionOverrides,
} from "../store/reducers/coredata";
import { runValidators } from "../helpers/Validators";
import { isArray } from "lodash";
import i18next from "i18next";

import { AppThunk } from "../store/configureStore";
import { RootState } from "../store/reducers";
import {
  IRowToAdd,
  IColumnHookInput,
  IColumnHookOutput,
  IDeveloperSelectOption,
  IRowHookInput,
  IRowHookOutputInternal,
  IRowMeta,
  ISelectField,
  ITableMessage,
  ITableMessageInternal,
  ITableMessages,
  INewTableMessages,
  IRowCell,
  IRowHookCell,
  IRowCellBasic,
  IRowCellManyToOne,
  IDeveloperField,
} from "../interfaces";
import { IAbstractField } from "../fields";
import { transpose } from "../helpers/TableHelpers";
import { SelectField } from "../fields/select";
import { v4 as uuidv4 } from "uuid";
import { escapeRegExp } from "../util/regex";
import {
  selectMappedFieldInstances,
  selectMappedSpecs,
  selectKeyToIndexMap,
  ManyToOneIndexEntry,
  OneToOneIndexEntry,
} from "../store/selectors";
import { TransformDataSuccess } from "./user_functions";

export type ParsedData = unknown[][];
export type DataWithMetaRow = [...unknown[], IRowMeta];
export type FullDataWithMeta = DataWithMetaRow[];
export type HotChange = [
  rowIndex: number,
  colIndex: number,
  oldValue: unknown,
  newValue: string | null
];
export type SelectOptionOverride = [
  rowIndex: number,
  fieldKey: string,
  selectOptions: IDeveloperSelectOption[]
];
export type DataThunk = AppThunk<FullDataWithMeta>;
export type ParsedDataThunk = AppThunk<ParsedData>;
export type AsyncDataThunk = AppThunk<Promise<FullDataWithMeta>>;
export type RunColumnHooksFn = (
  fieldName: string,
  data: IColumnHookInput[]
) => Promise<IColumnHookOutput[]>;
export type RunRowHooksFn = (
  data: IRowHookInput[],
  mode: "init" | "update"
) => Promise<IRowHookOutputInternal[]>;
export type RunRowDeleteHooksFn = (data: IRowHookInput[]) => Promise<void>;

enum CoreDataRowAction {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

/**
 * Does all of the operations to prepare the state and data at the beginning
 * of the review step. Namely:
 * - Transform the select field values based on the mapping from the mapping step
 * - Runs all field transforms
 * - Runs column and row hooks
 * - Runs validations
 * Returns the full data ready for the table.
 */
export const initializeForReview = (
  fullData: FullDataWithMeta,
  runClientColumnHooks: RunColumnHooksFn,
  runClientRowHooks: RunRowHooksFn
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    let newFullData: FullDataWithMeta = [];

    newFullData = dispatch(transformSelectFieldValues(fullData));
    newFullData = dispatch(runFieldTransforms(newFullData));

    if (state.coredata.numRegisteredColHooks > 0) {
      newFullData = await dispatch(
        runColumnHooks(newFullData, runClientColumnHooks)
      );
    }

    if (state.coredata.numRegisteredRowHooks > 0) {
      newFullData = await dispatch(
        runRowHooks(newFullData, runClientRowHooks, "init")
      );
    } else {
      // if we ran row hooks, that will run validations after.
      // otherwise we need to explicitly do it here.
      dispatch(runValidations(newFullData));
    }

    return newFullData;
  };
};

/**
 * Updates the data and state for user-initiated changes.
 * Accepts the full data, as well as an array of changes in the format
 * [rowIndex, colIndex, oldValue, newValue].
 * Note that this does *not* run row hooks.
 * Returns the new data with changes applied.
 */
export const changeCells = (
  fullData: FullDataWithMeta,
  changes: HotChange[]
): DataThunk => {
  return (dispatch) => {
    return dispatch(processChanges(fullData, changes));
  };
};

/**
 * Runs the row hooks over the given data with the given mode.
 * If changes is supplied, the row hooks will only be run on the rows
 * that had changes. Otherwise, they will run for every row.
 * Returns the new data with any changes from the hooks applied.
 */
export const runRowHooks = (
  fullData: FullDataWithMeta,
  runClientRowHooks: RunRowHooksFn,
  mode: "init" | "update",
  changes?: HotChange[]
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    const state = getState();

    if (state.coredata.numRegisteredRowHooks === 0) return fullData;
    if (state.settings.backendCapabilities.saved_schema_only) return fullData;

    const changedRowIndexes = changes
      ? new Set(changes.map((c) => c[0]))
      : null;

    const rowHookInput = buildRowHookInputRows(
      state,
      fullData,
      changedRowIndexes
    );

    const rowHookOutput = await runClientRowHooks(rowHookInput, mode);
    const [rowHookChanges, userMessages, selectOptionOverrides] =
      getChangesAndMessagesFromHookOutput(state, fullData, rowHookOutput);

    dispatch(updateSelectOptionOverrides(selectOptionOverrides));
    dispatch(updateUserMessages(userMessages));
    return dispatch(processChanges(fullData, rowHookChanges));
  };
};

/**
 * Adds new empty rows at the specified row indexes.
 */
export const addEmptyRows = (
  fullData: FullDataWithMeta,
  addedRows: number[]
): DataThunk => {
  return addOrRemoveConsecutiveRows(fullData, addedRows, CoreDataRowAction.ADD);
};

/**
 * Removes the rows with the specified indexes, reruns validations,
 * and runs the row delete hooks.
 */
export const removeConsecutiveRows = (
  fullData: FullDataWithMeta,
  removedRows: number[],
  runDeleteHooks?: RunRowDeleteHooksFn
): DataThunk => {
  return (dispatch, getState) => {
    const state = getState();
    if (runDeleteHooks) {
      const hookInput = buildRowHookInputRows(state, fullData, removedRows);
      runDeleteHooks(hookInput);
    }
    const newFullData = dispatch(
      addOrRemoveConsecutiveRows(
        fullData,
        removedRows,
        CoreDataRowAction.REMOVE
      )
    );

    dispatch(runValidations(newFullData));

    return newFullData;
  };
};

/**
 * Runs all changes made by the transform data AI function
 */
export const runTransformDataChanges = (
  fullData: FullDataWithMeta,
  transformResult: TransformDataSuccess,
  runRowHooks: RunRowHooksFn,
  runRowDeleteHooks: RunRowDeleteHooksFn
): AppThunk<Promise<FullDataWithMeta>> => {
  return async (dispatch) => {
    let newFullData = await dispatch(
      processChangesAndRunRowHooks(
        fullData,
        transformResult.changes,
        runRowHooks
      )
    );

    for (const removedRowIdx of [...transformResult.removedRowIndexes].sort(
      (a, b) => b - a
    )) {
      newFullData = dispatch(
        removeConsecutiveRows(newFullData, [removedRowIdx], runRowDeleteHooks)
      );
    }

    return newFullData;
  };
};

/**
 * Updates the user messages in bulk.
 * Messages are supplied as a map where the keys are row indexes and
 * the values are records of field keys pointing to arrays of table
 * messages. For each cell given, the supplied messages will replace
 * any existing user-generated messages. Cells not specified will be
 * left unmodified.
 */
export const updateUserMessagesForCells = (
  newMessages: Map<string, ITableMessage[]>
): AppThunk => {
  return (dispatch, getState) => {
    const columnMapping = getState().fields.columnMapping;
    const newUserMessages: ITableMessages = new Map();

    newMessages.forEach((cellMessages, cellRef) => {
      const cellColIndex = Number(cellRef.split(",")[1]);

      if (columnMapping.has(cellColIndex) && isArray(cellMessages)) {
        const newMessages: ITableMessageInternal[] = cellMessages.map(
          (message) => {
            return {
              ...message,
              type: "user-generated",
            };
          }
        );
        newUserMessages.set(cellRef, newMessages);
      }
    });

    dispatch(updateUserMessages(newUserMessages));
  };
};

/**
 * Does all necessary modifications to apply the given changes to the data.
 * Returns the new, modified data.
 */
export const processChanges = (
  fullData: FullDataWithMeta,
  changes: HotChange[]
): DataThunk => {
  return (dispatch, getState) => {
    const state = getState();
    const newTableMessages = selectNewTableMessages(state);
    const newTransformErrorCells = selectNewTransformErrorCells(state);
    const mappedFieldInstances = selectMappedFieldInstances(state);
    const newFullData = cloneFullData(fullData);

    changes.forEach(([row, col, _oldVal, newVal]) => {
      const key = `${row},${col}`;

      newTransformErrorCells.delete(key);

      if (newFullData[row] === undefined)
        newFullData[row] = buildEmptyRow(state);

      const field = mappedFieldInstances.get(col);
      if (!field) return;

      if (typeof newVal === "string") {
        newVal = newVal.trim();
      }

      const transformResult = field.transformChecked(newVal, row);

      // remove existing transform messages
      filterTableMessagesForKey(
        newTableMessages,
        key,
        (msg) => msg.type !== "field-transform"
      );

      if (transformResult.empty) {
        newFullData[row][col] = "";
      } else if (transformResult.success) {
        newFullData[row][col] = transformResult.value;
      } else {
        newFullData[row][col] = newVal;
        addTableMessage(newTableMessages, key, transformResult.message);
        newTransformErrorCells.add(key);
      }
    });

    batch(() => {
      dispatch(setCellTransformErrors(newTransformErrorCells));
      dispatch(setTableMessages(newTableMessages));
      dispatch(runValidations(newFullData));
    });

    return newFullData;
  };
};

/**
 * Uses the select field mapping from the column match step to transform
 * values in select fields to their canonical select option labels
 */
const transformSelectFieldValues = (fullData: FullDataWithMeta): DataThunk => {
  return (_dispatch, getState): FullDataWithMeta => {
    const transposedData = transpose(fullData);
    const state = getState();
    const { data } = state.coredata;
    const mappedSpecs = selectMappedSpecs(state);
    const { selectFieldMapping } = state.fields;

    const selectFields = [...mappedSpecs.entries()].filter(
      ([_, field]) => field.type === "select"
    ) as [number, ISelectField][];

    for (const [colIndex, fieldSpec] of selectFields) {
      // If we don't have any data for this column, then just pass.
      // This happens if the user is doing manual entry.
      if (transposedData[colIndex] === undefined) continue;

      // If we have too many unique values, we don't allow custom mapping
      // In that case just make a good effort with labels/values/alt matches
      if (data.valCountsInColumn && data.valCountsInColumn.has(colIndex)) {
        transposedData[colIndex] = transposedData[colIndex].map((value) => {
          const selectMapping = selectFieldMapping.get(colIndex);
          // if we have a select mapping, check if the value is in the mapping
          const selectOption = selectMapping?.get(value as string);
          return selectOption?.label?.trim() ?? value;
        });
      } else {
        // a map of unique values to keys
        const selectOptions = fieldSpec.selectOptions.map(
          SelectField.normalizeOption
        );
        // a map of the field's option keys to option labels
        const optionKeyMap = new Map<string, string>();
        for (const { label, value, alternateMatches } of selectOptions) {
          [value, ...(alternateMatches ?? [])].forEach((match) =>
            optionKeyMap.set(match, label)
          );
        }

        transposedData[colIndex] = transposedData[colIndex].map((value) => {
          if (optionKeyMap.has(value as string)) {
            return optionKeyMap.get(value as string)!;
          }
          return value;
        });
      }
    }

    return transpose(transposedData) as FullDataWithMeta;
  };
};

/**
 * Runs field transforms on the complete dataset>
 * Updates table messages and transformErrorCells and returns
 * the transformed data.
 */
export const runFieldTransforms = (fullData: FullDataWithMeta): DataThunk => {
  return (dispatch, getState): FullDataWithMeta => {
    const transposedData = transpose(fullData);
    const state = getState();
    const fieldInstances = selectMappedFieldInstances(state);
    const tableMessages = selectNewTableMessages(state);
    const transformErrorCells = selectNewTransformErrorCells(state);

    const newTransposedData = transposedData.map((colData, colIdx) => {
      const field = fieldInstances.get(colIdx);

      if (!field) return colData;

      return colData.map((rawValue, rowIdx) => {
        const transformResult = field.transformChecked(
          rawValue as string,
          rowIdx
        );

        if (transformResult.empty) return "";

        if (transformResult.success) {
          return transformResult.value;
        } else {
          const key = `${rowIdx},${colIdx}`;
          addTableMessage(tableMessages, key, transformResult.message);
          transformErrorCells.add(key);
          return rawValue;
        }
      });
    });

    dispatch(setTableMessages(tableMessages));
    dispatch(setCellTransformErrors(transformErrorCells));

    return transpose(newTransposedData) as FullDataWithMeta;
  };
};

const runColumnHooks = (
  fullData: FullDataWithMeta,
  runClientColumnHooks: RunColumnHooksFn
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    const state = getState();
    if (state.settings.backendCapabilities.saved_schema_only) return fullData;

    const transposedData = transpose(fullData);
    const tableMessages = selectNewTableMessages(state);
    const columnMapping = selectMappedSpecs(state);
    const changes: HotChange[] = [];

    for (const [colIndex, field] of columnMapping) {
      const colHookInputData: IColumnHookInput[] = (
        transposedData[colIndex] ?? []
      ).map((value, index) => ({
        value: value ?? "",
        index,
        rowId: (getRowMeta(fullData[index]) ?? {}).rowId,
      }));

      const colHookOutputData = await runClientColumnHooks(
        field.key,
        colHookInputData
      );

      colHookOutputData.forEach((hookOutput) => {
        const rowIndex = hookOutput.index;

        if (hookOutput.info && hookOutput.info.length > 0) {
          const key = `${rowIndex},${colIndex}`;
          const messages: ITableMessageInternal[] = hookOutput.info.map(
            (info) => ({ ...info, type: "user-generated" })
          );

          addTableMessages(tableMessages, key, messages);
        }

        if (hookOutput.value !== undefined) {
          changes.push([
            rowIndex,
            colIndex,
            fullData[rowIndex][colIndex],
            hookOutput.value,
          ]);
        }
      });
    }

    dispatch(setTableMessages(tableMessages));
    return dispatch(processChanges(fullData, changes));
  };
};

export const runValidations = (fullData: FullDataWithMeta): AppThunk => {
  return (dispatch, getState) => {
    const state = getState();

    const newMessages = filterTableMessages(
      state.coredata.tableMessages,
      (msg) => msg.type !== "validation"
    );

    // run the validations, giving us all new validation messages
    const newValidatorTableMessages = runValidators(
      fullData,
      selectMappedSpecs(state),
      selectMappedFieldInstances(state),
      state.coredata.transformErrorCells
    );

    // add the validation messages to the user messages
    newValidatorTableMessages.forEach(
      (validatorTableMessage: ITableMessageInternal[], key: string) => {
        const cellMessages = newMessages.get(key) || [];
        newMessages.set(key, cellMessages.concat(validatorTableMessage));
      }
    );

    // add maxRecord warnings
    const maxRecords = state.settings.maxRecords;
    if (maxRecords !== null) {
      const numRows = fullData.length;
      const tableMessage: ITableMessageInternal = {
        type: "validation",
        level: "error",
        message: i18next.t("validations.maxRecordsExceeded", { maxRecords }),
      };

      for (const colIdx of state.fields.columnMapping.keys()) {
        for (let rowIdx = maxRecords; rowIdx < numRows; rowIdx++) {
          const key = `${rowIdx},${colIdx}`;
          addTableMessage(newMessages, key, tableMessage);
        }
      }
    }

    dispatch(setTableMessages(newMessages));
  };
};

const addOrRemoveConsecutiveRows = (
  fullData: FullDataWithMeta,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): DataThunk => {
  return (dispatch, getState) => {
    if (affectedRowIndices.length === 0) return fullData;
    const state = getState();
    const newFullData = addOrRemoveRowsFromData(
      state,
      fullData,
      affectedRowIndices,
      mode
    );

    const newTableMessages = offsetMapEntriesAfterRowChange(
      state.coredata.tableMessages,
      affectedRowIndices,
      mode
    );

    const newTransformErrorCells = offsetSetEntriesAfterRowChange(
      state.coredata.transformErrorCells,
      affectedRowIndices,
      mode
    );

    const newSelectOverrides = offsetSelectOptionOverrideMaps(
      state.coredata.selectOptionOverrides,
      affectedRowIndices,
      mode
    );

    batch(() => {
      dispatch(setCellTransformErrors(newTransformErrorCells));
      dispatch(setTableMessages(newTableMessages));
      dispatch(setSelectOptionOverrides(newSelectOverrides));
    });

    return newFullData;
  };
};

const updateUserMessages = (newUserMessages: ITableMessages): AppThunk => {
  return (dispatch, getState) => {
    const newMessages = selectNewTableMessages(getState());

    newUserMessages.forEach((cellUserMessages, cellKey) => {
      const existingCellMessages = newMessages.get(cellKey) || [];
      const nonUserMessages = existingCellMessages.filter(
        (msg) => msg.type !== "user-generated"
      );
      const newCellMessages = nonUserMessages.concat(cellUserMessages);

      if (newCellMessages.length > 0) {
        newMessages.set(cellKey, newCellMessages);
      } else {
        newMessages.delete(cellKey);
      }
    });

    dispatch(setTableMessages(newMessages));
  };
};

const cloneFullData = (fullData: FullDataWithMeta): FullDataWithMeta =>
  fullData.map((row) => [...row]);

const selectNewTableMessages = (state: RootState): ITableMessages => {
  return new Map(state.coredata.tableMessages.entries());
};

const selectNewTransformErrorCells = (state: RootState): Set<string> => {
  return new Set(state.coredata.transformErrorCells.keys());
};

const buildEmptyRow = (state: RootState): DataWithMetaRow => {
  const fieldInstances = selectMappedFieldInstances(state);
  const rowMeta: IRowMeta = { rowId: uuidv4(), originalIndex: null };
  const newRow: DataWithMetaRow = [
    ...Array.from({ length: selectRawRowWidth(fieldInstances) }).map(
      (_, colIdx) => {
        const field = fieldInstances.get(colIdx);
        if (field) {
          return field.getInitialValue();
        } else {
          return "";
        }
      }
    ),
    rowMeta,
  ];
  return newRow;
};

const filterTableMessagesForKey = (
  tableMessages: ITableMessages,
  key: string,
  filterFn: (msg: ITableMessageInternal) => unknown
): void => {
  if (tableMessages.has(key)) {
    tableMessages.set(key, tableMessages.get(key)!.filter(filterFn));
  }
};

const filterTableMessages = (
  tableMessages: ITableMessages,
  filterFn: (msg: ITableMessageInternal) => unknown
): ITableMessages => {
  const newMessages: ITableMessages = new Map();

  tableMessages.forEach((messages, cellRef) => {
    const filteredMessages = messages.filter(filterFn);
    if (filteredMessages.length > 0) newMessages.set(cellRef, filteredMessages);
  });

  return newMessages;
};

export const addTableMessage = (
  tableMessages: ITableMessages,
  key: string,
  newMessage: ITableMessageInternal
): void => {
  if (tableMessages.has(key)) {
    const messages = tableMessages.get(key)!;
    tableMessages.set(key, [...messages, newMessage]);
  } else {
    tableMessages.set(key, [newMessage]);
  }
};

const addTableMessages = (
  tableMessages: ITableMessages,
  key: string,
  newMessages: ITableMessageInternal[]
): ITableMessages => {
  if (newMessages.length === 0) return tableMessages;

  let messages = tableMessages.get(key) || [];
  messages = messages.concat(newMessages);
  tableMessages.set(key, messages);
  return tableMessages;
};

const buildRowHookInputRows = (
  state: RootState,
  fullData: FullDataWithMeta,
  changedRowIndexes: number[] | Set<number> | null
): IRowHookInput[] => {
  const userMessages = filterTableMessages(
    state.coredata.tableMessages,
    (msg) => msg.type === "user-generated"
  );
  const transformErrorCells = state.coredata.transformErrorCells;
  const fieldSpecs = selectMappedSpecs(state);
  const fieldInstances = selectMappedFieldInstances(state);

  if (changedRowIndexes) {
    return [...changedRowIndexes]
      .filter((rowIndex) => rowIndex in fullData)
      .map((rowIndex) =>
        buildRowHookInputRow(
          fullData,
          rowIndex,
          fieldSpecs,
          fieldInstances,
          transformErrorCells,
          userMessages
        )
      );
  } else {
    return fullData.map((_row, rowIndex) =>
      buildRowHookInputRow(
        fullData,
        rowIndex,
        fieldSpecs,
        fieldInstances,
        transformErrorCells,
        userMessages
      )
    );
  }
};

const buildRowHookInputRow = (
  fullData: FullDataWithMeta,
  rowIndex: number,
  fieldSpecs: Map<number, IDeveloperField>,
  fieldInstances: Map<number, IAbstractField>,
  transformErrorCells: Set<string>,
  userMessages: ITableMessages
): IRowHookInput => {
  const rowHookInputDataRow: IRowHookInput = {
    row: {},
    index: rowIndex,
    rowId: (getRowMeta(fullData[rowIndex]) ?? {}).rowId,
  };

  fieldSpecs.forEach((fieldSpec, colIdx) => {
    const key = `${rowIndex},${colIdx}`;
    const field = fieldInstances.get(colIdx)!;

    const cellValue = fullData[rowIndex][colIdx] ?? "";
    const hasTransformError = transformErrorCells.has(key);
    let selectOptions;

    if (field.type === "select") {
      const selectField = field as SelectField;
      if (selectField.selectOptionOverrideMap.has(rowIndex)) {
        selectOptions = [
          ...selectField.selectOptionOverrideMap.get(rowIndex)!.entries(),
        ].map(([label, value]) => ({
          label,
          value,
        }));
      }
    }

    const fieldObj: IRowHookCell = {
      value: hasTransformError
        ? cellValue
        : field.getEditValueChecked(cellValue, rowIndex),
      resultValue: hasTransformError
        ? null
        : field.getOutputValueChecked(cellValue, rowIndex),
      info: (userMessages.get(key) || []).map(
        (m: ITableMessageInternal): ITableMessage => ({
          level: m.level,
          message: m.message,
        })
      ),
    };

    if (selectOptions) fieldObj.selectOptions = selectOptions;

    if (fieldSpec.manyToOne) {
      rowHookInputDataRow.row[fieldSpec.key] ??= {
        manyToOne: [],
        value: undefined,
        resultValue: undefined,
      };
      rowHookInputDataRow.row[fieldSpec.key].manyToOne!.push(fieldObj);
    } else {
      rowHookInputDataRow.row[fieldSpec.key] = fieldObj;
    }
  });

  return rowHookInputDataRow;
};

const getChangesAndMessagesFromHookOutput = (
  state: RootState,
  fullData: FullDataWithMeta,
  rowHookOutputRows: IRowHookOutputInternal[]
): [HotChange[], ITableMessages, SelectOptionOverride[]] => {
  const changes: [number, number, any, any][] = [];
  const messages: ITableMessages = new Map();
  const selOptionsNew: SelectOptionOverride[] = [];
  const keyToIndexMap = selectKeyToIndexMap(state);

  rowHookOutputRows.forEach((hookOutputRow) => {
    const rowChanges: {
      cell: IRowCell;
      key: string;
      colIndex: number;
    }[] = [];

    for (const key in hookOutputRow.row) {
      // ensure key is in a mapped column
      const colIndexEntry = keyToIndexMap.get(key);

      if (colIndexEntry === undefined) continue;

      const cell = hookOutputRow.row[key];

      if (colIndexEntry.manyToOne) {
        colIndexEntry.indexes.forEach((colIndex, i) =>
          rowChanges.push({
            cell: (cell as IRowCellManyToOne).manyToOne[i],
            key,
            colIndex,
          })
        );
      } else {
        rowChanges.push({
          cell: cell as IRowCell,
          key,
          colIndex: colIndexEntry.index,
        });
      }
    }

    rowChanges.forEach(({ cell, key, colIndex }) => {
      const { info, value, selectOptions } = cell;

      if (value !== undefined) {
        const oldValue = fullData[hookOutputRow.index][colIndex];
        changes.push([hookOutputRow.index, colIndex, oldValue, value]);
      }

      const cellKey = `${hookOutputRow.index},${colIndex}`;
      if (info) {
        const tableMessages: ITableMessageInternal[] = info.map(
          (userMessage) => ({
            ...userMessage,
            type: "user-generated",
          })
        );

        messages.set(cellKey, tableMessages);
      }

      if (selectOptions) {
        selOptionsNew.push([hookOutputRow.index, key, selectOptions]);
      }
    });
  });

  return [changes, messages, selOptionsNew];
};

const addOrRemoveRowsFromData = (
  state: RootState,
  fullData: FullDataWithMeta,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): FullDataWithMeta => {
  const newFullData = cloneFullData(fullData);

  if (mode === CoreDataRowAction.ADD) {
    const newRows = affectedRowIndices.map(() => buildEmptyRow(state));
    newFullData.splice(affectedRowIndices[0], 0, ...newRows);
  } else {
    newFullData.splice(affectedRowIndices[0], affectedRowIndices.length);
  }
  return newFullData;
};

const offsetMapEntriesAfterRowChange = (
  tableMap: Map<string, any>,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): Map<string, any> => {
  if (affectedRowIndices.length === 0) return tableMap;
  const updatedMap = new Map<string, any>();
  for (const [key, value] of tableMap) {
    const newKey = getOffsetKey(key, affectedRowIndices, mode);

    // If this row hasn't been removed, include it in the new map
    if (newKey) {
      updatedMap.set(newKey, value);
    }
  }
  return updatedMap;
};

/**
 * This function returns the new tableMessages with all indexes updated accordingly to what rows were removed.
 * The difference between this function and offsetMapEntriesAfterRowChange is that this function
 * takes into account offsets from multiple blocks of rows, whereas the other function only takes into account
 * a single block of rows.
 */
export const getOffsetTableMessages = (
  oldTableMessages: Map<string, ITableMessageInternal[]>,
  rowsWithErrors: Set<number>
): Map<string, ITableMessageInternal[]> => {
  const newErrorRowIndexes = [...rowsWithErrors]
    .sort((a, b) => a - b)
    .reduce(
      (map, oldIndex, newIndex) => map.set(oldIndex, newIndex),
      new Map<number, number>()
    );

  const errorTableMessages = new Map<string, ITableMessageInternal[]>();

  for (const [position, tableMessageArray] of oldTableMessages.entries()) {
    const [row, col] = position.split(",");
    const rowNum = parseInt(row, 10);
    if (rowsWithErrors.has(rowNum)) {
      const newRowNumber = newErrorRowIndexes.get(rowNum);
      errorTableMessages.set(`${newRowNumber},${col}`, tableMessageArray);
    }
  }

  return errorTableMessages;
};

const offsetSetEntriesAfterRowChange = (
  keySet: Set<string>,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): Set<string> => {
  const updatedSet = new Set<string>();

  keySet.forEach((key) => {
    const newKey = getOffsetKey(key, affectedRowIndices, mode);
    if (newKey) updatedSet.add(newKey);
  });

  return updatedSet;
};

const offsetSelectOptionOverrideMaps = (
  selectOptionOverrides: SelectOptionOverrides,
  affectedRowIndexes: number[],
  mode: CoreDataRowAction
): SelectOptionOverrides => {
  const offset =
    affectedRowIndexes.length * (mode === CoreDataRowAction.ADD ? 1 : -1);
  const newSelectOverrides: SelectOptionOverrides = new Map();

  selectOptionOverrides.forEach((overrideMap, fieldKey) => {
    const overrideEntries = [...overrideMap];

    const newFieldOverrides = new Map(
      overrideEntries
        // First remove the entries for rows that have been removed
        .filter(
          ([rowIdx]) =>
            !(affectedRowIndexes.includes(rowIdx) && CoreDataRowAction.REMOVE)
        )
        // Then offset the survivors
        .map(([rowIdx, map]) =>
          rowIdx >= affectedRowIndexes[0]
            ? [rowIdx + offset, map]
            : [rowIdx, map]
        )
    );

    newSelectOverrides.set(fieldKey, newFieldOverrides);
  });

  return newSelectOverrides;
};

/**
 * This function returns the new key for a given cell key after an offset action.
 * If the key is in a row that has been removed, returns null.
 */
const getOffsetKey = (
  key: string,
  affectedRowIndices: number[],
  mode: CoreDataRowAction
): string | null => {
  const startingIndex = affectedRowIndices[0];
  const rowOffset =
    mode === CoreDataRowAction.ADD
      ? affectedRowIndices.length
      : affectedRowIndices.length * -1;
  const [row, col] = key.split(",").map((n: string) => parseInt(n));
  let newKey: string | null = null;

  // If this row hasn't been removed, include it
  if (mode === CoreDataRowAction.ADD || affectedRowIndices.indexOf(row) < 0) {
    if (row >= startingIndex) {
      // adding 0 or more rows, increase the row indexes by rowOffset
      newKey = `${row + rowOffset},${col}`;
    } else {
      newKey = key;
    }
  }

  return newKey;
};

export const getRowMeta = (row: DataWithMetaRow): IRowMeta =>
  row.at(-1) as IRowMeta;

export const addRowMetaToData = (data: ParsedData): FullDataWithMeta => {
  if (data.length === 0 || data[0].length === 0) {
    return data as FullDataWithMeta;
  }

  return data.map((row, originalIndex): DataWithMetaRow => {
    return [
      ...row,
      {
        rowId: uuidv4(),
        originalIndex,
      },
    ];
  });
};

export const addRows = (
  newRows: IRowToAdd[],
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch, getState) => {
    const sortedRows = newRows
      .map((r) => ({ ...r, index: r.index ?? fullData.length } as IRowToAdd))
      .sort((row1, row2) => row1.index! - row2.index!)
      .map((r, idx) => ({ ...r, index: r.index! + idx } as IRowToAdd));

    let newData: FullDataWithMeta = fullData;
    const overrides: SelectOptionOverride[] = [];
    const userMessages: INewTableMessages = new Map();
    const changes: HotChange[] = [];
    const keyToIndexMap = selectKeyToIndexMap(getState());

    sortedRows.forEach((newRow) => {
      newData = dispatch(addEmptyRows(newData, [newRow.index!]));

      for (const [key, cell] of Object.entries(newRow.row)) {
        const indexEntry = keyToIndexMap.get(key) as {
          manyToOne: boolean;
          indexes: [];
          index: number;
        };

        let newCells: { cell: IRowCellBasic; colIndex: number }[];
        if (indexEntry.manyToOne) {
          newCells = (cell.manyToOne as IRowCellBasic[]).map((c, i) => ({
            cell: c,
            colIndex: indexEntry.indexes[i],
          }));
        } else {
          newCells = [{ cell, colIndex: indexEntry.index }];
        }

        for (const { cell, colIndex } of newCells) {
          if (cell.value !== undefined) {
            changes.push([newRow.index!, colIndex, null, cell.value]);
          }
          if (cell.selectOptions) {
            overrides.push([newRow.index!, key, cell.selectOptions]);
          }
          if (cell.info) {
            userMessages.set(
              `${newRow.index!},${colIndex}`,
              cell.info.map((msg) => ({ ...msg, type: "user-generated" }))
            );
          }
        }
      }
    });

    batch(() => {
      dispatch(updateSelectOptionOverrides(overrides));
      dispatch(updateUserMessages(userMessages));
      newData = dispatch(processChanges(newData, changes));
    });
    return newData;
  };
};

export const buildRowIdToIndexMap = (
  fullData: FullDataWithMeta
): Map<string, number> => {
  return fullData.reduce((map, row, idx) => {
    map.set(getRowMeta(row).rowId, idx);
    return map;
  }, new Map());
};

export const removeRowsById = (
  rowIds: string[],
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch) => {
    let newFullData = fullData;
    const rowIdToIndexMap = buildRowIdToIndexMap(newFullData);
    // Sort them highest to lowest so we don't need to offset as we remove
    const sortedIndices = rowIds
      .map((rowId) => {
        const rowIndex = rowIdToIndexMap.get(rowId);
        if (rowIndex === undefined) {
          // eslint-disable-next-line no-console
          console.warn(`Remove Row: cannot find rowId: ${rowId}`);
        }
        return rowIndex;
      })
      .filter((idx) => idx !== undefined)
      .sort((idx1, idx2) => idx2! - idx1!);
    sortedIndices.forEach((idx) => {
      newFullData = dispatch(removeConsecutiveRows(newFullData, [idx!]));
    });
    return newFullData;
  };
};

export const addOrRemoveQueuedRows = (
  fullData: FullDataWithMeta
): DataThunk => {
  return (dispatch, getState) => {
    let newData = [...fullData];
    const { rowsToAdd, rowsToDelete } = getState().coredata;
    if (rowsToAdd.length > 0) {
      newData = dispatch(addRows(rowsToAdd, newData));
      dispatch(clearAddRowQueue());
    }

    if (rowsToDelete.length > 0) {
      newData = dispatch(removeRowsById(rowsToDelete, newData));
      dispatch(clearRemoveRowQueue());
    }
    return newData;
  };
};

export interface FindAndReplaceOpts {
  fullCell: boolean;
  caseSensitive: boolean;
  field: string | null;
  manyToOneIndex: number | null;
  // onlyCellsWithErrors: boolean;
}

// field types where the display values differ from stored values
const typesToGetDisplayValue = ["date", "time", "datetime", "number"];

/*
 * Runs the given find-and-replace operation as requested by the user.
 */
export const findAndReplace = (
  fullData: FullDataWithMeta,
  runClientRowHooks: RunRowHooksFn,
  query: string,
  replaceVal: string,
  opts: FindAndReplaceOpts
): AsyncDataThunk => {
  return async (dispatch, getState) => {
    const changes: HotChange[] = [];

    const state = getState();
    const { transformErrorCells } = state.coredata;
    const keyToIndexMap = selectKeyToIndexMap(state);
    const fieldInstances = selectMappedFieldInstances(state);

    let replaceColIndex: number | null = null;
    if (opts.field) {
      const indexEntry = keyToIndexMap.get(opts.field);
      if (opts.manyToOneIndex !== null) {
        replaceColIndex = (indexEntry as ManyToOneIndexEntry).indexes[
          opts.manyToOneIndex
        ];
      } else {
        replaceColIndex = (indexEntry as OneToOneIndexEntry).index;
      }
    }

    const regexStr =
      opts.fullCell || query.length === 0
        ? `^${escapeRegExp(query)}$`
        : escapeRegExp(query);

    // escape special replacement patterns with $ literals
    const replaceStr = replaceVal.replace("$", "$$");

    const flags = opts.caseSensitive ? "g" : "gi";
    const regex = new RegExp(regexStr, flags);

    fullData.forEach((row, rowIndex) => {
      row.forEach((cell, colIndex) => {
        if (replaceColIndex !== null && replaceColIndex !== colIndex) return;
        const field = fieldInstances.get(colIndex);
        if (!field) return;
        if (field.type === "checkbox") return;

        if (typesToGetDisplayValue.includes(field.type)) {
          const cellKey = `${rowIndex},${colIndex}`;
          if (!transformErrorCells.has(cellKey)) {
            // @ts-expect-error Can't convince TS that cell matches the right type here
            cell = field.getDisplayValueChecked(cell);
          }
        }

        if (typeof cell !== "string") return;

        const newVal = cell.replace(regex, replaceStr);
        if (newVal !== cell) {
          changes.push([rowIndex, colIndex, cell, newVal]);
        }
      });
    });

    if (changes.length === 0) return fullData;

    return await dispatch(
      processChangesAndRunRowHooks(fullData, changes, runClientRowHooks)
    );
  };
};

export const processChangesAndRunRowHooks = (
  fullData: FullDataWithMeta,
  changes: HotChange[],
  runClientRowHooks: RunRowHooksFn
): AsyncDataThunk => {
  return async (dispatch) => {
    const updateData = dispatch(processChanges(fullData, changes));
    return await dispatch(
      runRowHooks(updateData, runClientRowHooks, "update", changes)
    );
  };
};
