import { IProgress } from "../../interfaces/interfaces";
import { EFileUploadStage } from "../../constants/EFileUploadStage";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { resetState } from "./coredata";
import { FullDataWithMeta } from "../../thunks/data_actions";

export type RehydrateStage =
  | "SHEET_SELECTION"
  | "HEADER_SELECTION"
  | "COLUMN_MATCHING"
  | "DATA_REVIEW";

interface IModalReduxState {
  stage: EFileUploadStage;
  exitRequested: boolean;
  rehydrateStage: RehydrateStage | null;
  rehydrateTempData: FullDataWithMeta;
  rehydrationComplete: boolean;
  progress: IProgress;
}

const initialState: IModalReduxState = {
  stage: EFileUploadStage.DATA_UPLOAD,
  exitRequested: false,
  rehydrateStage: null,
  rehydrateTempData: [],
  rehydrationComplete: false,
  progress: {
    message: "",
    count: 0,
  },
};

const modalsSlice = createSlice({
  name: "modals",
  initialState,
  reducers: {
    setStage: (state, action: PayloadAction<EFileUploadStage>) => {
      state.stage = action.payload;
    },

    setExitRequested: (state, action: PayloadAction<boolean>) => {
      state.exitRequested = action.payload;
    },

    setRehydrateStage: (state, action: PayloadAction<RehydrateStage>) => {
      state.rehydrateStage = action.payload;
    },

    setRehydrateTempData: (state, action: PayloadAction<FullDataWithMeta>) => {
      state.rehydrateTempData = action.payload;
    },

    setRehydrationComplete: (state) => {
      state.rehydrateTempData = [];
      state.rehydrationComplete = true;
    },

    updateProgress: {
      prepare: (count: number, message?: string) => {
        return { payload: { count, message: message ?? "" } };
      },
      reducer: (
        state,
        action: PayloadAction<{ count: number; message: string }>
      ) => {
        state.progress = action.payload;
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resetState, () => initialState);
  },
});

export const {
  setStage,
  setExitRequested,
  setRehydrateStage,
  setRehydrationComplete,
  setRehydrateTempData,
  updateProgress,
} = modalsSlice.actions;
export default modalsSlice.reducer;
