import { z } from "zod";
import {
  DEFAULT_API_URL,
  VERSION_TWO,
  MAX_UPLOAD_FILE_SIZE,
} from "../../constants/constants";
import { EBackendSyncMode, EInvalidDataBehavior } from "../../interfaces";
import {
  StyleOverridesSchema,
  setStyleOverrideDefaults,
} from "./style_overrides";

const isValidHttpUrl = (url: string): boolean => {
  let urlObj: URL;
  try {
    urlObj = new URL(url);
  } catch (_) {
    return false;
  }

  const goodProtocol =
    urlObj.protocol === "http:" || urlObj.protocol === "https:";
  const notLocalhost = urlObj.hostname !== "localhost";
  const notLoopback = !urlObj.host.startsWith("127.");

  return goodProtocol && notLocalhost && notLoopback;
};

export const LocaleSchema = z.enum([
  "bn",
  "en",
  "hr",
  "ja",
  "nl",
  "ru",
  "uk",
  "cs",
  "es",
  "hu",
  "ko",
  "no",
  "sv",
  "vi",
  "da",
  "fi",
  "id",
  "lt",
  "pl",
  "sw",
  "zh_CN",
  "de",
  "fr",
  "is",
  "lv",
  "pt",
  "th",
  "zh_TW",
  "el",
  "hi",
  "it",
  "ms",
  "ro",
  "tr",
]);

export type TLocaleShorthand = z.infer<typeof LocaleSchema>;

const invalidDataBehaviorEnum = [
  "BLOCK_SUBMIT",
  "INCLUDE_INVALID_ROWS",
  "REMOVE_INVALID_ROWS",
] as const;

const backendSyncModeEnum = ["DISABLED", "FULL_DATA", "MAPPINGS_ONLY"] as const;

export const DeveloperSettingsSchema = z
  .object({
    importIdentifier: z.string(),
    title: z.string().optional(),
    allowInvalidSubmit: z.boolean().optional(),
    invalidDataBehavior: z.enum(invalidDataBehaviorEnum).optional(),
    backendSync: z.boolean().optional(),
    backendSyncMode: z.enum(backendSyncModeEnum).optional(),
    manualInputDisabled: z.boolean().default(false),
    manualInputOnly: z.boolean().default(false),
    allowCustomFields: z.boolean().default(false),
    maxRecords: z.number().int().nullable().default(null),
    developmentMode: z.boolean().optional(),
    displayEncoding: z.boolean().default(true),
    styleOverrides: StyleOverridesSchema.default({}),
    maxFileSize: z
      .number()
      .int()
      .positive()
      .max(MAX_UPLOAD_FILE_SIZE)
      .default(MAX_UPLOAD_FILE_SIZE),
    webhookUrl: z
      .string()
      .nullable()
      .default(null)
      .refine(
        (val) => {
          if (val === null) return true;
          return isValidHttpUrl(val);
        },
        {
          message: "Invalid URL",
        }
      ),
    needsReviewWebhookUrl: z
      .string()
      .nullable()
      .default(null)
      .refine(
        (val) => {
          if (val === null) return true;
          return isValidHttpUrl(val);
        },
        {
          message: "Invalid URL",
        }
      ),
    initialData: z
      .union([z.array(z.record(z.any())), z.array(z.array(z.any()))])
      .nullable()
      .default(null),
    uploadStep: z
      .object({
        helpText: z.string().nullable().default(null),
      })
      .default({}),
    matchingStep: z
      .object({
        helpText: z.string().nullable().default(null),
        headerRowOverride: z.number().int().nullable().default(null),
        fuzzyMatchHeaders: z.boolean().default(true),
        alwaysMatchSelectFields: z.boolean().default(false),
        matchToSchema: z.boolean().default(false),
      })
      .default({}),
    reviewStep: z
      .object({
        helpText: z.string().nullable().default(null),
        processingText: z.string().nullable().default(null),
        enableUserTransformations: z.boolean().default(false),
      })
      .default({}),
    autoMapHeaders: z.boolean().default(false),
    delimiter: z.string().optional(),
    backendOverride: z
      .object({
        url: z.string(),
        type: z.enum(["AWS", "AZURE"]),
      })
      .default({
        url: DEFAULT_API_URL,
        type: "AWS",
      }),
    locale: LocaleSchema.default("en"),
    templateDownloadFilename: z.string().nullable().default(null),
    browserExcelParsing: z.boolean().default(false),
    version: z.string().default(""),
    alternateDomain: z.string().nullable().default(null),
  })
  .refine(
    (settings) => {
      if (settings.manualInputDisabled && settings.manualInputOnly) {
        return false;
      }
      return true;
    },
    { message: "manualInputDisabled and manualInputOnly can't both be `true`" }
  )
  .refine(
    (settings) => {
      if (
        settings.matchingStep.matchToSchema &&
        settings.version !== VERSION_TWO
      ) {
        return false;
      }
      return true;
    },
    { message: "matchToSchema is only available for version 2" }
  )
  .transform((settings) => {
    if (!settings.invalidDataBehavior) {
      settings.invalidDataBehavior = settings.allowInvalidSubmit
        ? EInvalidDataBehavior.INCLUDE_INVALID_ROWS
        : EInvalidDataBehavior.REMOVE_INVALID_ROWS;
    }

    // backendSyncMode obsoletes backendSync
    // if backendSync is true, we'll use that to not break existing workflows
    // but only if we don't have a value for backendSyncMode
    if (!settings.backendSyncMode) {
      settings.backendSyncMode = settings.backendSync
        ? EBackendSyncMode.FULL_DATA
        : EBackendSyncMode.DISABLED;
    }

    return settings;
  })
  .transform((settings) => {
    // we have to support two sets of style override defaults while we have two versions.
    // we can get rid of all of this when we ditch version 1
    const { styleOverrides, version } = settings;
    const defaultedStyleOverrides = setStyleOverrideDefaults(
      version === VERSION_TWO,
      styleOverrides
    );

    settings.styleOverrides = defaultedStyleOverrides;
    return settings;
  })
  .transform((settings) => {
    // If we have a backendOverride url that's different from the default, use that
    // Otherwise use the alternateDomain if present
    const { backendOverride, alternateDomain } = settings;

    if (backendOverride.url === DEFAULT_API_URL && alternateDomain) {
      const urlObj = new URL(alternateDomain);
      backendOverride.url = urlObj.protocol + "//app." + urlObj.host;
    }

    return settings;
  });

export type IDeveloperSettings = z.input<typeof DeveloperSettingsSchema>;
export type IDeveloperSettingsStrict = z.output<typeof DeveloperSettingsSchema>;
