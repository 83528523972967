import {
  DEFAULT_API_URL,
  allFiletypes,
  localFiletypes,
} from "../../constants/constants";
import {
  IDeveloperSettingsStrict,
  IDeveloperStyleOverridesStrict,
  TBackendType,
  IBackendCapabilities,
  IUser,
  ISavedSchema,
  EInvalidDataBehavior,
  EBackendSyncMode,
} from "../../interfaces";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../reducers";
import {
  DeveloperSettingsSchema,
  IDeveloperSettings,
} from "../../helpers/schemas/settings";

export type TImporterMode = "INIT" | "DEMO" | "DEVELOPMENT" | "PRODUCTION";

export interface ISettingsReduxState {
  licenseKey: string;
  importIdentifier: string;
  title: string | null;
  user: IUser;
  styleOverrides: IDeveloperStyleOverridesStrict;
  importerMode: TImporterMode;
  backend: {
    url: string;
    type: TBackendType;
  };
  backendCapabilities: IBackendCapabilities;
  maxRecords: number | null;
  maxFileSize: number;
  browserExcelParsing: boolean;
  headerRowOverride: number | null;
  alwaysMatchSelectFields: boolean;
  delimiter: string | undefined;
  autoMapHeaders: boolean;
  savedSchema: null | ISavedSchema;
  invalidDataBehavior: EInvalidDataBehavior;
  appHost: null | string;
  backendSyncMode: EBackendSyncMode;
  webhookUrl: null | string;
  manualInputDisabled: boolean;
  manualInputOnly: boolean;
  templateDownloadFilename: null | string;
  uploadStep: IDeveloperSettingsStrict["uploadStep"];
  matchingStep: IDeveloperSettingsStrict["matchingStep"];
  reviewStep: IDeveloperSettingsStrict["reviewStep"];
  displayEncoding: boolean;
  allowCustomFields: boolean;
  version: string;
  originalSettings?: IDeveloperSettings;
  isHeadless: boolean; // whether we are running in a headless context right now
}

export const defaultBackendCapabilities: IBackendCapabilities = {
  accept_json_results: false,
  write_only_storage: false,
  saved_schema_only: false,
  ai_matching: false,
  transform_data: false,
};

const DefaultDeveloperSettings = DeveloperSettingsSchema.parse({
  importIdentifier: "",
});

const initialState: ISettingsReduxState = {
  licenseKey: "",
  importIdentifier: "",
  title: null,
  user: { id: "" },
  styleOverrides: DefaultDeveloperSettings.styleOverrides,
  importerMode: "INIT",
  backend: {
    url: DEFAULT_API_URL,
    type: "AWS",
  },
  backendCapabilities: defaultBackendCapabilities,
  maxRecords: DefaultDeveloperSettings.maxRecords,
  maxFileSize: DefaultDeveloperSettings.maxFileSize,
  browserExcelParsing: DefaultDeveloperSettings.browserExcelParsing,
  headerRowOverride: DefaultDeveloperSettings.matchingStep.headerRowOverride,
  alwaysMatchSelectFields: false,
  delimiter: DefaultDeveloperSettings.delimiter,
  autoMapHeaders: DefaultDeveloperSettings.autoMapHeaders,
  savedSchema: null,
  invalidDataBehavior:
    DefaultDeveloperSettings.invalidDataBehavior as EInvalidDataBehavior,
  appHost: null,
  backendSyncMode: DefaultDeveloperSettings.backendSyncMode as EBackendSyncMode,
  webhookUrl: DefaultDeveloperSettings.webhookUrl,
  manualInputDisabled: DefaultDeveloperSettings.manualInputDisabled,
  manualInputOnly: DefaultDeveloperSettings.manualInputOnly,
  templateDownloadFilename: DefaultDeveloperSettings.templateDownloadFilename,
  uploadStep: DefaultDeveloperSettings.uploadStep,
  matchingStep: DefaultDeveloperSettings.matchingStep,
  reviewStep: DefaultDeveloperSettings.reviewStep,
  displayEncoding: DefaultDeveloperSettings.displayEncoding,
  allowCustomFields: DefaultDeveloperSettings.allowCustomFields,
  version: DefaultDeveloperSettings.version,
  isHeadless: false,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setLicenseKey: (state, action: PayloadAction<string>) => {
      state.licenseKey = action.payload;
    },

    setAppHost: (state, action: PayloadAction<string>) => {
      state.appHost = action.payload;
    },

    setUser: (state, action: PayloadAction<IUser>) => {
      state.user = action.payload;
    },

    setBackendCapabilities: (
      state,
      action: PayloadAction<IBackendCapabilities>
    ) => {
      state.backendCapabilities = action.payload;
    },

    setFromDeveloperSettings: (
      state,
      action: PayloadAction<IDeveloperSettingsStrict>
    ) => {
      return {
        ...state,
        importIdentifier: action.payload.importIdentifier,
        title: action.payload.title ?? null,
        styleOverrides: action.payload.styleOverrides,
        backend: action.payload.backendOverride,
        browserExcelParsing: action.payload.browserExcelParsing,
        maxRecords: action.payload.maxRecords,
        maxFileSize: action.payload.maxFileSize,
        headerRowOverride: action.payload.matchingStep.headerRowOverride,
        alwaysMatchSelectFields:
          action.payload.matchingStep.alwaysMatchSelectFields,
        delimiter: action.payload.delimiter,
        autoMapHeaders: action.payload.autoMapHeaders,
        invalidDataBehavior: action.payload
          .invalidDataBehavior as EInvalidDataBehavior,
        backendSyncMode: action.payload.backendSyncMode as EBackendSyncMode,
        webhookUrl: action.payload.webhookUrl,
        manualInputDisabled: action.payload.manualInputDisabled,
        manualInputOnly: action.payload.manualInputOnly,
        templateDownloadFilename: action.payload.templateDownloadFilename,
        uploadStep: action.payload.uploadStep,
        matchingStep: action.payload.matchingStep,
        reviewStep: action.payload.reviewStep,
        displayEncoding: action.payload.displayEncoding,
        allowCustomFields: action.payload.allowCustomFields,
        importerMode:
          action.payload.developmentMode !== undefined
            ? action.payload.developmentMode
              ? "DEVELOPMENT"
              : "PRODUCTION"
            : "INIT",
        version: action.payload.version,
      };
    },

    setSavedSchema: (state, action: PayloadAction<ISavedSchema>) => {
      state.savedSchema = action.payload;
    },

    setHeaderRowOverride: (state, action: PayloadAction<number | null>) => {
      state.headerRowOverride = action.payload;
    },

    setImporterMode: (state, action: PayloadAction<TImporterMode>) => {
      state.importerMode = action.payload;
    },

    setMaxRecords: (state, action: PayloadAction<number>) => {
      state.maxRecords = action.payload;
    },

    setOriginalSettings: (state, action: PayloadAction<IDeveloperSettings>) => {
      state.originalSettings = action.payload;
    },

    setIsHeadless: (state) => {
      state.isHeadless = true;
    },
  },
});

export const selectGlobalStyle = (state: RootState) => ({
  color: state.settings.styleOverrides.global.textColor,
});

export const selectAllowedExtensions = (state: RootState): string[] => {
  const { backendSyncMode, browserExcelParsing } = state.settings;
  const writeOnlyStorage =
    state.settings.backendCapabilities.write_only_storage;
  const extensions =
    backendSyncMode === EBackendSyncMode.FULL_DATA && !writeOnlyStorage
      ? allFiletypes
      : localFiletypes;

  return [
    ...new Set([
      ...extensions,
      ...(browserExcelParsing ? ["xlsx", "xls"] : []),
    ]),
  ]
    .map((ext) => `.${ext}`)
    .sort();
};

export const selectAIMatching = (state: RootState): boolean =>
  (state.settings.backendSyncMode === EBackendSyncMode.FULL_DATA ||
    state.settings.backendSyncMode === EBackendSyncMode.MAPPINGS_ONLY) &&
  state.settings.matchingStep.fuzzyMatchHeaders &&
  state.settings.backendCapabilities.ai_matching;

export const {
  setLicenseKey,
  setAppHost,
  setUser,
  setBackendCapabilities,
  setFromDeveloperSettings,
  setSavedSchema,
  setHeaderRowOverride,
  setImporterMode,
  setMaxRecords,
  setOriginalSettings,
  setIsHeadless,
} = settingsSlice.actions;
export default settingsSlice.reducer;
