import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";
import { IDeveloperSelectOption } from "../interfaces";

export interface SelectOpts extends BaseTypeOpts {
  selectOptions: IDeveloperSelectOption[];
  selectOptionOverrideMap: Map<number, Map<string, string>>;
  allowCustom?: boolean;
}

export class SelectField extends AbstractField<SelectOpts, string> {
  type = "select" as const;

  readonly labels: string[];
  /** Map of labels => values */
  readonly optionMap: Map<string, string>;
  readonly selectOptionOverrideMap: Map<number, Map<string, string>>;

  constructor(opts: SelectOpts) {
    super(opts);

    const selectOptions = opts.selectOptions.map(SelectField.normalizeOption);
    this.labels = selectOptions.map((o) => o.label);
    this.optionMap = new Map(selectOptions.map((o) => [o.label, o.value]));
    this.selectOptionOverrideMap = opts.selectOptionOverrideMap;
  }

  transform(rawValue: string, row: number): ITransformResult<string> {
    const optionMap = this.selectOptionOverrideMap.get(row) || this.optionMap;

    if (this.opts.allowCustom) {
      return this.transformSuccess(rawValue);
    }

    if (optionMap.has(rawValue)) {
      return this.transformSuccess(rawValue);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string, row: number): string {
    const optionMap = this.selectOptionOverrideMap.get(row) || this.optionMap;
    if (optionMap.has(value)) {
      return optionMap.get(value)!;
    }

    return value;
  }

  static normalizeOption(
    this: void,
    option: IDeveloperSelectOption
  ): IDeveloperSelectOption {
    const normalized: IDeveloperSelectOption = {
      value: option.value.trim(),
      label: option.label.trim(),
    };

    if (option.alternateMatches) {
      normalized.alternateMatches = option.alternateMatches.map((alt) =>
        alt.trim()
      );
    }

    return normalized;
  }
}
