import "bootstrap/dist/css/bootstrap.css";
import "handsontable/dist/handsontable.full.css";
import Papa from "papaparse";
import React, { useEffect, useState } from "react";
import { FileWithPath } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";

import { EFileUploadStage } from "../../constants/EFileUploadStage";
import { VERSION_TWO } from "../../constants/constants";
import { nextStepFromUpload } from "../../helpers/nextStep";
import { EStepHook } from "../../interfaces";
import { useSettings, useStyles } from "../../store/hooks";
import { RootState } from "../../store/reducers";
import {
  clearSelectedHandsonCell,
  hideProcessingModal,
  showProcessingModal,
} from "../../store/reducers/commonComponents";
import { setRehydrationComplete } from "../../store/reducers/modals";
import { selectMatchableFieldSpecs } from "../../store/reducers/fields";
import {
  parseSelectedSheet,
  processSelectedFile,
} from "../../thunks/file_processing";

import { DataUploadRedesign } from "./DataUploadRedesign";
import { DataUpload } from "./DataUploadOriginal";
import { handleStepHook } from "../../thunks/parent_connection_handlers";
import { initColumnMappings } from "../../thunks/initial_data";
import { setModalStage } from "../../thunks/modal_stage";
import { useParentConnectionContext } from "../ParentConnectionContext";

const DataUploadModal = () => {
  const connection = useParentConnectionContext();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSelectSheetModal, setShowSelectSheetModal] = useState(false);
  const [showMaxRecordsAlert, setShowMaxRecordsAlert] = useState(false);
  const dispatch = useDispatch();
  const { rehydrate, matchableFields, initialized } = useSelector(
    (state: RootState) => ({
      rehydrate:
        state.modals.rehydrateStage === "SHEET_SELECTION" &&
        !state.modals.rehydrationComplete,
      matchableFields: selectMatchableFieldSpecs(state.fields),
      initialized: state.coredata.initialized,
    })
  );

  const global = useStyles((styles) => styles.global);

  const {
    manualInputDisabled,
    manualInputOnly,
    templateDownloadFilename,
    uploadStep: stepSettings,
    version,
  } = useSettings();

  useEffect(() => {
    dispatch(clearSelectedHandsonCell());

    if (rehydrate) {
      setShowSelectSheetModal(true);
      dispatch(setRehydrationComplete());
    }
  }, [dispatch, rehydrate]);

  const onTemplateDownloadClick = () => {
    if (templateDownloadFilename === null) return;
    const fields = matchableFields.map((field) => field.label);

    const csv = Papa.unparse({
      fields,
      data: [],
    });

    const elm = document.createElement("a");
    elm.setAttribute(
      "href",
      "data:text/csv;charset=utf-8," + encodeURIComponent(csv)
    );
    let filename = templateDownloadFilename;
    if (filename.split(".").pop() !== "csv") {
      filename += ".csv";
    }
    elm.setAttribute("download", filename);
    elm.click();
  };

  const advanceToReview = () => {
    // setting initial data automatically advances to data review
    // see setInitialUnmappedData and setInitialMappedData
    dispatch(initColumnMappings());
    dispatch(setModalStage(EFileUploadStage.DATA_REVIEW));
  };

  const onSelectSheet = async () => {
    dispatch(showProcessingModal());
    setShowSelectSheetModal(false);

    const { success, requiresMaxRecordsDisclaimer } = await dispatch(
      parseSelectedSheet(true)
    );

    if (!success) {
      setShowErrorModal(true);
    } else if (requiresMaxRecordsDisclaimer) {
      setShowMaxRecordsAlert(true);
    } else {
      await proceedToNextStep();
    }

    dispatch(hideProcessingModal());
  };

  const processSelectedLocalFile = async (file: FileWithPath) => {
    dispatch(showProcessingModal());

    const { success, requiresSheetSelection, requiresMaxRecordsDisclaimer } =
      await dispatch(processSelectedFile(file as File));

    if (!success) {
      setShowErrorModal(true);
    } else if (requiresSheetSelection) {
      setShowSelectSheetModal(true);
    } else if (requiresMaxRecordsDisclaimer) {
      setShowMaxRecordsAlert(true);
    } else {
      await proceedToNextStep();
    }

    dispatch(hideProcessingModal());
  };

  const proceedToNextStep = async () => {
    await dispatch(handleStepHook(connection, EStepHook.UPLOAD_STEP));
    dispatch(nextStepFromUpload());
  };

  const onCloseMaxRecordsAlert = async () => {
    setShowMaxRecordsAlert(false);
    proceedToNextStep();
  };

  const props = {
    manualInputDisabled,
    manualInputOnly,
    advanceToReview,
    onSelectSheet,
    onTemplateDownloadClick,
    processSelectedLocalFile,
    setShowErrorModal,
    showErrorModal,
    showSelectSheetModal,
    stepSettings,
    templateDownloadFilename,
    textColor: global?.textColor || "",
    initialized,
    showMaxRecordsAlert,
    onCloseMaxRecordsAlert,
  };

  return version === VERSION_TWO ? (
    <DataUploadRedesign {...props} />
  ) : (
    <DataUpload {...props} />
  );
};

export interface UIComponentProps {
  showErrorModal: boolean;
  setShowErrorModal: (show: boolean) => void;
  showSelectSheetModal: boolean;
  textColor: string;
  manualInputDisabled: boolean;
  manualInputOnly: boolean;
  templateDownloadFilename: string | null;
  stepSettings: {
    helpText: string | null;
  };
  onTemplateDownloadClick: () => void;
  onSelectSheet: (sheetName?: string) => void;
  processSelectedLocalFile: (file: FileWithPath) => void;
  advanceToReview: () => void;
  initialized: boolean;
  showMaxRecordsAlert: boolean;
  onCloseMaxRecordsAlert: () => void;
}

export default DataUploadModal;
