import * as React from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";

interface IStyledSecondaryButtonProps {
  onClick(event: any): void;
  disabled?: boolean | undefined;
}

const mapState = (state: RootState) => {
  return {
    styleOverrides: state.settings.styleOverrides,
  };
};
const reduxConnector = connect(mapState, {});
type IStyledSecondaryButtonFullProps = IStyledSecondaryButtonProps &
  React.HTMLAttributes<HTMLDivElement> &
  ConnectedProps<typeof reduxConnector>;

interface IStyledSecondaryButtonState {
  isHovered: boolean;
}

class StyledSecondaryButton extends React.Component<
  IStyledSecondaryButtonFullProps,
  IStyledSecondaryButtonState
> {
  constructor(props: IStyledSecondaryButtonFullProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  render() {
    const style = this.state.isHovered
      ? {
          ...this.props.style,
          color: this.props.styleOverrides.secondaryButton.hoverTextColor,
          backgroundColor:
            this.props.styleOverrides.secondaryButton.hoverBackgroundColor,
          border: this.props.styleOverrides.secondaryButton.hoverBorder,
          borderRadius: this.props.styleOverrides.secondaryButton.borderRadius,
        }
      : {
          ...this.props.style,
          color: this.props.styleOverrides.secondaryButton.textColor,
          backgroundColor:
            this.props.styleOverrides.secondaryButton.backgroundColor,
          border: this.props.styleOverrides.secondaryButton.border,
          borderRadius: this.props.styleOverrides.secondaryButton.borderRadius,
        };

    return (
      <Button
        type="button"
        style={style}
        className={this.props.className}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
        onMouseOver={() => this.setState({ isHovered: true })}
        onMouseOut={() => this.setState({ isHovered: false })}
        onMouseUp={() => this.setState({ isHovered: false })}
        onMouseDown={() => this.setState({ isHovered: true })}
      >
        <strong>{this.props.children}</strong>
      </Button>
    );
  }
}

export default reduxConnector(StyledSecondaryButton);
