import { appendTableMessagesForKey } from "./CoreDataHelpers";

import {
  IDeveloperField,
  ITableMessageInternal,
  IUniqueValidator,
  IUniqueWithValidator,
  IRequiredValidator,
  IRegexValidator,
  IRequireWithValidator,
  IRequireWithValuesValidator,
  IValidatorField,
  INewTableMessages,
} from "../interfaces";

import { cellIsEmpty, transpose } from "./TableHelpers";
import MapWithDefault from "../util/MapWithDefault";
import { IField, IAbstractField } from "../fields";
import { FullDataWithMeta } from "../thunks/data_actions";
import { ILengthValidator } from "./schemas/validators";

// Used for storing state for unique_with validations.
// A map of maps where the top level map has unique_with string keys
// Pointing to maps of strings of row values to row indexes that have that value
type IUniqueWithDict = MapWithDefault<
  string,
  MapWithDefault<string, Set<number>>
>;

export function runValidators(
  data: FullDataWithMeta,
  columnMapping: Map<number, IDeveloperField>,
  fieldInstances: Map<number, IField>,
  transformErrorCells: Set<string>
): INewTableMessages {
  const newTableMessages: INewTableMessages = new Map();
  const transposeData = transpose(data);

  // a map of field keys to validators on that field
  const columnToValidators = new Map<number, IValidatorField[]>();

  columnMapping.forEach((field: IDeveloperField, colIndex: number) => {
    if (field.validators) {
      columnToValidators.set(colIndex, field.validators);
    }
  });

  // We only want to validate rows that have data in at least one column
  const rowsToValidate: number[] = [];
  data.forEach((row, index) => {
    if (
      // Only consider data in columns we're actually mapping
      row.some(
        (cell, colIdx) => !cellIsEmpty(cell) && columnMapping.has(colIdx)
      )
    ) {
      rowsToValidate.push(index);
    }
  });

  const uniqueWithValidators = new MapWithDefault<
    string,
    Set<[number, IUniqueWithValidator]>
  >(() => new Set());

  // validators that operate on all data in a single column
  columnToValidators.forEach((validators, colIndex) => {
    const field = fieldInstances.get(colIndex)!;

    validators.forEach((validator) => {
      if (
        validator.validate === "unique" ||
        validator.validate === "unique_case_insensitive"
      ) {
        validateUnique(
          validator,
          field,
          colIndex,
          transposeData[colIndex],
          newTableMessages,
          transformErrorCells
        );
      }

      if (validator.validate === "unique_with") {
        uniqueWithValidators
          .get(validator.uniqueKey)
          .add([colIndex, validator]);
      }
    });
  });

  // Map of unique keys to map of values and rows with that value
  const globalUniqueWithDict: IUniqueWithDict = new MapWithDefault(
    () => new MapWithDefault(() => new Set())
  );

  // validators that run on individual cells or full rows
  rowsToValidate.forEach((rowIndex: number) => {
    const rowUniqueWithDict = new MapWithDefault<string, string[]>(() => []);

    columnToValidators.forEach((validators, colIndex) => {
      validators.forEach((validator) => {
        let cellValue = data[rowIndex][colIndex];
        let newMessages: ITableMessageInternal[] = [];

        const key = `${rowIndex},${colIndex}`;
        const field = fieldInstances.get(colIndex)!;

        if (!transformErrorCells.has(key)) {
          cellValue = field.getDisplayValueChecked(cellValue as any, rowIndex);
        }

        switch (validator.validate) {
          case "required":
            newMessages = validateRequired(validator, cellValue);
            break;
          case "regex_match":
          case "regex_exclude":
            newMessages = validateRegex(validator, cellValue);
            break;
          case "length":
            newMessages = validateLength(validator, cellValue);
            break;
          case "unique_with":
            processUniqueWithForCell(validator, cellValue, rowUniqueWithDict);
            break;
          case "require_with":
          case "require_without":
          case "require_with_all":
          case "require_without_all":
          case "require_with_values":
          case "require_without_values":
          case "require_with_all_values":
          case "require_without_all_values":
            newMessages = validateRequireWith(
              validator,
              cellValue,
              data[rowIndex],
              columnMapping
            );
            break;
        }

        if (newMessages.length > 0) {
          appendTableMessagesForKey(newTableMessages, newMessages, key);
        }
      });
    });

    processUniqueWithForRow(globalUniqueWithDict, rowUniqueWithDict, rowIndex);
  });

  validateUniqueWith(
    uniqueWithValidators,
    globalUniqueWithDict,
    newTableMessages
  );

  return newTableMessages;
}

export function validateUnique(
  validator: IUniqueValidator,
  field: IAbstractField,
  colIndex: number,
  colData: unknown[],
  newTableMessages: INewTableMessages,
  transformErrorCells: Set<string>
): void {
  const valueOccurances = new MapWithDefault<unknown, Set<string>>(
    () => new Set()
  );

  // For unknown reasons (possibly adding/deleting/copying rows in review modal)
  // sometimes this can be undefined, so don't loop over it
  if (colData === undefined) return;

  colData.forEach((cellValue, rowIndex) => {
    if (cellIsEmpty(cellValue)) return;

    const key = `${rowIndex},${colIndex}`;

    if (!transformErrorCells.has(key))
      cellValue = field.getDisplayValue(cellValue, rowIndex);

    if (
      validator.validate === "unique_case_insensitive" &&
      typeof cellValue === "string"
    ) {
      cellValue = cellValue.toLowerCase();
    }

    valueOccurances.get(cellValue).add(key);
  });

  const newMessage = getValidatorMessage(validator, "Value must be unique");

  valueOccurances.forEach((keys) => {
    if (keys.size > 1) {
      keys.forEach((key) =>
        appendTableMessagesForKey(newTableMessages, [newMessage], key)
      );
    }
  });
}

export function validateRequired(
  validator: IRequiredValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (cellIsEmpty(cellValue)) {
    return [getValidatorMessage(validator, "Required")];
  } else {
    return [];
  }
}

export function validateRegex(
  validator: IRegexValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (typeof cellValue !== "string" || cellValue === "") return [];

  const defaultMessage =
    validator.validate === "regex_match"
      ? "Does not match regex"
      : "Must exclude regex";

  let regexOptions = "";

  if (validator.regexOptions?.ignoreCase) regexOptions += "i";
  if (validator.regexOptions?.dotAll) regexOptions += "s";
  if (validator.regexOptions?.multiline) regexOptions += "m";
  if (validator.regexOptions?.unicode) regexOptions += "u";

  const cellMatches = new RegExp(validator.regex, regexOptions).test(cellValue);
  if (
    (!cellMatches && validator.validate === "regex_match") ||
    (cellMatches && validator.validate === "regex_exclude")
  ) {
    return [getValidatorMessage(validator, defaultMessage)];
  } else {
    return [];
  }
}

export function validateLength(
  validator: ILengthValidator,
  cellValue: unknown
): ITableMessageInternal[] {
  if (typeof cellValue !== "string" || cellValue === "") return [];

  if (validator.min !== undefined && validator.max !== undefined) {
    return cellValue.length < validator.min || cellValue.length > validator.max
      ? [
          getValidatorMessage(
            validator,
            `Length must be at least ${validator.min} and at most ${validator.max}`
          ),
        ]
      : [];
  } else if (validator.min !== undefined) {
    return cellValue.length < validator.min
      ? [
          getValidatorMessage(
            validator,
            `Length must be at least ${validator.min}`
          ),
        ]
      : [];
  } else {
    return cellValue.length > validator.max!
      ? [
          getValidatorMessage(
            validator,
            `Length must be at most ${validator.max}`
          ),
        ]
      : [];
  }
}

function processUniqueWithForCell(
  validator: IUniqueWithValidator,
  cellValue: unknown,
  rowUniqueWithDict: MapWithDefault<string, unknown[]>
): void {
  rowUniqueWithDict.get(validator.uniqueKey).push(cellValue);
}

function processUniqueWithForRow(
  globalDict: IUniqueWithDict,
  rowDict: MapWithDefault<string, string[]>,
  rowIndex: number
): void {
  rowDict.forEach((valueArray, uniqueKey) => {
    const valueStr = valueArray.join(";;");
    globalDict.get(uniqueKey).get(valueStr).add(rowIndex);
  });
}

function validateUniqueWith(
  uniqueWithColumnMap: MapWithDefault<
    string,
    Set<[number, IUniqueWithValidator]>
  >,
  globalDict: IUniqueWithDict,
  newTableMessages: INewTableMessages
): void {
  // for each unique key
  globalDict.forEach((valueMap, uniqueKey) => {
    // for each unique set of values for that key
    valueMap.forEach((rowIndexes) => {
      // if only one row has that set of values, validation passes
      if (rowIndexes.size <= 1) return;

      // otherwise add an error to each cell
      rowIndexes.forEach((rowIndex) => {
        uniqueWithColumnMap.get(uniqueKey)!.forEach(([colIndex, validator]) => {
          const key = `${rowIndex},${colIndex}`;
          const message = getValidatorMessage(
            validator,
            `Values are not unique for ${validator.uniqueKey}`
          );
          appendTableMessagesForKey(newTableMessages, [message], key);
        });
      });
    });
  });
}

export const requireWithValidators = [
  "require_with",
  "require_without",
  "require_with_all",
  "require_without_all",
];

export const requireWithValuesValidators = [
  "require_with_values",
  "require_without_values",
  "require_with_all_values",
  "require_without_all_values",
];

// helper function to assert between IRequireWithValidator / IRequireWithValueValidator
function isRequireWithValidator(
  validator: IValidatorField
): validator is IRequireWithValidator {
  return requireWithValidators.includes(validator.validate);
}

function requireWithErrorMessage(
  validator: IRequireWithValidator | IRequireWithValuesValidator
): string {
  if (validator.errorMessage) return validator.errorMessage;

  let fields: string;
  if (isRequireWithValidator(validator)) {
    fields = validator.fields.join(", ");
  } else {
    fields = Object.entries(validator.fieldValues)
      .map(([key, value]) => `${key} = ${value}`)
      .join(", ");
  }

  switch (validator.validate) {
    case "require_with":
      return `Field must be present if any of these fields are filled: ${fields}`;
    case "require_without":
      return `Field must not be empty if any of these fields are empty: ${fields}`;
    case "require_with_all":
      return `Field must not be empty if all of these fields are present: ${fields}`;
    case "require_without_all":
      return `Field must not be empty if all of these fields are empty: ${fields}`;
    case "require_with_values":
      return `Field must be present if any of these fields are filled with the following values: ${fields}`;
    case "require_without_values":
      return `Field must be present if any of these fields are present and NOT filled with the following values: ${fields}`;
    case "require_with_all_values":
      return `Field must be present if all of these fields are filled with the following values: ${fields}`;
    case "require_without_all_values":
      return `Field must be present if all of these fields are present and NOT filled with the following values: ${fields}`;
  }
}

export function validateRequireWith(
  validator: IRequireWithValidator | IRequireWithValuesValidator,
  cellValue: unknown,
  rowData: unknown[],
  columnMapping: Map<number, IDeveloperField>
): ITableMessageInternal[] {
  // if we have a cellValue, then the required validation passes
  if (!cellIsEmpty(cellValue)) return [];

  let otherKeys: string[];
  const valuesAtKeysMatch = (key: string): boolean =>
    // eslint-disable-next-line eqeqeq
    rowValuesObj[key] ==
    (validator as IRequireWithValuesValidator).fieldValues[key];

  if (isRequireWithValidator(validator)) {
    // guard against invalid validator
    if (validator.fields == null) return [];

    otherKeys = validator.fields;
  } else {
    // guard against invalid validator
    if (validator.fieldValues == null) return [];

    otherKeys = Object.keys(validator.fieldValues);
  }

  let validateFn: (keys: string[]) => boolean;
  switch (validator.validate) {
    case "require_with":
      validateFn = () => rowValuesArray.every(cellIsEmpty);
      break;
    case "require_with_values":
      validateFn = (keys) => !keys.some(valuesAtKeysMatch);
      break;
    case "require_without":
      validateFn = () => !rowValuesArray.some(cellIsEmpty);
      break;
    case "require_without_values":
      // this one is weird
      validateFn = (keys) =>
        keys.every(
          (key) =>
            cellIsEmpty(rowValuesObj[key]) ||
            rowValuesObj[key] === validator.fieldValues[key]
        );
      break;
    case "require_with_all":
      validateFn = () => rowValuesArray.some(cellIsEmpty);
      break;
    case "require_with_all_values":
      validateFn = (keys) => !keys.every(valuesAtKeysMatch);
      break;
    case "require_without_all":
      validateFn = () => !rowValuesArray.every(cellIsEmpty);
      break;
    case "require_without_all_values":
      validateFn = (keys) => keys.some(valuesAtKeysMatch);
      break;
  }

  const rowValuesArray = getValuesArrayForFieldKeys(
    otherKeys,
    columnMapping,
    rowData
  );
  const rowValuesObj = getValuesObjForFieldKeys(
    otherKeys,
    columnMapping,
    rowData
  );
  const isValid = validateFn(otherKeys);

  if (!isValid) {
    return [getValidatorMessage(validator, requireWithErrorMessage(validator))];
  } else {
    return [];
  }
}

function getValidatorMessage(
  validator: IValidatorField,
  defaultMessage: string
): ITableMessageInternal {
  return {
    message: validator.errorMessage || defaultMessage,
    level: validator.level || "error",
    type: "validation",
  };
}

function getValuesArrayForFieldKeys(
  fields: string[],
  columnMapping: Map<number, IDeveloperField>,
  rowData: unknown[]
): unknown[] {
  const values: unknown[] = [];

  columnMapping.forEach((field, colIndex) => {
    if (fields.includes(field.key)) {
      values.push(rowData[colIndex]);
    }
  });

  return values;
}

function getValuesObjForFieldKeys(
  fields: string[],
  columnMapping: Map<number, IDeveloperField>,
  rowData: unknown[]
): { [key: string]: unknown } {
  // map of columnKey -> columnIndex
  const fieldColIndexes: { [key: string]: number } = {};

  columnMapping.forEach((field, colIndex: number) => {
    if (!field.manyToOne && fields.includes(field.key)) {
      fieldColIndexes[field.key] = colIndex;
    }
  });

  return Object.fromEntries(
    fields.map((f) => [f, rowData[fieldColIndexes[f]]])
  );
}
