import React, { useEffect, useCallback } from "react";
import { FileWithPath } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { ReactComponent as ManualInputIcon } from "../../assets/manual-input.svg";
import { ReactComponent as TemplateDownloadIcon } from "../../assets/template-download.svg";
import { AlertModalRedesign, ErrorModalRedesign } from "../AlertModal";
import { SelectSheetModalRedesign } from "../SelectSheetModal";
import HelpText from "../HelpTextRedesign";
import { FileSelect } from "./FileSelectRedesign";

import { UIComponentProps } from ".";
import { Button } from "../commonComponents/Button";
import Text from "../commonComponents/Text";
import Modal from "../commonComponents/Modal";
import { useAppSelector, useSettings, useAppDispatch } from "../../store/hooks";
import { setData } from "../../store/reducers/coredata";

export function DataUploadRedesign({
  showErrorModal,
  setShowErrorModal,
  showSelectSheetModal,
  manualInputOnly,
  manualInputDisabled,
  templateDownloadFilename,
  stepSettings,
  onTemplateDownloadClick,
  onSelectSheet,
  processSelectedLocalFile,
  advanceToReview,
  initialized,
  showMaxRecordsAlert,
  onCloseMaxRecordsAlert,
}: UIComponentProps) {
  const { t } = useTranslation();
  const { title, importIdentifier: type } = useSettings();
  const { maxRecords } = useAppSelector((state) => ({
    maxRecords: state.settings.maxRecords,
  }));
  const dispatch = useAppDispatch();

  const setDataAndAdvanceToReview = useCallback(() => {
    dispatch(
      setData({
        previewData: [],
        rawPreviewData: [],
        uploadType: "MANUAL_INPUT",
      })
    );
    advanceToReview();
  }, [dispatch, advanceToReview]);

  useEffect(() => {
    // Wait to advance to review until we've finished initializing settings/data
    // If we have initialData, the thunk that sets initialData advances to review automatically
    if (manualInputOnly && initialized) {
      setDataAndAdvanceToReview();
    }
  }, [manualInputOnly, setDataAndAdvanceToReview, initialized]);

  return (
    <>
      <ErrorModalRedesign
        show={showErrorModal}
        onClose={() => setShowErrorModal(false)}
      />

      <SelectSheetModalRedesign
        show={showSelectSheetModal}
        onHide={() => onSelectSheet()}
      />

      <AlertModalRedesign
        show={showMaxRecordsAlert}
        setShow={onCloseMaxRecordsAlert}
        onPrimaryButtonClick={onCloseMaxRecordsAlert}
        primaryButtonText="Ok"
        message={t("validations.maxRecordsExceeded", { maxRecords })}
        showSecondaryButton={false}
        data-cy="max-records-disclaimer"
        secondaryButtonText=""
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
      />

      <Modal data-cy="DataUploadModal" step="upload">
        <header className="mb-4">
          <Text type="h1" className="mb-2" data-cy="data-upload-title">
            {title || `${t("modalHeader.titleStarter")} ${type}`}
          </Text>
          {stepSettings.helpText && (
            <HelpText content={stepSettings.helpText} />
          )}
        </header>

        <div>
          {!manualInputOnly && (
            <FileSelect
              onFileSelected={(file: FileWithPath) => {
                processSelectedLocalFile(file);
              }}
              shouldShowTemplateDownloadLink={templateDownloadFilename !== null}
              onTemplateDownloadClick={onTemplateDownloadClick}
            />
          )}

          {(!manualInputDisabled || templateDownloadFilename) &&
            !manualInputOnly && (
              <div className="flex items-center justify-center my-8 mx-auto max-w-sm">
                <div className="flex-1 h-px bg-ice-300" />
                <Text type="body" className="px-4 text-ice-500">
                  {t("dataUploadModal.separator")}
                </Text>
                <div className="flex-1 h-px bg-ice-300" />
              </div>
            )}
        </div>

        <div className="flex flex-row justify-center items-center gap-4 !mx-8 !mb-2 sm:!mx-16 sm:!mb-5 md:!mx-32 md:!mb-10">
          {!manualInputDisabled && (
            <Button
              onClick={setDataAndAdvanceToReview}
              theme="secondary"
              className="gap-4 bg-gray-50"
              data-cy="manual-entry-button"
            >
              <ManualInputIcon className="h-6 w-6" />
              <span>{t("dataUploadModal.manualEntryButton.enterData")}</span>
            </Button>
          )}
          {templateDownloadFilename && (
            <Button
              onClick={onTemplateDownloadClick}
              theme="secondary"
              className="gap-4 bg-gray-50"
            >
              <TemplateDownloadIcon className="h-6 w-6" />
              <span>{t("dataUploadModal.downloadTemplateButton")}</span>
            </Button>
          )}
        </div>
      </Modal>
    </>
  );
}
