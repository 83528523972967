import * as React from "react";

import "bootstrap/dist/css/bootstrap.css";
import Table from "react-bootstrap/Table";
import { IDeveloperSelectOption, ISelectField } from "../../interfaces";
import Select from "react-select";
import { RootState } from "../../store/reducers";
import { connect, ConnectedProps } from "react-redux";
import { selectUniqueValsInColumn } from "../../store/reducers/coredata";
import { WithTranslation, withTranslation } from "react-i18next";
import { fuzzyMatchSelectOptions } from "../../thunks/column_matching";
import {
  selectFieldSpecAtColIndex,
  updateSelectFieldMapping,
} from "../../store/reducers/fields";

interface IColumnMatchModalSelectFieldProps {
  columnIndex: number;
}

const mapState = (
  state: RootState,
  ownProps: IColumnMatchModalSelectFieldProps
) => {
  return {
    selectFieldMapping: state.fields.selectFieldMapping,
    field: selectFieldSpecAtColIndex(
      state.fields,
      ownProps.columnIndex
    ) as ISelectField,
    uniqueValuesInColumn: selectUniqueValsInColumn(state.coredata.data).get(
      ownProps.columnIndex
    ),
  };
};

const mapDispatch = {
  fuzzyMatchSelectOptions,
  updateSelectFieldMapping,
};

const reduxConnector = connect(mapState, mapDispatch);
type IColumnMatchModalSelectFieldFullProps = IColumnMatchModalSelectFieldProps &
  ConnectedProps<typeof reduxConnector> &
  WithTranslation;

interface IColumnMatchModalSelectFieldState {
  selectOptions: { label: string; value: string }[];
}

class ColumnMatchModalSelectField extends React.Component<
  IColumnMatchModalSelectFieldFullProps,
  IColumnMatchModalSelectFieldState
> {
  componentDidMount() {
    // We debounce this otherwise it overwrite it between "Confirm Mapping" and "Edit"
    if (!this.props.selectFieldMapping.has(this.props.columnIndex)) {
      this.props.fuzzyMatchSelectOptions(this.props.columnIndex);
    }
  }

  componentDidUpdate(prevProps: IColumnMatchModalSelectFieldFullProps) {
    if (this.props.field?.key !== prevProps.field?.key) {
      this.props.fuzzyMatchSelectOptions(this.props.columnIndex);
    }
  }

  render() {
    if (!this.props.uniqueValuesInColumn)
      return (
        <div>{this.props.t("dataCleaningModal.selectField.tooManyValues")}</div>
      );

    const selectFieldTableRows = Array.from(
      this.props.uniqueValuesInColumn
    ).map((uniqueColumnValue, index) => {
      const value = this.props.selectFieldMapping
        .get(this.props.columnIndex)
        ?.get(uniqueColumnValue);

      return (
        <tr key={index}>
          <td style={{ verticalAlign: "middle !important", padding: "0 10px" }}>
            {uniqueColumnValue}
          </td>
          <td>
            <Select
              styles={{
                control: (base: any) => ({
                  ...base,
                  borderWidth: 0,
                }),
                menu: (base: any) => ({
                  ...base,
                  borderWidth: 0,
                }),
                menuList: (base: any) => ({
                  ...base,
                  borderWidth: 0,
                }),
              }}
              isClearable
              onChange={(newValue: any) =>
                this.props.updateSelectFieldMapping(
                  this.props.columnIndex,
                  uniqueColumnValue,
                  newValue
                )
              }
              options={this.props.field?.selectOptions}
              value={value}
              getOptionValue={(option: IDeveloperSelectOption) => option.value}
              getOptionLabel={(option: IDeveloperSelectOption) => option.label}
            />
          </td>
        </tr>
      );
    });

    return (
      <Table className="columnMatchModalCardTable" size="sm" bordered>
        <tbody>{selectFieldTableRows}</tbody>
      </Table>
    );
  }
}

export default withTranslation()(reduxConnector(ColumnMatchModalSelectField));
