import React, { createRef, useCallback } from "react";
import { Card, Col, Table } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import type { OptionsOrGroups, GroupBase } from "react-select";
import {
  ExclamationTriangleFill,
  InfoCircleFill,
  CheckLg,
} from "react-bootstrap-icons";
import { useTranslation, Trans } from "react-i18next";
import Select from "react-select";

import { useAppSelector, useAppDispatch } from "../../store/hooks";
import {
  selectFieldSpecAtColIndex,
  selectMatchableFieldSpecs,
  mapColumn,
  unmapColumn,
  setColumnConfirmed,
  setColumnIgnored,
} from "../../store/reducers/fields";
import { IDeveloperField } from "../../interfaces";

import {
  alphaColumnIndex,
  selectDuplicateMatchesForCol,
  selectHeaderNameForCol,
} from "../ColumnMatch/ui_helpers";
import { selectUniqueValsInColumn } from "../../store/reducers/coredata";
import ColumnMatchModalSelectField from "./ColumnMatchModalSelectField";
import SecondaryText from "../styledComponents/SecondaryText";
import StyledPrimaryButton from "../styledComponents/StyledPrimaryButton";
import StyledSecondaryButton from "../styledComponents/StyledSecondaryButton";
import StyledTertiaryButton from "../styledComponents/StyledTertiaryButton";
import type { TFunction } from "i18next";
import PrimaryText from "../styledComponents/PrimaryText";
import { RootState } from "../../store/reducers";

type TFieldSelectOption =
  | { type: "matchField"; field: IDeveloperField }
  | { type: "suggestedCustom"; header: string }
  | { type: "helpText"; text: string };

const selectStyles = {
  control: (base: any) => ({
    ...base,
    borderWidth: 0,
  }),
  menu: (base: any) => ({
    ...base,
    borderWidth: 0,
  }),
  menuList: (base: any) => ({
    ...base,
    borderWidth: 0,
  }),
};

const selectAreAllSelectValuesMapped = (
  state: RootState,
  columnIndex: number
): boolean => {
  const uniqueValues = selectUniqueValsInColumn(state.coredata.data).get(
    columnIndex
  );
  const selectFieldMapping = state.fields.selectFieldMapping.get(columnIndex);

  let areMapped = true;
  // eslint-disable-next-line no-unused-expressions
  uniqueValues?.forEach((val: string) => {
    if (selectFieldMapping && selectFieldMapping.has(val)) {
      areMapped = false;
    }
  });

  return areMapped;
};

const ColumnDataTable: React.FC<{ columnData: any[] }> = ({ columnData }) => {
  return (
    <tbody>
      {columnData.map((cellValue: any, index: number) => {
        return (
          <tr key={index}>
            <td style={{ textAlign: "center", backgroundColor: "#f5f8fa" }}>
              {index + 1}
            </td>
            <td colSpan={2} style={{ padding: "6px" }}>
              {`${cellValue}`}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const MatchedToText: React.FC<{ t: TFunction; fieldName: string }> = ({
  t,
  fieldName,
}) => {
  return (
    <PrimaryText>
      <Trans t={t} i18nKey="v1.columnMatchModalCard.matchedTo">
        Matched to
        <span
          style={{
            display: "inline-block",
            borderRadius: "4px",
            backgroundColor: "#dbe5f0",
            padding: "0 5px",
          }}
        >
          {{ fieldName }}
        </span>{" "}
      </Trans>
    </PrimaryText>
  );
};

const isOptionDisabled = (option: TFieldSelectOption) =>
  option.type === "helpText";

const FieldSelectWithCustom: React.FC<{
  colIndex: number;
  onMatchField: (field: IDeveloperField) => void;
  onMatchCustom: (name: string) => void;
  onClear: () => void;
  selectRef: React.RefObject<any>;
}> = ({ colIndex, onMatchField, onMatchCustom, onClear, selectRef }) => {
  const { selectedField, matchableFields, header, headerName } = useAppSelector(
    (state) => ({
      selectedField: selectFieldSpecAtColIndex(state.fields, colIndex),
      matchableFields: selectMatchableFieldSpecs(state.fields),
      header: state.coredata.headers?.[colIndex],
      headerName: selectHeaderNameForCol(state, colIndex),
    })
  );

  let options: OptionsOrGroups<
    TFieldSelectOption,
    GroupBase<TFieldSelectOption>
  >;
  options = matchableFields.map((field) => {
    return { type: "matchField", field };
  });

  const handleChange = (change: TFieldSelectOption | null) => {
    if (change === null) {
      onClear();
    } else {
      switch (change.type) {
        case "matchField":
          onMatchField(change.field);
          break;
        case "suggestedCustom":
          onMatchCustom(change.header);
          break;
        case "helpText":
          // not possible to get here
          throw new Error("User selected helpText option");
      }
    }
  };

  const getCustomValue = (option: TFieldSelectOption) => {
    switch (option.type) {
      case "matchField":
        return `field:${option.field.key}`;
      case "suggestedCustom":
        return `custom:${headerName}`;
      case "helpText":
        return option.text;
    }
  };

  const getCustomLabel = (
    option:
      | TFieldSelectOption
      | { value: string; label: string; __isNew__: true }
  ) => {
    if ("__isNew__" in option) {
      return option.label;
    }

    switch (option.type) {
      case "matchField":
        return option.field.label;
      case "suggestedCustom": {
        return `Add custom: "${headerName}"`;
      }
      case "helpText":
        return option.text;
    }
  };

  if (header) {
    options = [
      ...options,
      {
        label: "—",
        options: [
          {
            type: "suggestedCustom",
            header,
          },
          {
            type: "helpText",
            text: "or start typing to enter your own",
          },
        ],
      },
    ];
  } else {
    options = [
      ...options,
      {
        label: "—",
        options: [
          {
            type: "helpText",
            text: "Start typing to enter your own",
          },
        ],
      },
    ];
  }

  let selectedValue: TFieldSelectOption | null = null;
  if (selectedField) {
    selectedValue = { type: "matchField", field: selectedField };
  }

  return (
    <CreatableSelect
      ref={selectRef}
      styles={selectStyles}
      isClearable
      onChange={handleChange}
      onCreateOption={onMatchCustom}
      formatCreateLabel={(value) => `Add custom: "${value}"`}
      options={options}
      value={selectedValue}
      getOptionValue={getCustomValue}
      getOptionLabel={getCustomLabel}
      isOptionDisabled={isOptionDisabled}
    />
  );
};

export default function ColumnMatchCardLegacy({
  columnIndex,
  columnData,
}: {
  columnIndex: number;
  columnData: any[][];
}) {
  const customSelectRef = createRef<any>();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const {
    field,
    matchableFields,
    isConfirmed,
    isIgnored,
    header,
    allowCustom,
    isAutoMapped,
    duplicateMatches,
    areAllSelectValuesMatched,
    percentHasValue,
    suggestedCustomHeader,
  } = useAppSelector((state) => ({
    field: selectFieldSpecAtColIndex(state.fields, columnIndex),
    matchableFields: selectMatchableFieldSpecs(state.fields),
    isConfirmed: state.fields.confirmedColumns.has(columnIndex),
    isIgnored: state.fields.ignoredColumns.has(columnIndex),
    isAutoMapped:
      state.fields.columnMapping.get(columnIndex)?.matchType === "AUTOMAP",
    header: state.coredata.headers?.[columnIndex],
    allowCustom: state.settings.allowCustomFields,
    duplicateMatches: selectDuplicateMatchesForCol(state, columnIndex),
    areAllSelectValuesMatched: selectAreAllSelectValuesMapped(
      state,
      columnIndex
    ),
    percentHasValue:
      state.coredata.data.percentHasValueInColumn?.get(columnIndex),
    suggestedCustomHeader: selectHeaderNameForCol(state, columnIndex),
  }));

  const isSelectField = field && field.type === "select";
  const condensedView = isConfirmed || isIgnored;

  const onClear = useCallback(
    () => dispatch(unmapColumn(columnIndex)),
    [dispatch, columnIndex]
  );

  const onMapField = useCallback(
    (fieldKey: string) => dispatch(mapColumn(columnIndex, fieldKey, "USER")),
    [dispatch, columnIndex]
  );

  const onMapCustom = useCallback(
    (name: string) => dispatch(mapColumn(columnIndex, name, "CUSTOM")),
    [dispatch, columnIndex]
  );

  const duplicateMatchMessage =
    duplicateMatches.size > 0
      ? `${Array.from(duplicateMatches).join(", ")} ${t(
          "columnMatchModal.alreadyMatched",
          { count: duplicateMatches.size }
        )} ${field?.label}`
      : null;

  const onClickIncludeCustom = () => {
    if (suggestedCustomHeader) {
      onMapCustom(suggestedCustomHeader);
    } else {
      customSelectRef.current?.focus();
    }
  };

  let rightColumnImportantText;
  if (isConfirmed) {
    rightColumnImportantText = t("v1.columnMatchModalCard.confirmedMapping");
  } else if (isIgnored) {
    rightColumnImportantText = t("v1.columnMatchModalCard.ignoredMapping");
  } else if (field) {
    rightColumnImportantText = (
      <>
        <CheckLg
          style={{
            color: "#28a745",
            fontSize: "20px",
            paddingRight: "5px",
          }}
        />
        <div style={{ verticalAlign: "middle", display: "inline-block" }}>
          <MatchedToText t={t} fieldName={field.label} />
        </div>
      </>
    );
  } else {
    rightColumnImportantText = (
      <PrimaryText>
        <ExclamationTriangleFill
          style={{ color: "#ffc107", fontSize: "20px", paddingRight: "5px" }}
        />
        <div style={{ verticalAlign: "middle", display: "inline-block" }}>
          {t("v1.columnMatchModalCard.unableMatch")}
        </div>
      </PrimaryText>
    );
  }

  return (
    <Card
      className={`columnMatchModalCard ${
        condensedView ? "" : "columnMatchModalCardMinHeight"
      }`}
      data-cy="ColumnMatchModalCard"
    >
      <Card.Body className="columnMatchModalCardBody">
        <Col>
          <Table
            className="columnMatchModalCardTable"
            size="sm"
            bordered
            responsive="sm"
          >
            <thead>
              <tr>
                <th
                  style={{
                    width: "48px",
                    textAlign: "center",
                    padding: "6px",
                    backgroundColor: "#f5f8fa",
                  }}
                >
                  {alphaColumnIndex(columnIndex)}
                </th>
                <th
                  style={{
                    verticalAlign: "middle !important",
                    padding: "6px",
                    backgroundColor: "#f5f8fa",
                  }}
                >
                  {header}
                </th>
                <th style={{ width: "45%" }}>
                  {isIgnored ? null : allowCustom ? (
                    <FieldSelectWithCustom
                      colIndex={columnIndex}
                      onMatchField={(field) => onMapField(field.key)}
                      onMatchCustom={onMapCustom}
                      onClear={onClear}
                      selectRef={customSelectRef}
                    />
                  ) : (
                    <Select
                      styles={selectStyles}
                      isClearable
                      onChange={(field) =>
                        field ? onMapField(field.key) : onClear()
                      }
                      options={matchableFields}
                      value={field}
                      getOptionValue={(option: IDeveloperField) => option.key}
                      getOptionLabel={(option: IDeveloperField) => option.label}
                    />
                  )}
                </th>
              </tr>
            </thead>
            {condensedView ? null : <ColumnDataTable columnData={columnData} />}
          </Table>
        </Col>
        {/* Select field mapping (in the middle) */}
        {condensedView || !isSelectField ? null : (
          <Col>
            <ColumnMatchModalSelectField columnIndex={columnIndex} />
          </Col>
        )}
        <Col>
          <ul className="columnMatchModalCardRightColumnList">
            <li
              style={
                condensedView ? { marginTop: "12px" } : { marginBottom: "6px" }
              }
            >
              <b>{rightColumnImportantText}</b>
            </li>
            {isAutoMapped && (
              <li>
                <InfoCircleFill
                  style={{
                    color: "#6c757d",
                    fontSize: "20px",
                    paddingRight: "5px",
                  }}
                />
                <em style={{ fontSize: "12px" }}>
                  {isIgnored
                    ? t("v1.columnMatchModalCard.automaticallyIgnored")
                    : t("v1.columnMatchModalCard.automaticallyMatched")}
                </em>
              </li>
            )}
            {condensedView ? null : (
              <li style={{ marginBottom: "6px" }}>
                <InfoCircleFill
                  style={{
                    color: "#6c757d",
                    fontSize: "20px",
                    paddingRight: "5px",
                  }}
                />
                <div
                  style={{
                    verticalAlign: "middle",
                    display: "inline-block",
                  }}
                >
                  <SecondaryText>
                    {`${percentHasValue}${t(
                      "v1.columnMatchModalCard.percentHaveValues"
                    )}`}
                  </SecondaryText>
                </div>
              </li>
            )}
            {condensedView || duplicateMatchMessage === null ? null : (
              <li style={{ marginBottom: "6px" }}>
                <ExclamationTriangleFill
                  style={{
                    color: "#ffc107",
                    fontSize: "20px",
                    paddingRight: "5px",
                  }}
                />
                {duplicateMatchMessage}
              </li>
            )}
            {!condensedView && isSelectField && areAllSelectValuesMatched ? (
              <li style={{ marginBottom: "6px" }}>
                <ExclamationTriangleFill
                  style={{
                    color: "#ffc107",
                    fontSize: "20px",
                    paddingRight: "5px",
                  }}
                />
                <SecondaryText>
                  {t("v1.columnMatchModal.selectFieldNotAllMappedWarning")}
                </SecondaryText>
              </li>
            ) : null}
          </ul>
          {condensedView ? (
            <StyledSecondaryButton
              onClick={() => {
                dispatch(setColumnConfirmed(columnIndex, false));
                dispatch(setColumnIgnored(columnIndex, false));
              }}
            >
              {t("common.edit")}
            </StyledSecondaryButton>
          ) : null}
          <div className="columnMatchModalCardRightColumnButtonDiv">
            {field && !isConfirmed ? (
              <StyledPrimaryButton
                style={{ fontSize: 12 }}
                onClick={() => dispatch(setColumnConfirmed(columnIndex, true))}
              >
                {t("v1.columnMatchModalCard.confirmMapping")}
              </StyledPrimaryButton>
            ) : null}
            {!condensedView ? (
              <StyledTertiaryButton
                style={{ fontSize: 12 }}
                onClick={() => dispatch(setColumnIgnored(columnIndex, true))}
              >
                {t("v1.columnMatchModalCard.ignoreColumn")}
              </StyledTertiaryButton>
            ) : null}
            {!condensedView && !field && allowCustom ? (
              <StyledTertiaryButton
                style={{ fontSize: 12 }}
                onClick={onClickIncludeCustom}
              >
                {t("v1.columnMatchModalCard.includeCustomField")}
              </StyledTertiaryButton>
            ) : null}
          </div>
        </Col>
      </Card.Body>
    </Card>
  );
}
