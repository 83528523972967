import * as Sentry from "@sentry/browser";
import { getTableDataFromInitialData } from "../helpers/CoreDataHelpers";
import { parseUnmappedData } from "../helpers/TableHelpers";
import { AppThunk } from "../store/configureStore";
import { setStage } from "../store/reducers/modals";
import { setData, setHeaders } from "../store/reducers/coredata";
import { EFileUploadStage } from "../constants/EFileUploadStage";
import { nextStepFromUpload } from "../helpers/nextStep";
import { HotChange } from "./data_actions";
import { setSelectedHandsonCell } from "../store/reducers/commonComponents";
import {
  getOriginalFields,
  setColumnMapping,
  ColumnMapping,
} from "../store/reducers/fields";
import { setModalStage } from "./modal_stage";
import {
  selectMappedFieldInstances,
  selectKeyToIndexMap,
} from "../store/selectors";
import { handleNumOfRows } from "./initialization";

export const initColumnMappings = (
  data?: Record<string, unknown>[]
): AppThunk<ColumnMapping> => {
  return (dispatch, getState) => {
    const state = getState();
    const fields = getOriginalFields(state.fields);

    const row = (data ?? [])[0] ?? {};

    const columnMappings: ColumnMapping = new Map();
    let colIdx = 0;

    fields.forEach((field) => {
      if (field.manyToOne) {
        const maybeVals = row[field.key];

        if (Array.isArray(maybeVals) && maybeVals.length > 0) {
          maybeVals.forEach(() => {
            columnMappings.set(colIdx, { key: field.key, matchType: "EXACT" });
            colIdx += 1;
          });
          return;
        }
      }

      columnMappings.set(colIdx, { key: field.key, matchType: "EXACT" });
      colIdx += 1;
    });

    dispatch(setColumnMapping(columnMappings));
    return columnMappings;
  };
};

export const setInitialData = (
  initialData: string[][] | Record<string, string | string[]>[] | null
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    if (initialData && initialData.length > 0) {
      const data = dispatch(handleNumOfRows(initialData));

      // if this is an array it is an unmapped data set
      if (Array.isArray(data[0])) {
        dispatch(setInitialUnmappedData(data as string[][]));
      } else {
        dispatch(setInitialMappedData(data as Record<string, string>[]));
      }
    }
  };
};

const setInitialMappedData = (
  initialData: Record<string, string>[]
): AppThunk => {
  return (dispatch, getState) => {
    dispatch(initColumnMappings(initialData ?? {}));
    const keyToIndexMap = selectKeyToIndexMap(getState());
    const initialTableData = getTableDataFromInitialData(
      initialData,
      keyToIndexMap
    );

    dispatch(
      setData({
        previewData: initialTableData,
        rawPreviewData: initialTableData,
        file: null,
        uploadType: "INITIAL_DATA_MAPPED",
        valCountsInColumn: null,
        percentHasValueInColumn: null,
      })
    );
    dispatch(setStage(EFileUploadStage.DATA_REVIEW));
  };
};

export const setInitialUnmappedData = (
  initialData: string[][]
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const { settings, coredata } = getState();
    const headerRow = settings.headerRowOverride ?? coredata.rawDataHeaderRow;
    // if headerRow is null it means that the developer wants us to allow the
    // user to go through the mapping flow

    if (headerRow === null) {
      dispatch(
        setData({
          previewData: initialData,
          rawPreviewData: initialData,
          file: null,
          uploadType: "INITIAL_DATA_UNMAPPED",
          valCountsInColumn: null,
          percentHasValueInColumn: null,
        })
      );
    } else {
      // if headerRow is initialized with -1, it means there are no headers, so
      // set header row to null
      const parsedData = parseUnmappedData(
        initialData,
        headerRow === -1 ? null : headerRow
      );
      dispatch(setHeaders(parsedData.headers, headerRow));
      dispatch(
        setData({
          file: null,
          uploadType: "INITIAL_DATA_UNMAPPED",
          previewData: parsedData.data,
          rawPreviewData: initialData,
          valCountsInColumn: parsedData.valCountsInColumn,
          percentHasValueInColumn: parsedData.percentHasValueInColumn,
        })
      );
    }

    await dispatch(nextStepFromUpload());
  };
};

/* Used by V1 only for handling manual input on the data upload step */
export const initializeForManualInput = (
  manualInputData: any[][],
  changes: HotChange[],
  selectedCell: number[] | null
): AppThunk => {
  return (dispatch, getState) => {
    let hasOnlyEmptyChanges = true;
    changes.forEach((change: any[]) => {
      const row = change[0];
      const col = change[1];
      const newValue = change[3];

      try {
        // If >20 preview, we need to add new rows
        if (manualInputData[row] == null) manualInputData[row] = [];

        if (newValue !== "") {
          hasOnlyEmptyChanges = false;
          manualInputData[row][col] = newValue;
        } else if (newValue === "") {
          manualInputData[row][col] = null;
        }
      } catch (err) {
        Sentry.withScope((scope) => {
          scope.setExtra("manualInputData", manualInputData);
          scope.setExtra("changes", changes);

          Sentry.captureException(err);
        });
      }
    });

    // This debounces if the user just double clicks a cell and doesn't enter
    // any values
    if (hasOnlyEmptyChanges) return;

    dispatch(initColumnMappings());

    const state = getState();
    const fields = selectMappedFieldInstances(state);
    const rowLength = Math.max(...state.fields.columnMapping.keys()) + 1;

    const dataWithoutEmptyRows: any[][] = [];
    manualInputData.forEach((row) => {
      const isEmpty = !row.some((i) => i != null);
      if (isEmpty) return;

      const newRow = [];
      // the empty rows will be sparse, so we need to operate explicitly on indexes
      for (let index = 0; index < rowLength; index++) {
        const value = row[index];
        newRow.push(
          value == null ? fields.get(index)?.getInitialValue() ?? "" : value
        );
      }

      dataWithoutEmptyRows.push(newRow);
    });

    dispatch(
      setData({
        previewData: dataWithoutEmptyRows,
        rawPreviewData: dataWithoutEmptyRows,
        uploadType: "MANUAL_INPUT",
      })
    );

    if (selectedCell !== null) dispatch(setSelectedHandsonCell(selectedCell));
    dispatch(setModalStage(EFileUploadStage.DATA_REVIEW));
  };
};
