import React, { useState, useMemo, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Text from "../commonComponents/Text";
import { FindAndReplaceOpts } from "../../thunks/data_actions";
import Input from "../commonComponents/Input";
import Select from "react-select";
import Button from "../commonComponents/Button";
import Toggle from "../commonComponents/Toggle";
import { useAppSelector, useStyles } from "../../store/hooks";
import { ReactComponent as CloseIcon } from "../../assets/remove.svg";
import { RootState } from "../../store/reducers";
import { useTranslation } from "react-i18next";
import { selectGlobalStyle } from "../../store/reducers/settings";
import {
  ManyToOneIndexEntry,
  selectKeyToIndexMap,
  selectOrderedSpecs,
} from "../../store/selectors";

interface SelectOption {
  value: string | null;
  label: string;
  manyToOneIndex: number | null;
  fieldKey: string | null;
}

interface IFindAndReplaceModalProps {
  handleFindAndReplace: (
    query: string,
    replacement: string,
    opts: FindAndReplaceOpts
  ) => void;
  onClose: () => void;
  show: boolean;
}

const selectColumnOptions = (state: RootState): SelectOption[] => {
  const options: SelectOption[] = [];
  const keyToIndexMap = selectKeyToIndexMap(state);
  const fields = selectOrderedSpecs(state);

  for (const field of fields) {
    if (field.hidden || field.readOnly || !keyToIndexMap.has(field.key))
      continue;

    if (field.manyToOne) {
      const indexEntry = keyToIndexMap.get(field.key) as ManyToOneIndexEntry;
      indexEntry.indexes.forEach((_, idx) => {
        const label = idx === 0 ? field.label : `${field.label} (${idx + 1})`;
        options.push({
          value: `${field.key}_${idx}`,
          label,
          manyToOneIndex: idx,
          fieldKey: field.key,
        });
      });
    } else {
      options.push({
        value: field.key,
        label: field.label,
        manyToOneIndex: null,
        fieldKey: field.key,
      });
    }
  }

  return options;
};

const FindAndReplaceModal: React.FC<IFindAndReplaceModalProps> = (props) => {
  const { t } = useTranslation();

  const allColumnsOption = useMemo(
    () => ({
      value: null,
      label: t("dataReviewModal.findAndReplace.allColumns"),
      manyToOneIndex: null,
      fieldKey: null,
    }),
    [t]
  );

  const columnOptions = useAppSelector(selectColumnOptions);
  useEffect(() => {
    columnOptions.unshift(allColumnsOption);
  }, [columnOptions, allColumnsOption]);

  const [query, setQuery] = useState("");
  const [replacement, setReplacement] = useState("");
  const [matchCase, setMatchCase] = useState(false);
  const [matchEntireCell, setMatchEntireCell] = useState(false);
  const [findColumn, setFindColumn] = useState<SelectOption>(allColumnsOption);

  const modalStyle = useAppSelector(selectGlobalStyle);
  const modalBodyStyle = useStyles((styles) => ({
    backgroundColor: styles.global.backgroundColor,
  }));
  const closeButtonStyle = useStyles((styles) => ({
    color: styles.global.primaryTextColor,
  }));

  const onConfirm = () => {
    props.handleFindAndReplace(query, replacement, {
      fullCell: matchEntireCell,
      caseSensitive: matchCase,
      field: findColumn.fieldKey,
      manyToOneIndex: findColumn.manyToOneIndex,
    });

    props.onClose();
  };

  return (
    <Modal
      show={props.show}
      className="redesign"
      onHide={props.onClose}
      centered
      backdrop="static"
      style={modalStyle}
    >
      <Modal.Body className="shadow rounded-lg p-6" style={modalBodyStyle}>
        <button
          onClick={props.onClose}
          className="absolute top-8 right-4 -translate-y-1/2 p-2 hover:bg-gray-100 rounded-lg"
          style={closeButtonStyle}
        >
          <CloseIcon className="w-5 h-5" />
        </button>
        <Text type="display" className="mb-4">
          {t("dataReviewModal.findAndReplace.title")}
        </Text>
        <div className="flex flex-col gap-4">
          <div>
            <Text type="medium" as="label" htmlFor="find-query">
              {t("dataReviewModal.findAndReplace.find")}
            </Text>
            <Input
              id="find-query"
              name="find-query"
              placeholder={t("dataReviewModal.findAndReplace.emptyCell")!}
              onChange={(newQuery) => setQuery(newQuery)}
              value={query}
            />
          </div>
          <div>
            <Text type="medium" as="label" htmlFor="replace-with">
              {t("dataReviewModal.findAndReplace.replaceWith")}
            </Text>
            <Input
              id="replace-with"
              name="replace-with"
              onChange={(newReplace) => setReplacement(newReplace)}
              value={replacement}
            />
          </div>
          <div>
            <Text type="medium">
              {t("dataReviewModal.findAndReplace.inColumn")}
            </Text>
            <Select
              className="py-2"
              options={columnOptions}
              value={findColumn}
              onChange={(newValue) => setFindColumn(newValue!)}
            />
          </div>
          <div className="flex justify-start gap-8">
            <Toggle
              label={t("dataReviewModal.findAndReplace.matchCase")}
              checked={matchCase}
              onChange={() => setMatchCase(!matchCase)}
            />
            <Toggle
              label={t("dataReviewModal.findAndReplace.matchEntireCell")}
              checked={matchEntireCell}
              onChange={() => setMatchEntireCell(!matchEntireCell)}
            />
          </div>
          <div className="flex gap-4 justify-end">
            <Button theme="secondary" onClick={props.onClose}>
              {t("common.cancel")}
            </Button>
            <Button theme="primary" onClick={onConfirm}>
              {t("dataReviewModal.findAndReplace.action")}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default FindAndReplaceModal;
