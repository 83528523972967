import tippy from "tippy.js";
import { IDeveloperField, ITableMessageInternal } from "../interfaces";

export default class TippyManager {
  tippys: Map<string, any>;

  constructor() {
    this.tippys = new Map<string, any>();
  }

  addTippys(
    fieldSpecs: Map<number, IDeveloperField>,
    tableMessages: Map<string, ITableMessageInternal[]>
  ): void {
    this._addTippysFromOutputFields(fieldSpecs);
    this._addTippys(tableMessages);
  }

  _addTippysFromOutputFields(fieldSpecs: Map<number, IDeveloperField>): void {
    const tippys: Map<string, ITableMessageInternal[]> = new Map<
      string,
      ITableMessageInternal[]
    >();

    fieldSpecs.forEach((field, colIndex) => {
      if (field.description) {
        const key = `column-header,${colIndex}`;
        tippys.set(key, [
          {
            type: "validation",
            message: field.description,
            level: "info",
          },
        ]);
      }
    });

    this._addTippys(tippys);
  }

  _addTippys(tippys: Map<string, ITableMessageInternal[]>): void {
    // key is r,c
    tippys.forEach((values: ITableMessageInternal[], key: string) => {
      const row = key.split(",")[0];
      const column = key.split(",")[1];
      const tippyClassName = `tippy-${row}-${column}`;
      const messages = new Set<string>([]);
      values.forEach((cellMessage: ITableMessageInternal) => {
        messages.add(cellMessage.message);
      });

      const tippyInstances = tippy(`.${tippyClassName}`, {
        content: `${Array.from(messages).join(", ")}`,
      });

      this.tippys.set(key, tippyInstances);
    });
  }

  deleteAllTippys(): void {
    this.tippys.forEach((values: any[]) => {
      values.forEach((tippy: any) => {
        tippy.destroy();
      });
    });
    this.tippys = new Map<string, any>();
  }
}
