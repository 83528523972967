import * as React from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store/reducers";

interface IStyledPrimaryButtonProps {
  onClick(event: any): void;
}

const mapState = (state: RootState) => {
  return {
    styleOverrides: state.settings.styleOverrides,
  };
};
const reduxConnector = connect(mapState, {});
type IStyledPrimaryButtonFullProps = IStyledPrimaryButtonProps &
  React.HTMLAttributes<HTMLDivElement> &
  ConnectedProps<typeof reduxConnector>;

interface IStyledPrimaryButtonState {
  isHovered: boolean;
}

class StyledPrimaryButton extends React.Component<
  IStyledPrimaryButtonFullProps,
  IStyledPrimaryButtonState
> {
  constructor(props: IStyledPrimaryButtonFullProps) {
    super(props);
    this.state = {
      isHovered: false,
    };
  }

  render() {
    const style = this.state.isHovered
      ? {
          ...this.props.style,
          color: this.props.styleOverrides.primaryButton.hoverTextColor,
          backgroundColor:
            this.props.styleOverrides.primaryButton.hoverBackgroundColor,
          border: this.props.styleOverrides.primaryButton.hoverBorder,
          borderRadius: this.props.styleOverrides.primaryButton.borderRadius,
        }
      : {
          ...this.props.style,
          color: this.props.styleOverrides.primaryButton.textColor,
          backgroundColor:
            this.props.styleOverrides.primaryButton.backgroundColor,
          border: this.props.styleOverrides.primaryButton.border,
          borderRadius: this.props.styleOverrides.primaryButton.borderRadius,
        };

    return (
      <Button
        type="button"
        style={style}
        className={this.props.className}
        onClick={this.props.onClick}
        onMouseOver={() => this.setState({ isHovered: true })}
        onMouseOut={() => this.setState({ isHovered: false })}
        onMouseUp={() => this.setState({ isHovered: false })}
        onMouseDown={() => this.setState({ isHovered: true })}
      >
        <strong>{this.props.children}</strong>
      </Button>
    );
  }
}

export default reduxConnector(StyledPrimaryButton);
