import React, { useEffect, useState, useCallback } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

import { EFileUploadStage } from "./constants/EFileUploadStage";
import { VERSION_TWO, MIN_WINDOW_WIDTH_PIXELS } from "./constants/constants";

import DataUploadModal from "./components/DataUpload";
import DataMetadataModal from "./components/DataMetadata";
import ColumnMatchModal from "./components/ColumnMatch";
import DataReviewModal from "./components/DataReview";
import SchemaFirstMatch from "./components/SchemaFirstMatch";
import { EnhanceData } from "./components/EnhanceData";
import {
  FatalErrorModal,
  TranslatedAlertModalRedesign,
} from "./components/AlertModal";
import ProcessingModal from "./components/commonComponents/ProcessingModal";

import { useParentConnectionContext } from "./components/ParentConnectionContext";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import { selectFatalError } from "./store/reducers/errors";
import { handleCancelModal } from "./thunks/parent_connection_handlers";

const DromoUploader: React.FC = () => {
  const connection = useParentConnectionContext();
  const dispatch = useAppDispatch();

  const isInitialized = useAppSelector((state) => state.coredata.initialized);
  const [windowSizeError, setWindowSizeError] = useState(false);

  const isV2 = useAppSelector(
    (state) => state.settings.version === VERSION_TWO
  );

  const fontOverrides = useAppSelector((state) => ({
    customFontURL: state.settings.styleOverrides.global.customFontURL,
    customFontFamily: state.settings.styleOverrides.global.customFontFamily,
  }));

  const fatalError = useAppSelector((state) => selectFatalError(state.errors));

  const modalStage = useAppSelector((state) => state.modals.stage);

  const matchToSchema = useAppSelector(
    (state) => state.settings.matchingStep.matchToSchema
  );

  useEffect(() => {
    if (isInitialized) {
      if (isV2) {
        document.body.classList.add("redesign");
      }

      if (
        fontOverrides.customFontURL !== null &&
        fontOverrides.customFontFamily !== null
      ) {
        const font = document.createElement("link");
        font.setAttribute("href", fontOverrides.customFontURL);
        font.setAttribute("rel", "stylesheet");
        document.head.appendChild(font);
        document.body.style.fontFamily = fontOverrides.customFontFamily;
      }
    }
  }, [isInitialized, isV2, fontOverrides]);

  const onResize = useCallback(() => {
    setWindowSizeError(window.innerWidth < MIN_WINDOW_WIDTH_PIXELS);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  });

  if (isV2 && windowSizeError) {
    return (
      <TranslatedAlertModalRedesign
        show
        i18nMessage="alert.deviceChange"
        i18nPrimaryButtonText="common.close"
        i18nSecondaryButtonText=""
        showSecondaryButton={false}
        errorModal={true}
        onPrimaryButtonClick={() => dispatch(handleCancelModal(connection))}
        data-cy="window-size-modal"
      />
    );
  }

  if (fatalError) {
    return <FatalErrorModal eventId={fatalError.extra?.sentryEventId} />;
  }

  let modal: React.ReactNode;
  switch (modalStage) {
    case EFileUploadStage.DATA_UPLOAD:
      modal = <DataUploadModal />;
      break;
    case EFileUploadStage.DATA_METADATA:
      modal = <DataMetadataModal />;
      break;
    case EFileUploadStage.COLUMN_MATCH:
      if (matchToSchema) {
        modal = <SchemaFirstMatch />;
        break;
      }
      modal = <ColumnMatchModal />;
      break;
    case EFileUploadStage.AUTO_CLEAN_DATA:
      modal = <EnhanceData />;
      break;
    case EFileUploadStage.DATA_REVIEW: {
      modal = <DataReviewModal />;
      break;
    }
  }

  return (
    <>
      <ProcessingModal />
      {modal}
    </>
  );
};

export default DromoUploader;
