import { RootState } from "../store/reducers";
import { AppThunk } from "../store/configureStore";
import { EFileUploadStage } from "../constants/EFileUploadStage";
import {
  downloadFileToBuffer,
  downloadJson,
  uploadSavedProgressFileHelper,
  getAPIClient,
} from "../helpers/APIHelpers";
import { objectToFile } from "../helpers/CoreDataHelpers";
import {
  serializeFieldsWithSnapshots,
  deserializeFields,
  serializeSelectOptionOverrides,
  deserializeSelectOptionOverrides,
  serializeUserMessages,
  deserializeUserMessages,
  ISerializedFieldStateSnapshots,
  ISerializedSelectOptionOverrides,
  ISerializedUserMessages,
} from "../helpers/serialization";

import {
  IFieldsReducerState,
  setFieldState,
  setSnapshot,
} from "../store/reducers/fields";
import {
  RehydrateStage,
  setRehydrateStage,
  setRehydrateTempData,
  setStage,
} from "../store/reducers/modals";
import {
  setUploadId,
  setOriginalFilename,
  setSelectedSheet,
  setRawDataHeaderRow,
  setTableMessages,
  setCellTransformErrors,
  setSelectOptionOverrides,
} from "../store/reducers/coredata";
import {
  showProcessingModal,
  hideProcessingModal,
} from "../store/reducers/commonComponents";
import { selectMappedFieldInstances } from "../store/selectors";
import {
  processSelectedFile,
  parseSelectedSheet,
} from "../thunks/file_processing";
import {
  FullDataWithMeta,
  addTableMessage,
  runValidations,
} from "./data_actions";
import { ITableMessageInternal } from "../interfaces";
import { uploadCleanedFile } from "../headless/file_upload";
import { IField } from "../fields";

interface IAbstractRehydrateState<Stage extends RehydrateStage> {
  stage: Stage;
}

type WithoutStage<T extends IAbstractRehydrateState<any>> = Omit<T, "stage">;

export interface ISheetSelectionState
  extends IAbstractRehydrateState<"SHEET_SELECTION"> {
  uploadId: string;
}

export interface IHeaderSelectionState
  extends IAbstractRehydrateState<"HEADER_SELECTION"> {
  uploadId: string;
  selectedSheetId: string;
}

export interface IColumnMatchingState
  extends IAbstractRehydrateState<"COLUMN_MATCHING"> {
  uploadId: string;
  selectedSheetId: string;
  rawDataHeaderRow: number | null;
  fields: ISerializedFieldStateSnapshots;
}

export interface IDataReviewState
  extends IAbstractRehydrateState<"DATA_REVIEW"> {
  uploadId: string;
  selectedSheetId: string;
  rawDataHeaderRow: number | null;
  fields: ISerializedFieldStateSnapshots;
  savedProgressId: string;
  transformErrorCells: string[];
  userMessages: ISerializedUserMessages;
  selectOptionOverrides: ISerializedSelectOptionOverrides;
}

export type IRehydrateState =
  | ISheetSelectionState
  | IHeaderSelectionState
  | IColumnMatchingState
  | IDataReviewState;

export const dumpStateForRehydration = (
  stage: RehydrateStage,
  fullData?: FullDataWithMeta
): AppThunk<Promise<IRehydrateState>> => {
  return async (dispatch, getState) => {
    if (stage === "DATA_REVIEW") {
      return await dispatch(
        dumpStateForDataReview(fullData as FullDataWithMeta)
      );
    }

    let selector: (state: RootState) => IRehydrateState;

    switch (stage) {
      case "SHEET_SELECTION":
        selector = dumpStateForSheetSelection;
        break;
      case "HEADER_SELECTION":
        selector = dumpStateForHeaderSelection;
        break;
      case "COLUMN_MATCHING":
        selector = dumpStateForColumnMatching;
        break;
      default:
        throw new Error(`Unhandled rehydrate stage: ${stage}`);
    }

    return selector(getState());
  };
};

const dumpStateForSheetSelection = (state: RootState): ISheetSelectionState => {
  if (state.coredata.uploadId === null) {
    throw new Error("Can't dump state because no uploadId is set");
  }

  return {
    stage: "SHEET_SELECTION",
    uploadId: state.coredata.uploadId,
  };
};

const dumpStateForHeaderSelection = (
  state: RootState
): IHeaderSelectionState => {
  const sheetSelectionState = dumpStateForSheetSelection(state);

  if (state.coredata.selectedSheetId === null) {
    throw new Error("Can't dump state because no selectedSheetId is set");
  }

  return {
    ...sheetSelectionState,
    stage: "HEADER_SELECTION",
    selectedSheetId: state.coredata.selectedSheetId,
  };
};

const dumpStateForColumnMatching = (state: RootState): IColumnMatchingState => {
  const headerSelectionState = dumpStateForHeaderSelection(state);

  return {
    ...headerSelectionState,
    stage: "COLUMN_MATCHING",
    rawDataHeaderRow: state.coredata.rawDataHeaderRow,
    fields: serializeFieldsWithSnapshots(state.fields),
  };
};

let uploadProgressFile: (
  savedProgressFile: File,
  dataLength: number
) => AppThunk<Promise<{ uploadId: string }>>;
if (process.env.JS_PLATFORM === "headless") {
  uploadProgressFile = (savedProgressFile: File, dataLength: number) =>
    uploadCleanedFile(savedProgressFile, true, dataLength, null);
} else {
  uploadProgressFile = (savedProgressFile: File, dataLength: number) =>
    uploadSavedProgressFileHelper(savedProgressFile, dataLength);
}

const dumpStateForDataReview = (
  fullData: FullDataWithMeta
): AppThunk<Promise<IDataReviewState>> => {
  return async (dispatch, getState) => {
    // optimization opportunity - we don't need full data here, which includes
    // ignored and removed columns. we could filter to just mapped columns
    const savedProgressFile = objectToFile(
      fullData,
      "saved_progress.json",
      "json",
      true
    ) as File;

    const { uploadId } = await dispatch(
      uploadProgressFile(savedProgressFile, fullData.length)
    );

    const state = getState();
    const columnMatchingState = dumpStateForColumnMatching(state);

    return {
      ...columnMatchingState,
      stage: "DATA_REVIEW",
      savedProgressId: uploadId,
      transformErrorCells: [...state.coredata.transformErrorCells.values()],
      userMessages: serializeUserMessages(state.coredata.tableMessages),
      fields: serializeFieldsWithSnapshots(state.fields),
      selectOptionOverrides: serializeSelectOptionOverrides(
        state.coredata.selectOptionOverrides
      ),
    };
  };
};

export const rehydrate = (state: IRehydrateState): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    dispatch(showProcessingModal());
    dispatch(setRehydrateStage(state.stage));

    switch (state.stage) {
      case "SHEET_SELECTION":
        await dispatch(rehydrateSheetSelection(state));
        dispatch(setStage(EFileUploadStage.DATA_UPLOAD));
        break;
      case "HEADER_SELECTION":
        await dispatch(rehydrateHeaderSelection(state));
        dispatch(setStage(EFileUploadStage.DATA_METADATA));
        break;
      case "COLUMN_MATCHING":
        await dispatch(rehydrateColumnMatching(state));
        dispatch(setStage(EFileUploadStage.COLUMN_MATCH));
        break;
      case "DATA_REVIEW":
        await dispatch(rehydrateDataReview(state));
        dispatch(setStage(EFileUploadStage.DATA_REVIEW));
        break;
      default:
        assertNever(state);
    }

    dispatch(hideProcessingModal());
  };
};

const processFile = (
  state: WithoutStage<ISheetSelectionState>
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const appState = getState();
    const api = getAPIClient(appState);
    dispatch(setUploadId(state.uploadId));

    const rawUploadMetadata = await api.getRawUploadMetadata(state.uploadId);
    dispatch(setOriginalFilename(rawUploadMetadata.filename));

    const downloadUrl = await api.getRawDownloadUrl(state.uploadId);
    const fileBuffer = await downloadFileToBuffer(downloadUrl);
    const file = new File([fileBuffer], rawUploadMetadata.filename ?? "");
    await dispatch(processSelectedFile(file));
  };
};

const rehydrateSheetSelection = (
  state: WithoutStage<ISheetSelectionState>
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    await dispatch(processFile(state));
  };
};

const rehydrateHeaderSelection = (
  state: WithoutStage<IHeaderSelectionState>
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    await dispatch(rehydrateSheetSelection(state));
    dispatch(setSelectedSheet(state.selectedSheetId));
    await dispatch(parseSelectedSheet(true));
  };
};

const rehydrateColumnMatching = (
  state: WithoutStage<IColumnMatchingState>
): AppThunk<Promise<void>> => {
  return async (dispatch) => {
    await dispatch(processFile(state));
    dispatch(setSelectedSheet(state.selectedSheetId));
    dispatch(setRawDataHeaderRow(state.rawDataHeaderRow));
    await dispatch(parseSelectedSheet(true));
    dispatch(rehydrateFieldStateWithSnapshots(state.fields));
  };
};

const rehydrateFieldStateWithSnapshots = (
  fieldState: ISerializedFieldStateSnapshots
): AppThunk<IFieldsReducerState> => {
  return (dispatch) => {
    const { snapshots, ...serializedState } = fieldState;
    const currentState = deserializeFields(serializedState);

    dispatch(setFieldState(currentState));
    for (const [name, serializedState] of snapshots) {
      const deserialized = deserializeFields(serializedState);
      dispatch(setSnapshot(name, deserialized));
    }

    return currentState;
  };
};

const addTranformErrorsToUserMessages = (
  errorCells: Set<string>,
  userMessages: Map<string, ITableMessageInternal[]>,
  fieldInstances: Map<number, IField>
): Map<string, ITableMessageInternal[]> => {
  const newMessages = new Map([...userMessages.entries()]);
  errorCells.forEach((cellKey) => {
    const colIdx = parseInt(cellKey.split(",")[1]);
    const field = fieldInstances.get(colIdx)!;
    const message: ITableMessageInternal = {
      type: "field-transform",
      level: "error",
      message: field.invalidValueMessage,
    };

    addTableMessage(newMessages, cellKey, message);
  });
  return newMessages;
};

const rehydrateDataReview = (
  state: WithoutStage<IDataReviewState>
): AppThunk<Promise<void>> => {
  return async (dispatch, getState) => {
    const appState = getState();
    const api = getAPIClient(appState);
    // future optimization opportunity - when rehydrating here, we download
    // both the full original file, and the full data at the time the import
    // was saved. we could just download the save data, but then the original
    // file will need to be downloaded on-demand if the user goes backwards
    // from the review step.

    await dispatch(rehydrateColumnMatching(state));
    dispatch(rehydrateFieldStateWithSnapshots(state.fields));
    const errorCellsSet = new Set(state.transformErrorCells);
    dispatch(setCellTransformErrors(errorCellsSet));

    const userMessages = deserializeUserMessages(state.userMessages);
    const fieldInstances = selectMappedFieldInstances(getState());
    const tableMessages = addTranformErrorsToUserMessages(
      errorCellsSet,
      userMessages,
      fieldInstances
    );

    const selectOptionOverrides = deserializeSelectOptionOverrides(
      state.selectOptionOverrides
    );
    dispatch(setSelectOptionOverrides(selectOptionOverrides));

    dispatch(setTableMessages(tableMessages));

    const savedDataUrl = await api.getCleanedDownloadUrl(state.savedProgressId);
    const fullData = (await downloadJson(savedDataUrl)) as FullDataWithMeta;
    dispatch(runValidations(fullData));
    dispatch(setRehydrateTempData(fullData));
  };
};

const assertNever = (state: never): never => {
  throw new Error(`Unknown rehydration state: ${(state as any).stage}`);
};
