import React, { useEffect, useState } from "react";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";

// Handsontable
import "handsontable/dist/handsontable.full.css";

// Constants
import { EFileUploadStage } from "../../constants/EFileUploadStage";
import {
  ENCODINGS,
  CODEPAGES,
  METADATA_PREVIEW_MODAL_NUM_COLS,
  METADATA_PREVIEW_MODAL_NUM_ROWS,
  VERSION_TWO,
} from "../../constants/constants";

// Props
import { SingleValue } from "react-select";
import {
  setEncoding,
  resetStateBackDateUpload,
  setRawDataHeaderRow,
} from "../../store/reducers/coredata";
import {
  hideProcessingModal,
  showProcessingModal,
} from "../../store/reducers/commonComponents";
import { useTranslation } from "react-i18next";
import { InputGroup } from "react-bootstrap";
import { nextStepFromMetadata } from "../../helpers/nextStep";
import { parseSelectedSheet } from "../../thunks/file_processing";
import { setInitialUnmappedData } from "../../thunks/initial_data";
import { selectGlobalStyle } from "../../store/reducers/settings";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DataMetadataLegacy } from "./DataMetadataLegacy";
import { DataMetadataView } from "./DataMetadataView";
import { FileWithPath } from "react-dropzone";
import { setModalStage } from "../../thunks/modal_stage";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { handleCancelModal } from "../../thunks/parent_connection_handlers";

interface AlertModalState {
  message: string;
  show: boolean;
  action: "back" | "cancel";
}

const DataMetadataModal = () => {
  const connection = useParentConnectionContext();
  const [dataPreview, setDataPreview] = useState<string[][]>([]);
  const [headerSelectRow, setHeaderSelectRow] = useState<number | null>(null);
  const [alertModal, setAlertModal] = useState<AlertModalState>({
    message: "",
    show: false,
    action: "cancel",
  });
  const dispatch = useAppDispatch();
  const {
    data,
    encoding,
    globalStyle,
    browserExcelParsing,
    displayEncoding,
    version,
    matchToSchema,
    isRehydrated,
  } = useAppSelector((state) => ({
    data: state.coredata.data,
    encoding: state.coredata.encoding,
    globalStyle: selectGlobalStyle(state),
    browserExcelParsing: state.settings.browserExcelParsing,
    displayEncoding: state.settings.displayEncoding,
    version: state.settings.version,
    matchToSchema: state.settings.matchingStep.matchToSchema,
    isRehydrated: state.modals.rehydrateStage !== null,
  }));
  const { t } = useTranslation();

  const updateHeaderRow = async (row: number | null) => {
    dispatch(showProcessingModal());
    dispatch(setRawDataHeaderRow(row));

    if (data.uploadType === "INITIAL_DATA_UNMAPPED") {
      await dispatch(setInitialUnmappedData(data.rawPreviewData));
    } else {
      try {
        await dispatch(parseSelectedSheet(true));
      } catch (error) {
        dispatch(hideProcessingModal());
        setAlertModal({
          message: t("common.errorLocalFile"),
          show: true,
          action: "back",
        });
        return;
      }
    }

    dispatch(nextStepFromMetadata());
  };

  const changeEncoding = async (
    value: SingleValue<{ label: string; value: string }>
  ) => {
    // edge-case detected by sentry
    if (!value || !value.label) return;

    if (encoding?.toLowerCase() === value.value.toLowerCase()) return;

    dispatch(showProcessingModal());
    dispatch(setEncoding(value.value));
    await dispatch(parseSelectedSheet(true));
    dispatch(hideProcessingModal());
  };

  const loadDataPreview = (data: Array<Array<any>>) => {
    const dataPreview: any[] = [];
    for (
      let i = 0;
      i < Math.min(METADATA_PREVIEW_MODAL_NUM_ROWS, data.length);
      i++
    ) {
      dataPreview.push(
        data[i].slice(
          0,
          Math.min(METADATA_PREVIEW_MODAL_NUM_COLS, data[i].length)
        )
      );
    }

    setDataPreview(dataPreview);
  };

  useEffect(() => {
    async function handleStepHooks() {
      const { uploadType } = data;
      if (uploadType === "INITIAL_DATA_MAPPED" || uploadType === "MANUAL_INPUT")
        return;
      if (uploadType === "FILE" && data.file == null) return;

      loadDataPreview(data.rawPreviewData);
    }

    handleStepHooks();
  }, [connection, data, dispatch]);

  const getEncodingOptions = () => {
    if (browserExcelParsing) {
      return {
        options: CODEPAGES,
        selectedValue: CODEPAGES.find((cp) => cp.value === encoding),
      };
    } else {
      return {
        options: ENCODINGS.map((enc: string) => {
          return {
            label: enc,
            value: enc,
          };
        }),
        selectedValue: {
          label: encoding,
          value: encoding,
        },
      };
    }
  };

  const encodings = getEncodingOptions();

  let radioButtonComponents = null;
  if (headerSelectRow !== null) {
    const radioNodes = [];
    for (let i = 0; i < Math.min(dataPreview.length, 10); i++) {
      radioNodes.push(
        <InputGroup.Radio
          name="header-select"
          key={i}
          value={i}
          checked={headerSelectRow === i}
          onChange={() => setHeaderSelectRow(i)}
        />
      );
    }
    radioButtonComponents = (
      <InputGroup className="metadata-modal-header-select">
        {radioNodes}
      </InputGroup>
    );
  }

  const setShowAlert = (show: boolean) =>
    setAlertModal({
      message: "",
      show,
      action: "cancel",
    });

  const handleAlertPrimaryButtonClick = () => {
    if (alertModal.action === "back") {
      dispatch(setModalStage(EFileUploadStage.DATA_UPLOAD));
      dispatch(resetStateBackDateUpload());
    } else if (alertModal.action === "cancel") {
      dispatch(handleCancelModal(connection));
    }
  };

  const handleAlertSecondaryButtonClick = () =>
    setAlertModal({
      message: "",
      show: false,
      action: "cancel",
    });

  const handleCloseModal = () =>
    setAlertModal({
      message: t("common.clearAllProgressAlert"),
      show: true,
      action: "cancel",
    });

  const handleGoBack = () => {
    dispatch(setModalStage(EFileUploadStage.DATA_UPLOAD));
    dispatch(resetStateBackDateUpload());
  };

  const props = {
    alertModal,
    setShowAlert,
    handleAlertPrimaryButtonClick,
    handleAlertSecondaryButtonClick,
    globalStyle,
    handleCloseModal,
    headerSelectRow,
    updateHeaderRow,
    setHeaderSelectRow,
    dataPreview,
    encodings,
    radioButtonComponents,
    changeEncoding,
    data,
    displayEncoding,
    handleGoBack,
    matchToSchema,
    isRehydrated,
  };

  return version === VERSION_TWO ? (
    <DataMetadataView {...props} />
  ) : (
    <DataMetadataLegacy {...props} />
  );
};

export interface UIDataMetadataProps {
  alertModal: AlertModalState;
  setShowAlert: (show: boolean) => void;
  handleAlertPrimaryButtonClick: () => void;
  handleAlertSecondaryButtonClick: () => void;
  globalStyle: {
    [key: string]: string;
  };
  handleCloseModal: () => void;
  headerSelectRow: number | null;
  updateHeaderRow: (headerRow: number | null) => void;
  setHeaderSelectRow: (headerRow: number) => void;
  dataPreview: any[];
  encodings: {
    options: Array<{ label: string; value: string }>;
    selectedValue: { label: string; value: string } | undefined;
  };
  radioButtonComponents: any;
  changeEncoding: (
    value: SingleValue<{ label: string; value: string }>
  ) => void;
  data: {
    uploadType: string;
    file: string | FileWithPath | null;
    rawPreviewData: Array<Array<any>>;
  };
  displayEncoding: boolean;
  handleGoBack: () => void;
  matchToSchema: boolean;
  isRehydrated: boolean;
}

export default DataMetadataModal;
