import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./reducers";
import { fieldFromDeveloperField, IField } from "../fields";
import { IDeveloperField, ISelectField, IError } from "../interfaces";

export const selectMappedFieldInstances = createSelector(
  (state: RootState) => state.fields.fieldSpecs,
  (state: RootState) => state.fields.columnMapping,
  (state: RootState) => state.coredata.selectOptionOverrides,
  (fieldSpecs, columnMapping, selectOptionOverrides) => {
    const fieldInstances = new Map<number, IField>();

    columnMapping.forEach(({ key }, colIndex) => {
      const field = fieldFromDeveloperField(
        fieldSpecs.get(key)!,
        selectOptionOverrides.get(key)
      );
      fieldInstances.set(colIndex, field);
    });

    return fieldInstances;
  }
) as (state: RootState) => Map<number, IField>;

export const selectMappedSpecs = createSelector(
  (state: RootState) => state.fields.fieldSpecs,
  (state: RootState) => state.fields.columnMapping,
  (fieldSpecs, columnMapping) => {
    const specMap = new Map<number, IDeveloperField>();

    for (const [colIndex, { key }] of columnMapping.entries()) {
      specMap.set(colIndex, fieldSpecs.get(key)!);
    }

    return specMap;
  }
) as (state: RootState) => Map<number, IDeveloperField>;

export const selectVisibleMappedSpecs = (
  state: RootState
): Map<number, IDeveloperField> => {
  const mappedSpecs = selectMappedSpecs(state);

  return new Map([...mappedSpecs].filter(([_idx, spec]) => !spec.hidden));
};

export const selectMappedSelectSpecs = createSelector(
  selectMappedSpecs,
  (mappedSpecs) => {
    return new Map<number, ISelectField>(
      [...mappedSpecs].filter(([_colIndex, field]) => {
        return (
          (Array.isArray(field.type) ? field.type[0] : field.type) === "select"
        );
      }) as [number, ISelectField][]
    );
  }
);

export const selectOrderedSpecs = createSelector(
  (state: RootState) => state.fields.columnMapping,
  (state: RootState) => state.fields.fieldSpecs,
  (columnMapping, fieldSpecs) => {
    const orderedKeys = [...columnMapping]
      .sort(([idxA], [idxB]) => idxA - idxB)
      .map(([_, { key }]) => key);

    return orderedKeys.map((key) => fieldSpecs.get(key)!);
  }
);

export type ManyToOneIndexEntry = { manyToOne: true; indexes: number[] };
export type OneToOneIndexEntry = { manyToOne: false; index: number };
export type IndexEntry = OneToOneIndexEntry | ManyToOneIndexEntry;

export const selectKeyToIndexMap = createSelector(
  selectMappedSpecs,
  (mappedSpecs) => {
    const mapping = new Map<string, IndexEntry>();

    for (const [colIndex, field] of mappedSpecs) {
      if (field.manyToOne) {
        const entry: IndexEntry = (mapping.get(
          field.key
        ) as ManyToOneIndexEntry) ?? {
          manyToOne: true,
          indexes: [],
        };

        entry.indexes.push(colIndex);
        mapping.set(field.key, entry);
      } else {
        mapping.set(field.key, {
          manyToOne: false,
          index: colIndex,
        });
      }
    }

    return mapping;
  }
);

export const selectErrorsForExport = (state: RootState): IError[] => {
  const errors: IError[] = [];
  const colIdxToFieldKeyMap: Map<number, string> = new Map();
  const keyToIndexMap = selectKeyToIndexMap(state);
  const fieldSpecs = selectMappedSpecs(state);

  [...fieldSpecs.entries()]
    .sort(([colIdx1], [colIdx2]) => colIdx1 - colIdx2)
    .forEach(([fullDataColIdx, field]) => {
      colIdxToFieldKeyMap.set(fullDataColIdx, field.key);
    });

  state.coredata.tableMessages.forEach((messages, key) => {
    const errorMessages = messages
      .filter((m) => m.level === "error")
      .map((m) => m.message);

    const [rowNumber, colNumber] = key.split(",").map((n) => parseInt(n));
    const indexEntry = keyToIndexMap.get(colIdxToFieldKeyMap.get(colNumber)!);

    if (!indexEntry) return;

    errorMessages.forEach((message) => {
      const errorObj: IError = {
        fieldKey: colIdxToFieldKeyMap.get(colNumber)!,
        rowIndex: rowNumber,
        message,
      };

      if (indexEntry.manyToOne) {
        errorObj.manyToOneIndex = indexEntry.indexes.indexOf(colNumber);
      }
      errors.push(errorObj);
    });
  });

  return errors;
};
