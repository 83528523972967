export const DEFAULT_API_URL =
  process.env.REACT_APP_API_BASE ??
  (process.env.NODE_ENV === "production"
    ? "https://app.dromo.io"
    : "http://localhost:8000");

export const MANUAL_ENTRY_TABLE_NUM_ROWS = 20;
export const METADATA_PREVIEW_MODAL_NUM_ROWS = 10;
export const METADATA_PREVIEW_MODAL_NUM_COLS = 3;
export const COLUMN_MATCH_MODAL_PREVIEW_NUM_ROWS = 4;
export const MAX_UNIQUE_VALUES_SELECT_FIELD = 50;

export const MAX_AI_SELECT_OPTION_UNIQUES = 50;

export const MAX_UPLOAD_FILE_SIZE = 1073741824; // 1 GiB

export const DEVELOPMENT_MODE_RESULT_ROW_LIMIT = 100;
export const DEVELOPMENT_MODE_HEADLESS_LIMIT = 1;

export const HANDSONTABLE_LICENSE_KEY = "8dc30-360c6-96ee2-b4b3f-aed00";

export const VERSION_TWO = "v2";

export const ENCODINGS = [
  "ASCII",
  "UTF-8",
  "UTF-16",
  "windows-1252",
  "2143-ordered",
  "3412-ordered",
  "Big5",
  "EUC-JP",
  "EUC-KR",
  "EUC-TW",
  "GB18030",
  "GB2312",
  "HZ-GB-2312",
  "IBM855",
  "IBM866",
  "ISO-2022-CN",
  "ISO-2022-JP",
  "ISO-2022-KR",
  "ISO-8859-2",
  "ISO-8859-5",
  "ISO-8859-7",
  "ISO-8859-8",
  "KOI8-R",
  "MacCyrillic",
  "SHIFT_JIS",
  "TIS-620",
  "UTF-16BE",
  "UTF-16LE",
  "UTF-32BE",
  "UTF-32LE",
  "windows-1250",
  "windows-1251",
  "windows-1253",
  "windows-1255",
];

export const CODEPAGES = [
  { value: "855", label: "IBM855" },
  { value: "866", label: "IBM866" },
  { value: "950", label: "Big5" },
  { value: "1200", label: "UTF-16-LE" },
  { value: "1201", label: "UTF-16-BE" },
  { value: "1250", label: "Windows-1250" },
  { value: "1251", label: "Windows-1251" },
  { value: "1252", label: "Windows-1252" },
  { value: "1253", label: "Windows-1253" },
  { value: "1254", label: "Windows-1254" },
  { value: "1255", label: "Windows-1255" },
  { value: "10002", label: "MAC Traditional Chinese (Big5)" },
  { value: "10008", label: "GB2312" },
  { value: "12000", label: "UTF-32-LE" },
  { value: "12001", label: "UTF-32-BE" },
  { value: "20127", label: "ASCII" },
  { value: "28592", label: "ISO 8859-2" },
  { value: "28595", label: "ISO 8859-5" },
  { value: "28597", label: "ISO 8859-7" },
  { value: "28598", label: "ISO 8859-8" },
  { value: "28601", label: "TIS-620" },
  { value: "50220", label: "ISO-2022-JP" },
  { value: "50225", label: "ISO-2022-KR" },
  { value: "50227", label: "ISO-2022-CN" },
  { value: "51932", label: "EUC-JP" },
  { value: "51936", label: "EUC-TW" },
  { value: "51949", label: "EUC-KR" },
  { value: "52936", label: "HZ-GB-2312" },
  { value: "54936", label: "GB18030" },
  { value: "65000", label: "UTF-7" },
  { value: "65001", label: "UTF-8" },
];

export const serverFiletypes = new Set<string>(["xls", "xlsx", "xml"]);
export const localFiletypes = new Set<string>(["tsv", "csv"]);
export const allFiletypes = new Set([...serverFiletypes, ...localFiletypes]);

export const NUM_ROWS_FOR_PREVIEW = 30;

export const MIN_WINDOW_WIDTH_PIXELS = 780;

// The default fuzzy matching threshold of 0.6 is TOO fuzzy for column matching
// Lowing this to 0.4 should reduce annoying false postives.
// A mismatched column that's not noticed could be very problematic
// Experimenting with the FUSE demo: https://fusejs.io/demo.html, I found exact matches weren't zero
// 0.00001 low enough to filter all but exact matches
export const FUSE_FUZZY_MATCH_THRESHOLD = 0.4;
export const FUSE_LESS_FUZZY_MATCH_THRESHOLD = 0.2;
export const FUSE_EXACT_MATCH_THRESHOLD = 0.00001;

export const AI_MATCHING_TIMEOUT_MS = 10000;
export const AI_MATCHING_MAX_TERMS = 100;
