import axios from "axios";
import { FileWithPath } from "react-dropzone";
import * as Sentry from "@sentry/browser";
import { AppThunk } from "../store/configureStore";
import { setUploadId } from "../store/reducers/coredata";
import {
  PresignedPost,
  GetRawUploadParams,
  GetRawUploadResponse,
  GetCleanedUploadResponse,
  UploadStatus,
} from "../interfaces/api";
import { sleep } from "../util/async";
import APIClient from "./APIClient";
import { RootState } from "../store/reducers";

type TUploadType = "clean" | "raw";
type AsyncThunk<T> = AppThunk<Promise<T>>;

export function getAPIClient(state: RootState): APIClient {
  const { settings } = state;
  return new APIClient(settings.licenseKey, settings.backend.url);
}

export const uploadRawFileHelper = (
  file: FileWithPath
): AsyncThunk<{
  uploadId: string;
  response: GetRawUploadResponse;
}> => {
  return uploadFileHelper(file, false, null, false) as AsyncThunk<{
    uploadId: string;
    response: GetRawUploadResponse;
  }>;
};

export const uploadCleanedFileHelper = (
  file: FileWithPath,
  numDataRows: number
): AsyncThunk<{
  uploadId: string;
  response: GetCleanedUploadResponse;
}> => {
  return uploadFileHelper(file, true, numDataRows, false) as AsyncThunk<{
    uploadId: string;
    response: GetCleanedUploadResponse;
  }>;
};

export const uploadSavedProgressFileHelper = (
  file: File,
  numDataRows: number
): AsyncThunk<{
  uploadId: string;
  response: GetCleanedUploadResponse;
}> => {
  return uploadFileHelper(file, true, numDataRows, true) as AsyncThunk<{
    uploadId: string;
    response: GetCleanedUploadResponse;
  }>;
};

const uploadFileHelper = (
  file: FileWithPath,
  cleaned: boolean,
  numDataRows: number | null,
  isSavedProgress: boolean
): AsyncThunk<{
  uploadId: string;
  response: GetRawUploadResponse | GetCleanedUploadResponse;
}> => {
  return async (dispatch, getState) => {
    const state = getState();
    let { settings, coredata, fields } = state;
    const api = getAPIClient(state);
    const uploadType: TUploadType = cleaned ? "clean" : "raw";
    const importIdentifier = settings.importIdentifier;
    const writeOnly = settings.backendCapabilities.write_only_storage;
    const requiresBackendProcessing =
      uploadType === "raw" && !writeOnly && !settings.browserExcelParsing;

    try {
      const additionalParams: Partial<GetRawUploadParams> = {};

      if (uploadType === "clean") {
        additionalParams.fieldOrder = fields.fieldOrder;
      }
      // First get the pre-signed S3 upload URL
      const { signature, uploadId, uploadKey, container } =
        await api.getSignedUploadUrl({
          fileName: file.name,
          user: settings.user,
          uploadType,
          rawDataUploadId: coredata.uploadId,
          label: coredata.selectedSheetId,
          importIdentifier,
          numDataRows,
          ...additionalParams,
        });

      // if this was the raw data upload, set it in coredata
      if (uploadType === "raw") {
        dispatch(setUploadId(uploadId));
        coredata = getState().coredata;
      }

      if (
        typeof signature === "string" &&
        signature.match(/blob\.core\.windows\.net/)
      ) {
        const azure = await import("./azureUpload");
        await azure.upload(signature, container, uploadKey, file);
      } else {
        // Upload the file to S3 or GCP
        const postData = new FormData();
        const fields = (signature as PresignedPost).fields;
        for (const [key, value] of Object.entries(fields)) {
          postData.append(key, value);
        }
        postData.append("file", file);
        await axios.post((signature as PresignedPost).url, postData);
      }

      let uploadStatus: UploadStatus;
      if (requiresBackendProcessing) {
        uploadStatus = "PROCESSING";
      } else if (isSavedProgress) {
        uploadStatus = "SAVED_PROGRESS";
      } else {
        uploadStatus = "PROCESSED";
      }

      // Update the file status to PROCESSING or PROCESSED based on
      // file type
      let response = await api.updateUpload(uploadType, uploadId, uploadStatus);

      if ("raw_data_upload" in response) {
        // set the upload ID if it isn't already set. this can happen if
        // the user did manual entry and this is the cleaned data upload
        if (coredata.uploadId === null) {
          dispatch(setUploadId(response.raw_data_upload.id));
        } else if (coredata.uploadId !== response.raw_data_upload.id) {
          Sentry.captureException(
            `Mismatching rawUploadId on clean upload with: ${coredata.uploadId} ${response.raw_data_upload}`
          );
        }
      }

      // if we aren't waiting for the backend to process, we're done
      if (!requiresBackendProcessing) {
        return { uploadId, response };
      }

      // otherwise we need to poll the backend and wait for processing
      const checkUploadStatus = async (): Promise<{
        uploadId: string;
        response: any;
      }> => {
        response = await api.getUpload(uploadType, uploadId);

        if (
          response.upload_status === "PROCESSED" ||
          response.upload_status === "FAILED"
        ) {
          return { uploadId, response };
        } else {
          await sleep(1000);
          return await checkUploadStatus();
        }
      };

      return await checkUploadStatus();
    } catch (err) {
      console.error(err);
      throw err;
    }
  };
};

export const downloadFileToBuffer = async (
  url: string
): Promise<ArrayBuffer> => {
  const response = await axios.get(url, { responseType: "arraybuffer" });
  return response.data;
};

export const downloadJson = async (url: string): Promise<unknown> => {
  const response = await axios.get(url, { responseType: "json" });
  return response.data;
};
