import { AbstractField, BaseTypeOpts, ITransformResult } from "./abstract";

// all valid characters that can show up before the @
const userPart = "[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+";
// valid domain name part- cannot start or end with a hyphen, must be 2-63 chars long
const singleDomain = "[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]";
// valid domain name - at least two domains (host + tld), possibly more
const domainPart = `${singleDomain}(?:\\.${singleDomain})+`;
// user part + @ sign + valid domain name
const emailRegex = new RegExp(`^${userPart}@${domainPart}$`);

export class EmailField extends AbstractField<BaseTypeOpts, string> {
  static regex = emailRegex;
  static defaultInvalidValueMessage = "Invalid email";

  type = "email" as const;

  transform(value: string): ITransformResult<string> {
    if (EmailField.regex.test(value)) {
      return this.transformSuccess(value);
    } else {
      return this.transformFailure();
    }
  }

  getDisplayValue(value: string): string {
    return value;
  }

  getOutputValue(value: string): string {
    return value;
  }
}
