import { z } from "zod";

export const StyleOverridesSchema = z
  .object({
    global: z
      .object({
        textColor: z.string().default(""),
        primaryTextColor: z.string().default(""),
        secondaryTextColor: z.string().default(""),
        successColor: z.string().default(""),
        warningColor: z.string().default(""),
        customFontURL: z.string().nullable().default(null),
        customFontFamily: z.string().nullable().default(null),
        backgroundColor: z.string().default(""),
        borderRadius: z.string().default(""),
        borderWidth: z.string().default(""),
        borderColor: z.string().default(""),
        borderStyle: z.string().default(""),
      })
      .default({}),
    primaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    secondaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    tertiaryButton: z
      .object({
        borderRadius: z.string().default(""),
        backgroundColor: z.string().default(""),
        textColor: z.string().default(""),
        border: z.string().default(""),
        hoverBackgroundColor: z.string().default(""),
        hoverTextColor: z.string().default(""),
        hoverBorder: z.string().default(""),
      })
      .default({}),
    dropzone: z
      .object({
        // VERSION_TWO TODO when getting rid of V1: make these the V2 defaults
        borderWidth: z.number().default(-1),
        borderRadius: z.number().default(-1),
        borderColor: z.string().default(""),
        borderStyle: z.string().default(""),
        backgroundColor: z.string().default(""),
        color: z.string().default(""),
        outline: z.string().default(""),
      })
      .default({}),
    helpText: z
      .object({
        textColor: z.string().default(""),
        backgroundColor: z.string().default(""),
      })
      .default({}),
    stepperBar: z
      .object({
        completeColor: z.string().default(""),
        incompleteColor: z.string().default(""),
        currentColor: z.string().default(""),
        fontSize: z.string().default(""),
        completeFontWeight: z.string().default(""),
        incompleteFontWeight: z.string().default(""),
        currentFontWeight: z.string().default(""),
        backgroundColor: z.string().default(""),
        borderBottom: z.string().default(""),
      })
      .default({}),
    dataTable: z
      .object({
        headerFontWeight: z.string().default(""),
      })
      .default({}),
  })
  .transform((styleOverrides) => {
    // If background color is specified and no border color is specified,
    // set border color to background color
    if (
      styleOverrides.primaryButton.backgroundColor &&
      !styleOverrides.primaryButton.border
    ) {
      styleOverrides.primaryButton.border =
        styleOverrides.primaryButton.backgroundColor;
    }
    if (
      styleOverrides.secondaryButton.backgroundColor &&
      !styleOverrides.secondaryButton.border
    ) {
      styleOverrides.secondaryButton.border =
        styleOverrides.secondaryButton.backgroundColor;
    }

    // If background color is specified and no hover background color is specified,
    // set hover background color to background color
    if (
      styleOverrides.primaryButton.backgroundColor &&
      !styleOverrides.primaryButton.hoverBackgroundColor
    ) {
      styleOverrides.primaryButton.hoverBackgroundColor =
        styleOverrides.primaryButton.backgroundColor;
    }
    if (
      styleOverrides.secondaryButton.backgroundColor &&
      !styleOverrides.secondaryButton.hoverBackgroundColor
    ) {
      styleOverrides.secondaryButton.hoverBackgroundColor =
        styleOverrides.secondaryButton.backgroundColor;
    }

    // If text color is specified and no hover text color is specified,
    // set hover text color to text color
    if (
      styleOverrides.primaryButton.textColor &&
      !styleOverrides.primaryButton.hoverTextColor
    ) {
      styleOverrides.primaryButton.hoverTextColor =
        styleOverrides.primaryButton.textColor;
    }
    if (
      styleOverrides.secondaryButton.textColor &&
      !styleOverrides.secondaryButton.hoverTextColor
    ) {
      styleOverrides.secondaryButton.hoverTextColor =
        styleOverrides.secondaryButton.textColor;
    }

    // If border color is specified and no hover border color is specified,
    // set hover border color to border color
    if (
      styleOverrides.primaryButton.border &&
      !styleOverrides.primaryButton.hoverBorder
    ) {
      styleOverrides.primaryButton.hoverBorder =
        styleOverrides.primaryButton.border;
    }
    if (
      styleOverrides.secondaryButton.border &&
      !styleOverrides.secondaryButton.hoverBorder
    ) {
      styleOverrides.secondaryButton.hoverBorder =
        styleOverrides.secondaryButton.border;
    }

    return styleOverrides;
  });

export type IDeveloperStyleOverrides = z.input<typeof StyleOverridesSchema>;
export type IDeveloperStyleOverridesStrict = z.output<
  typeof StyleOverridesSchema
>;

export const V1Defaults: IDeveloperStyleOverridesStrict = {
  global: {
    textColor: "#394b59",
    primaryTextColor: "#394b59",
    secondaryTextColor: "#667d8d",
    successColor: "#28a745",
    warningColor: "#f8e0e2",
    customFontURL: null,
    customFontFamily: null,
    backgroundColor: "#ffffff",
    borderRadius: "0.25rem",
    borderStyle: "none",
    borderColor: "#000",
    borderWidth: "0",
  },
  primaryButton: {
    borderRadius: "0.25rem",
    backgroundColor: "#007bff",
    textColor: "#ffffff",
    border: "1px solid #007bff",
    hoverBackgroundColor: "#006bef",
    hoverTextColor: "#ffffff",
    hoverBorder: "1px solid #006bef",
  },
  secondaryButton: {
    borderRadius: "0.25rem",
    backgroundColor: "#6c757d",
    textColor: "#ffffff",
    border: "1px solid #6c757d",
    hoverBackgroundColor: "#5c656d",
    hoverTextColor: "#fffff",
    hoverBorder: "1px solid #5c656d",
  },
  tertiaryButton: {
    borderRadius: "0.25rem",
    backgroundColor: "#ffffff",
    textColor: "#6c757d",
    border: "1px solid #6c757d",
    hoverBackgroundColor: "#ffffff",
    hoverTextColor: "#000000",
    hoverBorder: "1px solid #000000",
  },
  dropzone: {
    borderWidth: 3,
    borderRadius: 2,
    borderColor: "#dbe5f0",
    borderStyle: "dashed",
    backgroundColor: "#f5f8fa",
    color: "#bdbdbd",
    outline: "none",
  },
  helpText: {
    textColor: "#055160",
    backgroundColor: "#cff4fc",
  },
  stepperBar: {
    completeColor: "#058b00",
    incompleteColor: "#486581",
    currentColor: "#007BFF",
    fontSize: "1rem",
    completeFontWeight: "bold",
    incompleteFontWeight: "normal",
    currentFontWeight: "bold",
    backgroundColor: "#f8f9fa",
    borderBottom: "#394b59",
  },
  dataTable: {
    headerFontWeight: "",
  },
};

export const V2Defaults: IDeveloperStyleOverridesStrict = {
  global: {
    // Not used anymore
    textColor: "",
    primaryTextColor: "",
    secondaryTextColor: "",
    // Those two weren't used anymore?
    successColor: "",
    warningColor: "",
    customFontURL: null,
    customFontFamily: null,
    backgroundColor: "",
    borderRadius: "8px",
    borderStyle: "",
    borderColor: "",
    borderWidth: "",
  },
  primaryButton: {
    borderRadius: "",
    backgroundColor: "",
    textColor: "",
    border: "",
    hoverBackgroundColor: "",
    hoverTextColor: "",
    hoverBorder: "",
  },
  secondaryButton: {
    borderRadius: "",
    backgroundColor: "",
    textColor: "",
    border: "",
    hoverBackgroundColor: "",
    hoverTextColor: "",
    hoverBorder: "",
  },
  tertiaryButton: {
    borderRadius: "",
    backgroundColor: "",
    textColor: "",
    border: "",
    hoverBackgroundColor: "",
    hoverTextColor: "",
    hoverBorder: "",
  },
  dropzone: {
    borderWidth: 2,
    borderRadius: 8,
    borderColor: "",
    borderStyle: "",
    backgroundColor: "",
    // Will be deprecated?
    color: "",
    outline: "",
  },
  helpText: {
    textColor: "",
    backgroundColor: "",
  },
  stepperBar: {
    completeColor: "",
    incompleteColor: "",
    currentColor: "",
    fontSize: "",
    completeFontWeight: "",
    incompleteFontWeight: "",
    currentFontWeight: "",
    backgroundColor: "",
    borderBottom: "",
  },
  dataTable: {
    headerFontWeight: "",
  },
};

export const setStyleOverrideDefaults = (
  isV2: boolean,
  styleOverrides: IDeveloperStyleOverridesStrict
): IDeveloperStyleOverridesStrict => {
  if (isV2) {
    // V2 only has two default hardcoded values that we need to handle
    if (styleOverrides.dropzone.borderWidth === -1) {
      styleOverrides.dropzone.borderWidth = V2Defaults.dropzone.borderWidth;
    }
    if (styleOverrides.dropzone.borderRadius === -1) {
      styleOverrides.dropzone.borderRadius = V2Defaults.dropzone.borderRadius;
    }
    if (styleOverrides.global.borderRadius === "") {
      styleOverrides.global.borderRadius = V2Defaults.global.borderRadius;
    }
  } else {
    for (const [categoryKey, categoryObj] of Object.entries(styleOverrides)) {
      for (const [propKey, value] of Object.entries(categoryObj)) {
        if (value === "" || value === -1) {
          const defaultVal =
            V1Defaults[categoryKey as keyof IDeveloperStyleOverridesStrict][
              propKey as keyof typeof categoryObj
            ];

          styleOverrides[categoryKey as keyof IDeveloperStyleOverridesStrict][
            propKey as keyof typeof categoryObj
          ] = defaultVal;
        }
      }
    }
  }

  return styleOverrides;
};
