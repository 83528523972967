import { FileWithPath } from "react-dropzone";
import type XLSX from "@sheet/core";
import Papa from "papaparse";
import {
  MAX_UNIQUE_VALUES_SELECT_FIELD,
  NUM_ROWS_FOR_PREVIEW,
} from "../constants/constants";
import { getCleanedCellData } from "./CoreDataHelpers";
import { store } from "../store/configureStore";
import { DateTime } from "luxon";
import { roundToNearestSecond } from "../util/datetime";

export interface IParseFileResponse {
  headers: string[] | null;
  data: any[][];
  valCountsInColumn: Map<number, Map<string, number>>;
  percentHasValueInColumn: Map<number, number>;
}

interface IParseLocalFileInput {
  data: any[][] | null;
  headerRow: number | null;
}

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

function filterEmptyValues(value: any): boolean {
  switch (true) {
    case typeof value === "string" && value.trim() === "":
      return true;
    case typeof value === "undefined":
      return true;
    case typeof value === "object" && value === null:
      return true;
    default:
      return false;
  }
}

/**
 * transposes the rows and columns in an array of arrays
 * all rows are assumed to have the same length
 * @example
 * transpose([["a", "b"], ["c", "d"], ["e", "f"]])
 * // returns  [["a", "c", "e"], ["b", "d", "f"]]
 **/
export function transpose<T>(data: T[][] | null): T[][] {
  if (data === null) return [];
  if (data.length === 0) return [];

  return data[0].map((_: any, colIndex: number) =>
    data.map((row) => row[colIndex])
  );
}

function getParsedDataSummary(
  data: any[][],
  headerRow: number | null,
  emptyColumns: Map<number, number>,
  uniqueInColumn: Map<number, Map<string, number>>,
  numTotalRows: number
): { headers: string[] | null; percentHasValueInColumn: Map<number, number> } {
  let headers = null;
  if (headerRow !== null) {
    headers = data.splice(0, headerRow + 1)[headerRow];
    // replace null headers with the empty string
    headers.forEach((header: string | null, index: number) => {
      if (header === null) headers[index] = "";
    });
  }

  const parsedDataTransposed = transpose(data);
  if (headerRow !== null) {
    numTotalRows = numTotalRows - (headerRow + 1);
  }

  const percentHasValueInColumn = new Map<number, number>();
  for (let colIndex = 0; colIndex < parsedDataTransposed.length; colIndex++) {
    const numEmptyInColumn = emptyColumns.get(colIndex) ?? 0;
    percentHasValueInColumn.set(
      colIndex,
      Math.round(((numTotalRows - numEmptyInColumn) / numTotalRows) * 100)
    );

    // If there are more than MAX_UNIQUE_VALUES_SELECT_FIELD unique values,
    // remove the key, so it can't be a categorical mapping
    const numUniqueInColumn = uniqueInColumn.get(colIndex) || new Set();
    const { alwaysMatchSelectFields } = store.getState().settings;
    if (
      !alwaysMatchSelectFields &&
      numUniqueInColumn.size >= MAX_UNIQUE_VALUES_SELECT_FIELD
    ) {
      uniqueInColumn.delete(colIndex);
    }
  }

  return {
    headers,
    percentHasValueInColumn,
  };
}

export function parseUnmappedData(
  unmappedData: any[][],
  headerRow: number | null
): IParseFileResponse {
  // mapping of colIndex to the number of empty values
  const emptyColumns: Map<number, number> = new Map<number, number>();
  // mapping of colIndex to a set of unique values, which we can count
  const valCountsInColumn: Map<number, Map<string, number>> = new Map();

  for (let i = 0; i < unmappedData.length; i++) {
    valCountsInColumn.set(i, new Map());
    emptyColumns.set(i, 0);
  }

  const parsedData: any[][] = [];
  unmappedData.forEach((row: any[], rowIdx: number) => {
    // ignore all rows that are empty
    if (row.every((i) => i === null)) return;

    // if the headerRow is not null, we want to just ignore up to and
    // including that row for emptyColumn and uniqueValues
    if (headerRow === null || rowIdx > headerRow) {
      row.forEach((val: any, colIdx: number) => {
        if (val === undefined || val === null || val === "") {
          const curEmptyNumRows = emptyColumns.get(colIdx);
          if (curEmptyNumRows !== undefined) {
            emptyColumns.set(colIdx, curEmptyNumRows + 1);
          } else {
            // This is an edge case when the first row has missing headers and the
            // set wasn't fully initalized.
            // Set it to 1 if not initialized
            emptyColumns.set(colIdx, 1);
          }
        } else {
          if (typeof val === "string") {
            val = val.trim();
          }

          const valCounts = valCountsInColumn.get(colIdx);
          if (valCounts !== undefined) {
            const count = valCounts.get(val);
            valCounts.set(val, count === undefined ? 1 : count + 1);
          } else {
            // This is an edge case when the first row has missing headers and the
            // set wasn't fully initalized.
            const map = new Map<string, number>([[val, 1]]);
            valCountsInColumn.set(colIdx, map);
          }
        }
      });
    }
    parsedData.push(row.map((x) => getCleanedCellData(x)));
  });

  const { headers, percentHasValueInColumn } = getParsedDataSummary(
    parsedData,
    headerRow,
    emptyColumns,
    valCountsInColumn,
    parsedData.length
  );

  return {
    headers,
    data: parsedData,
    valCountsInColumn,
    percentHasValueInColumn,
  };
}

export function parseLocalFile({
  data,
  headerRow,
}: IParseLocalFileInput): IParseFileResponse {
  if (data) {
    const columns = transpose(data).filter((col) =>
      col.some((val) => val !== "")
    );
    const parsedData = transpose(columns).filter((row) =>
      row.some((val) => val !== "")
    );

    let headers = null;
    if (headerRow !== null) {
      headers = parsedData[headerRow];
      parsedData.splice(0, headerRow + 1);
    }

    const transposedData = transpose(parsedData);

    const valCountsInColumn = transposedData.reduce(
      (
        valCountsInCol: Map<number, Map<string, number>>,
        columnValues: any[],
        columnNumber: number
      ) => {
        const occuranceCounts: Map<string, number> = columnValues.reduce(
          (counts: Map<string, number>, val) =>
            counts.set(val, counts.has(val) ? counts.get(val)! + 1 : 1),
          new Map<string, number>()
        );
        return valCountsInCol.set(columnNumber, occuranceCounts);
      },
      new Map()
    );

    const numberOfRows = parsedData.length;

    const filledRowsRatio = new Map(
      transposedData.map(
        (
          columnValues: Array<string>,
          columnNumber: number
        ): [number, number] => {
          const numEmptyRows = columnValues.filter(filterEmptyValues).length;
          const percentage =
            ((numberOfRows - numEmptyRows) / numberOfRows) * 100;

          return [columnNumber, parseFloat(percentage.toFixed(2))];
        }
      )
    );

    return {
      headers,
      data: parsedData,
      valCountsInColumn,
      percentHasValueInColumn: filledRowsRatio,
    };
  }

  return {
    headers: [],
    data: [[]],
    valCountsInColumn: new Map(),
    percentHasValueInColumn: new Map(),
  };
}

export function parseFile(
  // Can either accept a "File" or "String" which represents a remote file URL
  file: FileWithPath | string,
  encoding: string,
  headerRow: number | null,
  previewOnly: boolean,
  delimiter: string | undefined
): Promise<IParseFileResponse> {
  return new Promise((resolve, reject) => {
    const previewData: any[] = [];

    let numTotalRows = 0;
    let numPreviewRows = 0;
    // mapping of colIndex to the number of empty values
    const emptyColumns: Map<number, number> = new Map<number, number>();
    // mapping of colIndex to a set of unique values, which we can count
    const valCountsInColumn: Map<number, Map<string, number>> = new Map<
      number,
      Map<string, number>
    >();

    // eslint-disable-next-line
    // @ts-ignore
    Papa.parse(file, {
      // download = true if file is a URL (typeof string)
      download: typeof file === "string" && isValidHttpUrl(file),
      encoding,
      header: false,
      dynamicTyping: false,
      skipEmptyLines: true,
      delimiter,
      step: (row: { data: any[] }) => {
        // ignore all rows that are empty
        if (row.data.every((i) => i === null)) return;

        // Initialize the maps if empty
        if (emptyColumns.size === 0) {
          for (let i = 0; i < row.data.length; i++) {
            emptyColumns.set(i, 0);
          }
        }
        if (valCountsInColumn.size === 0) {
          for (let i = 0; i < row.data.length; i++) {
            valCountsInColumn.set(i, new Map());
          }
        }

        // if the headerRow is not null, we want to just ignore up to and
        // including that row for emptyColumn and uniqueValues
        if (headerRow === null || numTotalRows > headerRow) {
          row.data.forEach((val: any, colIdx: number) => {
            if (val === undefined || val === null || val === "") {
              const curEmptyNumRows = emptyColumns.get(colIdx);
              if (curEmptyNumRows !== undefined) {
                emptyColumns.set(colIdx, curEmptyNumRows + 1);
              } else {
                // This is an edge case when the first row has missing headers and the
                // set wasn't fully initalized.
                // Set it to 1 if not initialized
                emptyColumns.set(colIdx, 1);
              }
            } else {
              if (typeof val === "string") {
                val = val.trim();
              }

              const valCountsInColumns = valCountsInColumn.get(colIdx);
              if (valCountsInColumns !== undefined) {
                const count = valCountsInColumns.get(val);
                valCountsInColumns.set(
                  val,
                  count === undefined ? 1 : count + 1
                );
              } else {
                // This is an edge case when the first row has missing headers and the
                // set wasn't fully initalized.
                const map = new Map<string, number>([[val, 1]]);
                valCountsInColumn.set(colIdx, map);
              }
            }
          });
        }

        if (!previewOnly || numPreviewRows < NUM_ROWS_FOR_PREVIEW) {
          previewData.push(row.data.map((x) => getCleanedCellData(x)));
          numPreviewRows++;
        }
        numTotalRows++;
      },
      complete: () => {
        const { headers, percentHasValueInColumn } = getParsedDataSummary(
          previewData,
          headerRow,
          emptyColumns,
          valCountsInColumn,
          numTotalRows
        );

        resolve({
          headers,
          data: previewData,
          valCountsInColumn,
          percentHasValueInColumn,
        });
      },
      error: (error: Papa.ParseError) => {
        reject(error.message);
      },
    });
  });
}

export async function parseWorksheet(
  worksheet: XLSX.WorkSheet
): Promise<string[][]> {
  if (worksheet["!ref"] === undefined) {
    // Means we have a completely empty sheet
    return [];
  }

  const XLSX = await import("@sheet/core");
  const rowLength = XLSX.utils.decode_range(worksheet["!ref"]!).e.c + 1;

  return Array.from(worksheet["!data"] as XLSX.CellObject[][]).map(
    (row: XLSX.CellObject[] | undefined) => {
      if (row === undefined) {
        return new Array(rowLength).fill("");
      }

      // Merged cells result in a sparse array.
      // The Array.from converts empty elements to undefined so we can map over them.
      let filledRow = Array.from(row);

      // Each row will only have as many elements as its last filled cell, but we
      // need them to have uniform length.
      if (filledRow.length < rowLength) {
        const padding = new Array(rowLength - filledRow.length).fill(undefined);
        filledRow = filledRow.concat(padding);
      }

      return filledRow.map((cell) => {
        if (cell?.v === undefined) return "";

        switch (cell.t) {
          case "n": // number
          case "b": // boolean
            // We return the raw value, instead of display value.
            // For numbers, large values can get truncated into scientific notation
            // in the display value.
            return cell.v.toString();
          case "d": {
            // Dates are converted to ISO-8601 strings.
            // The excel formatted value is locale-dependent.

            // We round to the nearest second to fix rounding issues
            const dateTime = roundToNearestSecond(
              DateTime.fromJSDate(cell.v as Date).setZone("UTC")
            );

            const dateString = dateTime.toISODate();
            if (dateString === "1899-12-30" || dateString === "1899-12-31") {
              // this means we have a plain clock time, discard the date
              return dateTime.toISOTime({
                includeOffset: false,
                suppressMilliseconds: true,
              });
            } else {
              return dateTime.toISO({
                includeOffset: false,
                suppressMilliseconds: true,
              });
            }
          }
          default: {
            // Default is to return the formatted value if it exists, otherwise the raw value.
            // CSVs only have raw values.
            let value = cell.w ?? cell.v;

            // String should be the only type left here, but I don't trust sheetjs
            if (typeof value === "string") {
              value = value.trim();
            }

            return value;
          }
        }
      });
    }
  );
}

// For some extensions we don't want preprocessing from sheetjs
const RAW_EXTENSIONS = ["csv", "tsv", "txt"];

export async function parseWorkbook(
  file: FileWithPath | File,
  previewOnly: boolean,
  codepage?: string | null
) {
  const XLSX = await import("@sheet/core");
  const buffer = await file.arrayBuffer();
  const fileExtension = file.name.split(".").pop()!;

  // UTF-8 is the default codepage value in redux state.
  // But we need to translate that to the codepage ID
  if (codepage === "UTF-8") {
    codepage = "65001";
  }

  return XLSX.read(buffer, {
    ...(RAW_EXTENSIONS.includes(fileExtension)
      ? {
          raw: true,
        }
      : {
          cellDates: true,
        }),
    dense: true,
    codepage: codepage ? parseInt(codepage, 10) : undefined,
    // sheetRows: previewOnly ? NUM_ROWS_FOR_PREVIEW : 0,
  });
}

export const cellIsEmpty = (cellValue: unknown): boolean => {
  return cellValue === undefined || cellValue === null || cellValue === "";
};
