import React, { useLayoutEffect, useRef, useState } from "react";
import { twMerge } from "tailwind-merge";
import { useStyles } from "../../store/hooks";
import { IDeveloperStyleOverridesStrict } from "../../interfaces";

export type ButtonTheme = "primary" | "secondary" | "ghost";

export interface IButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  loading?: boolean;
  className?: string;
  children: React.ReactNode;
  theme?: ButtonTheme;
  type?: "button" | "submit" | "reset";
  "data-cy"?: string;
  autoFocus?: boolean;
}

type ButtonThemeMap = {
  [key in ButtonTheme]: string;
};

const mapThemeToClasses: ButtonThemeMap = {
  primary: "text-[#fff] bg-blue hover:bg-blue-600 active:bg-blue-800",
  secondary:
    "text-ice-500 bg-ice-50 !border border-ice-300 hover:bg-ice-100 active:bg-ice-200",
  ghost:
    "!bg-transparent text-ice-500 hover:text-ice-600 active:text-ice-700 shadow-none",
};

export function Button({
  onClick,
  disabled,
  loading,
  className,
  children,
  type = "button",
  theme = "primary",
  "data-cy": dataCy,
  autoFocus,
}: IButtonProps) {
  const [isHovering, setIsHovering] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const classes = mapThemeToClasses[theme];

  const getButtonStyle = (
    key: "primaryButton" | "secondaryButton" | "tertiaryButton",
    styleOverrides: IDeveloperStyleOverridesStrict
  ) => {
    return {
      color: isHovering
        ? styleOverrides[key].hoverTextColor
        : styleOverrides[key].textColor,
      backgroundColor: isHovering
        ? styleOverrides[key].hoverBackgroundColor
        : styleOverrides[key].backgroundColor,
      border: isHovering
        ? styleOverrides[key].hoverBorder
        : styleOverrides[key].border,
      borderRadius: styleOverrides[key].borderRadius,
    };
  };

  const getOverrideStyles = (
    styleOverrides: IDeveloperStyleOverridesStrict
  ) => {
    switch (theme) {
      case "primary":
      case "secondary":
        return getButtonStyle(`${theme}Button`, styleOverrides);
      case "ghost":
        return {
          color: styleOverrides.global.primaryTextColor,
        };
    }
  };

  const overrideStyles = useStyles(getOverrideStyles);

  useLayoutEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ref.current?.focus({
          preventScroll: true,
        });
      }, 0);
    }
  }, [autoFocus]);

  return (
    <button
      ref={ref}
      type={type}
      data-cy={dataCy}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      className={twMerge(
        `
          flex
          flex-row
          justify-center
          items-center
          px-3
          py-2
          font-semibold
          rounded-lg
          shadow-sm 
        `,
        classes,
        disabled && "opacity-50 pointer-events-none",
        className
      )}
      onClick={onClick}
      disabled={disabled || loading}
      style={overrideStyles}
    >
      {children}
    </button>
  );
}

export default Button;
