import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Button } from "../commonComponents/Button";
import { UIDataReviewProps } from ".";
import { ReactComponent as ExportIcon } from "../../assets/export.svg";
import { AlertModalRedesign } from "../AlertModal";
import HelpText from "../HelpTextRedesign";
import TableWrapper from "../TableWrapper";
import { UpsellModal } from "../UpsellModal";
import ButtonDropdown from "../commonComponents/ButtonDropdown";
import Text from "../commonComponents/Text";
import Modal from "../commonComponents/Modal";
import FindAndReplaceModal from "./FindAndReplaceModal";
import { Alert } from "../commonComponents/Alert";
import { Card } from "../commonComponents/Card";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { ErrorFilterButton } from "./ErrorFilterButton";
import UserFunction from "../UserFunction";
import { useAppSelector } from "../../store/hooks";

export function DataReviewView({
  alertModal,
  alertModalCaption,
  alertModalMessage,
  children,
  data,
  downloadRichXLSX,
  downloadRichXLSXOnlyErrors,
  FinishButton,
  fullData,
  handleAlertSecondaryButtonClick,
  handleCellDataChange,
  handleCloseUpsellModal,
  handleCreateRow,
  handleFindAndReplace,
  handleRemoveRows,
  handleShowAlert,
  hasAlertModalError,
  rowsWithErrorsCount,
  hiddenRows,
  isLoading,
  manualInputOnly,
  onGoBackButtonClick,
  onPrimaryButtonClick,
  primaryButtonDescriptionText,
  primaryButtonText,
  secondaryButtonDescriptionText,
  secondaryButtonText,
  selectedHandsonCell,
  showOnlyRowsWithProblemsToggle,
  showUpsellModal,
  stepErrorMessage,
  stepSettings,
  onFilterAllRows,
  onFilterColumn,
  onClearFilter,
  onConfirmChanges,
  currentFilter,
  hideBackButton,
}: UIDataReviewProps) {
  const settings = useAppSelector((state) => state.settings);
  const [showFindAndReplace, setShowFindAndReplace] = useState(false);
  const { t } = useTranslation();

  const renderAlertMessages = () => {
    const messages: React.ReactNode[] = [];

    if (stepErrorMessage) {
      messages.push(
        <Alert
          type="error"
          data-cy="step-error-message"
          key="step-error-message"
        >
          <div dangerouslySetInnerHTML={{ __html: stepErrorMessage }} />
        </Alert>
      );
    }

    if (stepSettings.helpText) {
      messages.push(
        <HelpText
          content={stepSettings.helpText}
          className="mt-0"
          key="step-help-text"
        />
      );
    }

    return messages;
  };

  const [isUserFunctionOpen, setIsUserFunctionOpen] = useState(false);

  const handleOpenUserFunction = () => {
    setIsUserFunctionOpen(true);
  };

  const handleCloseUserFunction = () => {
    setIsUserFunctionOpen(false);
  };

  // show it unless we're rehydrating and we're going back to data upload
  const showBackButton =
    data.uploadType !== "INITIAL_DATA_MAPPED" &&
    !manualInputOnly &&
    !hideBackButton;

  const showTransformDataButton =
    settings.reviewStep.enableUserTransformations &&
    settings.backendCapabilities.transform_data;

  return (
    <div>
      {showTransformDataButton && (
        <UserFunction
          isOpen={isUserFunctionOpen}
          onClose={handleCloseUserFunction}
          fullData={fullData}
          onConfirmChanges={onConfirmChanges}
        />
      )}
      <AlertModalRedesign
        show={alertModal.show}
        setShow={handleShowAlert}
        message={alertModalMessage}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
        primaryButtonDescriptionText={primaryButtonDescriptionText}
        secondaryButtonDescriptionText={secondaryButtonDescriptionText}
        onPrimaryButtonClick={onPrimaryButtonClick}
        onSecondaryButtonClick={handleAlertSecondaryButtonClick}
        showSecondaryButton={secondaryButtonText !== ""}
        errorModal={hasAlertModalError}
        caption={alertModalCaption}
        data-cy="data-review-alert"
      >
        {children}
      </AlertModalRedesign>
      <UpsellModal
        show={showUpsellModal}
        onClose={handleCloseUpsellModal}
      ></UpsellModal>
      <FindAndReplaceModal
        show={showFindAndReplace}
        onClose={() => setShowFindAndReplace(false)}
        handleFindAndReplace={handleFindAndReplace}
      />
      <Modal
        data-cy="DataReviewModal"
        onBack={showBackButton ? onGoBackButtonClick : undefined}
        step="review"
        contentClassName="!p-2 md:!p-4"
      >
        <Card className="!p-0 md:!p-0 overflow-hidden">
          {isLoading ? (
            <div className="flex flex-col justify-center h-80">
              <div className="flex justify-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">{t("common.loading")}</span>
                </Spinner>
              </div>
              {stepSettings.processingText && (
                <Text type="body" className="mt-2 text-center">
                  {stepSettings.processingText}
                </Text>
              )}
            </div>
          ) : (
            <>
              <header className="!pt-6 !pl-6 !pr-6 !pb-4">
                <div className="flex items-start">
                  <div>
                    <Text type="h1">{t("modalHeader.review")}</Text>
                  </div>
                  <div className="flex ml-auto whitespace-nowrap gap-2">
                    {rowsWithErrorsCount > 0 ? (
                      <ErrorFilterButton
                        currentFilter={currentFilter}
                        onFilterAllRows={onFilterAllRows}
                        onClearFilter={onClearFilter}
                      />
                    ) : (
                      <div className="!p-2 rounded-lg text-green-700 bg-green-50 !text-sm font-bold flex items-center gap-2 whitespace-nowrap">
                        <CheckIcon className="w-3 h-4" />
                        <Text type="inherit">
                          {t(
                            "dataReviewModal.errorActionButton.noRowsWithError"
                          )}
                        </Text>
                      </div>
                    )}

                    <Button
                      onClick={() => setShowFindAndReplace(true)}
                      theme="secondary"
                      className="text-sm"
                    >
                      {t("dataReviewModal.findAndReplace.title")}
                    </Button>
                    {showTransformDataButton && (
                      <Button
                        theme="secondary"
                        className="text-sm"
                        onClick={handleOpenUserFunction}
                        data-cy="transform-data-button"
                      >
                        {t("userFunctionModal.title")}
                      </Button>
                    )}

                    <ButtonDropdown
                      theme="secondary"
                      options={[
                        {
                          children: `${t("dataReviewModal.exportButton.all")}`,
                          onClick: downloadRichXLSX,
                        },
                        ...(rowsWithErrorsCount > 0
                          ? [
                              {
                                children: `${t(
                                  "dataReviewModal.exportButton.errorsOnly"
                                )}`,
                                onClick: downloadRichXLSXOnlyErrors,
                              },
                            ]
                          : []),
                      ]}
                    >
                      <ExportIcon />
                      <span>{t("dataReviewModal.exportButton.title")}</span>
                    </ButtonDropdown>
                  </div>
                </div>
                <div className="space-y-2 w-full mt-3">
                  {renderAlertMessages()}
                </div>
              </header>
              <div>
                <TableWrapper
                  tableData={fullData.map((r) => [...r])}
                  onCellDataChange={handleCellDataChange}
                  onRemoveRow={handleRemoveRows}
                  onCreateRow={handleCreateRow}
                  hiddenRows={hiddenRows}
                  autoAddRow={!showOnlyRowsWithProblemsToggle}
                  selectedCell={selectedHandsonCell}
                  tableHeight="57vh"
                  onFilterColumn={onFilterColumn}
                  currentFilter={currentFilter}
                />
              </div>
            </>
          )}
        </Card>

        <footer className="flex gap-2 items-center justify-end w-full mt-6">
          <FinishButton />
        </footer>
      </Modal>
    </div>
  );
}
