import * as React from "react";
import { Alert } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";

import { RootState } from "../../store/reducers";

const mapState = (state: RootState) => {
  return {
    styleOverrides: state.settings.styleOverrides.helpText,
  };
};

const reduxConnector = connect(mapState, {});

type IHelpTextProps = { content: string } & ConnectedProps<
  typeof reduxConnector
>;

function HelpText({ content, styleOverrides }: IHelpTextProps) {
  const style: React.CSSProperties = {
    color: styleOverrides.textColor,
    backgroundColor: styleOverrides.backgroundColor,
  };

  return (
    <Alert style={style} className="helpText">
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Alert>
  );
}

export default reduxConnector(HelpText);
