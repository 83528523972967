import React, { useState } from "react";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";

import { ReactComponent as PlusIcon } from "../../../assets/plus.svg";
import { ErrorMessage } from "./ErrorMessage";
import { Control } from "./Control";
import { SingleValue } from "./SingleValue";
import { Option } from "./Option";
import { Placeholder } from "./Placeholder";
import { SelectContextProvider } from "./context";
import { MenuList } from "./MenuList";
import { useTranslation } from "react-i18next";

export type TSelectProps = {
  "data-cy"?: string;
  className?: string;
  error?: string | null;
  getOptionValue?: (option: any) => string;
  getOptionLabel?: (option: any) => string;
  isIgnored?: boolean;
  initialCustomValue?: string;
  name: string;
  onChange: (value: any) => void;
  onCreate?: (value: string) => void;
  options: any[];
  value: any | undefined;
  isClearable?: boolean;
};

export const truncate = (str: string, n: number) => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const Select = ({
  "data-cy": dataCy,
  className,
  error,
  initialCustomValue,
  isIgnored,
  name,
  onChange,
  onCreate,
  options,
  value,
  isClearable,
}: TSelectProps) => {
  const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleCloseMenu = () => setIsMenuOpen(false);
  const creatable = typeof onCreate === "function";
  const ParentContainer = creatable ? CreatableSelect : ReactSelect;

  let creatableProps = {};

  const createLabel = (createValue: string) => (
    <span className="flex items-center gap-2">
      <PlusIcon className="w-4 h-4" />
      <span>{`${t(
        "columnMatchModalCard.addCustomField"
      )} "${createValue}"`}</span>
    </span>
  );

  if (creatable) {
    creatableProps = {
      onCreateOption: onCreate,
      formatCreateLabel: createLabel,
      options: [
        ...options,
        ...(initialCustomValue
          ? [
              {
                key: initialCustomValue,
                label: createLabel(initialCustomValue || ""),
                custom: true,
              },
            ]
          : []),
      ],
    };
  }

  return (
    <SelectContextProvider
      value={{
        isIgnored,
        "data-cy": dataCy,
        error,
        creatable,
        onCreate,
        onCloseMenu: handleCloseMenu,
      }}
    >
      <div data-cy={dataCy} className={className}>
        <ParentContainer
          name={name}
          onChange={onChange}
          value={value}
          getOptionLabel={(option) => option.label}
          getOptionValue={(option) => option.key ?? option.value}
          className="w-full"
          isSearchable
          components={{
            Control,
            SingleValue,
            Option,
            Placeholder,
            MenuList,
          }}
          options={options}
          menuIsOpen={isMenuOpen}
          onMenuOpen={() => setIsMenuOpen(true)}
          onMenuClose={() => setIsMenuOpen(false)}
          isClearable={isClearable ?? false}
          menuPosition="fixed"
          {...creatableProps}
        />
        <ErrorMessage />
      </div>
    </SelectContextProvider>
  );
};

export default Select;
