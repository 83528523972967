import React from "react";
import { useTranslation } from "react-i18next";
import { twMerge } from "tailwind-merge";

import { AlertModalRedesign } from "../AlertModal";
import Text from "../commonComponents/Text";
import { Button } from "../commonComponents/Button";
import Modal from "../commonComponents/Modal";
import { UIDataMetadataProps } from ".";
import { Card } from "../commonComponents/Card";
import { useStyles } from "../../store/hooks";

export function DataMetadataView({
  alertModal,
  setShowAlert,
  handleAlertPrimaryButtonClick,
  handleAlertSecondaryButtonClick,
  headerSelectRow,
  updateHeaderRow,
  setHeaderSelectRow,
  dataPreview,
  handleGoBack,
  matchToSchema,
  isRehydrated,
}: UIDataMetadataProps) {
  const { t } = useTranslation();
  const headerStyles = useStyles(({ dataTable }) => ({
    fontWeight: dataTable.headerFontWeight,
  }));

  return (
    <div>
      <AlertModalRedesign
        show={alertModal.show}
        setShow={setShowAlert}
        message={alertModal.message}
        primaryButtonText={t("common.yes")}
        secondaryButtonText={t("common.no")}
        primaryButtonDescriptionText=""
        secondaryButtonDescriptionText=""
        onPrimaryButtonClick={handleAlertPrimaryButtonClick}
        onSecondaryButtonClick={handleAlertSecondaryButtonClick}
        showSecondaryButton
      />

      <Modal
        data-cy="DataMetadataModal"
        // Do not allow backtracking from MetaData if this is a rehydration flow
        onBack={isRehydrated ? undefined : handleGoBack}
        step="setHeader"
      >
        <header>
          <Text type="h1" className="mb-2">
            {t("dataHeaderModal.title")}
          </Text>
          <Text type="body">{t("dataHeaderModal.subtitle")}</Text>
        </header>
        <div className="flex flex-wrap md:flex-nowrap gap-2 mb-6 mt-4">
          <Button
            onClick={() => updateHeaderRow(headerSelectRow ?? 0)}
            data-cy="data-metadata-continue-button"
            className="w-full md:w-auto whitespace-nowrap"
            autoFocus
          >
            {t("dataHeaderModal.continue")}
          </Button>
          {matchToSchema || (
            <Button
              theme="secondary"
              onClick={() => updateHeaderRow(null)}
              className="w-full md:w-auto whitespace-nowrap"
            >
              {t("dataHeaderModal.noHeaderRow")}
            </Button>
          )}
        </div>

        <Card>
          <div className="overflow-auto">
            <table className="!border border-ice-300 w-full">
              <thead className="bg-ice-50 !border-b border-ice-300">
                <tr className="divide-x divide-ice-300 ">
                  <th style={headerStyles}>&nbsp;</th>
                  {dataPreview?.[0]?.map((_: any, index: number) => (
                    <th
                      key={`header-${index}`}
                      className="px-2 text-left !text-sm"
                      data-cy={`data-metadata-header-${index}`}
                      style={headerStyles}
                    >
                      {getLetterFromIndex(index)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="relative">
                <tr
                  className="h-[1.55rem] w-full !border border-blue-500 bg-[#0082ff10] absolute top-0 right-0 z-10 transition"
                  style={{
                    transform: `translateY(${(headerSelectRow ?? 0) * 1.5}rem)`,
                  }}
                >
                  <td colSpan={dataPreview?.[0]?.length}>&nbsp;</td>
                </tr>
                {dataPreview?.map((row: any, rowIndex: number) => (
                  <tr
                    key={rowIndex}
                    className="group divide-x divide-ice-200 border-ice-200 !border-b hover:bg-ice-100"
                    role="button"
                    onClick={() => setHeaderSelectRow(rowIndex)}
                  >
                    <td className="text-center py-0 px-2 !text-sm w-10 bg-ice-50 h-6 !border-b border-ice-200 group-hover:bg-ice-100">
                      {rowIndex + 1}
                    </td>
                    {row?.map((cell: any, cellIndex: number) => (
                      <td
                        key={cellIndex}
                        className={twMerge(
                          "px-2 !text-sm h-6 whitespace-nowrap transition-all",
                          (
                            headerSelectRow !== null
                              ? headerSelectRow === rowIndex
                              : rowIndex === 0
                          )
                            ? "font-bold"
                            : "font-normal"
                        )}
                        data-cy={`data-metadata-cell-${
                          rowIndex + 1
                        }-${getLetterFromIndex(cellIndex)}`}
                      >
                        <Text type="body" as="span" className="!text-sm">
                          {cell}
                        </Text>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>
      </Modal>
    </div>
  );
}

function getLetterFromIndex(index: number) {
  return String.fromCharCode(65 + index);
}
