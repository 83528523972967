import React, { useEffect, useMemo, useState } from "react";

// Bootstrap
import "bootstrap/dist/css/bootstrap.css";

// Constants
import { EFileUploadStage } from "../../constants/EFileUploadStage";

import ColumnMatchCard from "./ColumnMatchCard";
import ColumnMatchCardLegacy from "./ColumnMatchCardLegacy";

// Props
import { IDeveloperSettingsStrict } from "../../interfaces";
import { transpose } from "../../helpers/TableHelpers";
import {
  COLUMN_MATCH_MODAL_PREVIEW_NUM_ROWS,
  VERSION_TWO,
} from "../../constants/constants";
import {
  selectDuplicateColumnMappings,
  selectMissingRequiredFieldKeys,
} from "../../helpers/FieldHelpers";
import { useTranslation } from "react-i18next";
import {
  selectGlobalStyle,
  selectAIMatching,
} from "../../store/reducers/settings";
import { matchColumns } from "../../thunks/column_matching";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ColumnMatchLegacy } from "./ColumnMatchLegacy";
import { ColumnMatchView } from "./ColumnMatchView";
import EmptyFieldCard from "./EmptyFieldCard";
import { setRehydrationComplete } from "../../store/reducers/modals";
import {
  selectMatchableFieldSpecs,
  mapColumn,
  mergeIgnoredColumns,
  addEmptyField,
  removeEmptyField,
} from "../../store/reducers/fields";
import { setModalStage } from "../../thunks/modal_stage";
import { handleCancelModal } from "../../thunks/parent_connection_handlers";
import { useParentConnectionContext } from "../ParentConnectionContext";
import { selectMappedSelectSpecs } from "../../store/selectors";

export interface IAlertModal {
  show: boolean;
  nextModalStage: EFileUploadStage | "cancel";
}

const ColumnMatchModal = () => {
  const connection = useParentConnectionContext();
  const { t } = useTranslation();
  const [alertModal, setAlertModal] = useState<IAlertModal>({
    show: false,
    nextModalStage: EFileUploadStage.DATA_REVIEW,
  });
  const [dataPreview, setDataPreview] = useState<Array<Array<any>> | null>(
    null
  );
  const dispatch = useAppDispatch();
  const {
    data,
    columnMapping,
    headers,
    stepSettings,
    globalStyle,
    useAIColumnMatching,
    aiMatchStatus,
    missingRequiredFieldKeys,
    version,
    allowCustomFields,
    addedEmptyFieldKeys,
    rehydrateStage,
    rehydrationComplete,
    matchableFields,
    duplicateColumnMappings,
    matchedAnySelectFields,
  } = useAppSelector((state) => ({
    data: state.coredata.data,
    columnMapping: state.fields.columnMapping,
    headers: state.coredata.headers,
    stepSettings: state.settings.matchingStep,
    globalStyle: selectGlobalStyle(state),
    useAIColumnMatching: selectAIMatching(state),
    aiMatchStatus: state.coredata.aiColMatchStatus,
    version: state.settings.version,
    allowCustomFields: state.settings.allowCustomFields,
    missingRequiredFieldKeys: selectMissingRequiredFieldKeys(state),
    addedEmptyFieldKeys: state.fields.addedEmptyFields,
    rehydrateStage: state.modals.rehydrateStage,
    rehydrationComplete: state.modals.rehydrationComplete,
    matchableFields: selectMatchableFieldSpecs(state.fields),
    duplicateColumnMappings: selectDuplicateColumnMappings(state),
    matchedAnySelectFields: selectMappedSelectSpecs(state).size > 0,
  }));
  const [filterMatchedColumns, setFilterMatchedColumns] = useState(false);
  const [areAllColumnsMatched, setAreAllColumnsMatched] = useState(false);
  const isRedesign = version === VERSION_TWO;

  const columns = transpose(dataPreview);

  useEffect(() => {
    const allColumnsAreMatched = columns.every((_, index) =>
      columnMapping.has(index)
    );

    if (allColumnsAreMatched) {
      setFilterMatchedColumns(false);
      setAreAllColumnsMatched(true);
    } else {
      setAreAllColumnsMatched(false);
    }
  }, [columnMapping, columns]);

  const toggleFilterMatchedColumns = () => {
    setFilterMatchedColumns(!filterMatchedColumns);
  };

  useEffect(() => {
    async function setColumnMappingAndDataPreview() {
      const tmpData: any[] = data.previewData.slice(
        0,
        COLUMN_MATCH_MODAL_PREVIEW_NUM_ROWS
      );

      // check if columnMapping size is 0 in case someone comes back to this modal
      // so we do not overwrite their existing columnMapping
      if (columnMapping.size === 0 && headers) {
        await dispatch(matchColumns());
      }

      setDataPreview(tmpData);
    }

    setColumnMappingAndDataPreview();
  }, [columnMapping.size, data.previewData, dispatch, headers]);

  useEffect(() => {
    if (rehydrateStage === "COLUMN_MATCHING" && !rehydrationComplete) {
      dispatch(setRehydrationComplete());
    }
  }, [rehydrateStage, dispatch, rehydrationComplete]);

  const getLabelByKey = (key: string): string => {
    const field = matchableFields.find((f) => f.key === key);
    return field ? field.label : key;
  };

  const getAlertModalData = () => {
    let alertModalMessage = t("common.clearAllProgressAlert");
    let alertModalShowSecondaryButton = true;
    let blockNextButton = false;

    if (
      alertModal.nextModalStage === EFileUploadStage.DATA_UPLOAD ||
      alertModal.nextModalStage === EFileUploadStage.DATA_METADATA ||
      alertModal.nextModalStage === "cancel"
    ) {
      return {
        blockNextButton,
        alertModalMessage,
        alertModalShowSecondaryButton,
      };
    }

    if (duplicateColumnMappings.length > 0) {
      alertModalMessage = `${t(
        "columnMatchModal.duplicateFieldsAlert"
      )} ${duplicateColumnMappings.join(", ")}`;
      alertModalShowSecondaryButton = false;
      blockNextButton = true;
    } else if (missingRequiredFieldKeys.length > 0) {
      alertModalMessage = `${t(
        "v1.columnMatchModal.requiredFieldsAlert"
      )} ${missingRequiredFieldKeys.map(getLabelByKey).join(", ")}`;
      alertModalShowSecondaryButton = false;
      blockNextButton = true;
    }

    return {
      blockNextButton,
      alertModalMessage,
      alertModalShowSecondaryButton,
    };
  };

  const ignoreUnmapped = () => {
    if (dataPreview === null) return;

    const ignoreCols = new Set<number>();
    for (const colIndex of dataPreview[0].keys()) {
      if (!columnMapping.has(colIndex)) {
        ignoreCols.add(colIndex);
      }
    }

    dispatch(mergeIgnoredColumns(ignoreCols));
  };

  const { blockNextButton, alertModalMessage, alertModalShowSecondaryButton } =
    getAlertModalData();

  const handleAddEmptyColumn = (columnKey: string) => {
    dispatch(addEmptyField(columnKey));
  };

  const handleRemoveEmptyColumn = (columnKey: string) => {
    dispatch(removeEmptyField(columnKey));
  };

  // Index is the index of the column in the original CSV
  const cards = columns.map((columnData: Array<any>, columnIndex: number) => {
    const pctHasValue = data.percentHasValueInColumn?.get(columnIndex);
    const isUnmatched = !columnMapping.has(columnIndex);
    const hideCard =
      (!(headers || [])[columnIndex] && pctHasValue === 0) ||
      (filterMatchedColumns ? !isUnmatched : false);

    return hideCard ? null : isRedesign ? (
      <ColumnMatchCard
        key={columnIndex}
        columnIndex={columnIndex}
        columnData={columnData}
      />
    ) : (
      <ColumnMatchCardLegacy
        key={columnIndex}
        columnIndex={columnIndex}
        columnData={columnData}
      />
    );
  });

  const mappedFieldKeys = useMemo(() => {
    return new Set([...columnMapping.values()].map(({ key }) => key));
  }, [columnMapping]);

  const emptyFieldCards = [...addedEmptyFieldKeys].map((key) => {
    const wasMatched = mappedFieldKeys.has(key);

    return (
      <EmptyFieldCard
        key={key}
        name={key}
        label={getLabelByKey(key)}
        onRemoveEmptyColumn={() => handleRemoveEmptyColumn(key)}
        error={wasMatched ? t("columnMatchModal.duplicateFieldsAlert") : ""}
      />
    );
  });

  const previousStage =
    stepSettings.headerRowOverride !== null || dataPreview?.length === 1
      ? EFileUploadStage.DATA_UPLOAD
      : EFileUploadStage.DATA_METADATA;

  const handleGoBack = () => {
    setAlertModal({
      show: true,
      nextModalStage: previousStage,
    });
  };

  // Show the back button unles we're rehydrated and the previousStage is the data upload modal
  const hideBackButton =
    rehydrateStage !== null && previousStage === EFileUploadStage.DATA_UPLOAD;

  const handleNextClick = () => {
    const nextModalStage =
      matchedAnySelectFields && isRedesign
        ? EFileUploadStage.AUTO_CLEAN_DATA
        : EFileUploadStage.DATA_REVIEW;

    if (blockNextButton) {
      setAlertModal({
        show: true,
        nextModalStage,
      });
    } else {
      ignoreUnmapped();
      dispatch(setModalStage(nextModalStage));
    }
  };

  const handleShowAlert = (show: boolean) =>
    setAlertModal({
      show,
      nextModalStage: "cancel",
    });

  const handleAlertPrimaryButtonClick = () => {
    if (blockNextButton) {
      setAlertModal({
        show: false,
        nextModalStage: EFileUploadStage.COLUMN_MATCH,
      });
    } else if (alertModal.nextModalStage === "cancel") {
      dispatch(handleCancelModal(connection));
    } else {
      dispatch(setModalStage(alertModal.nextModalStage));
    }
  };

  const handleAlertSecondaryButtonClick = () =>
    setAlertModal({
      show: false,
      nextModalStage: EFileUploadStage.DATA_REVIEW,
    });

  const handleHideModal = () => {
    setAlertModal({
      show: true,
      nextModalStage: "cancel",
    });
  };

  const getHeaderWithNoDuplicates = (header: string) => {
    const existingHeaders = matchableFields.map((f) => f.label);
    let newHeader = header;
    let i = 1;

    while (existingHeaders.includes(newHeader)) {
      newHeader = `${header} ${i}`;
      i++;
    }

    return newHeader;
  };

  const handleAddAllUnmatchedAsCustomFields = () => {
    columns.forEach((_, index) => {
      if (columnMapping.has(index)) return;
      const header = getHeaderWithNoDuplicates(
        headers !== null ? headers[index] : "Column"
      );

      dispatch(mapColumn(index, header, "CUSTOM"));
    });
  };

  const showSpinner =
    useAIColumnMatching === true &&
    headers !== null &&
    columns.length === 0 &&
    aiMatchStatus !== "fulfilled";

  const props = {
    alertModal,
    blockNextButton,
    alertModalMessage,
    stepSettings,
    alertModalShowSecondaryButton,
    globalStyle,
    showSpinner,
    cards,
    onGoBack: handleGoBack,
    onNextClick: handleNextClick,
    onShowAlert: handleShowAlert,
    onAlertPrimaryButtonClick: handleAlertPrimaryButtonClick,
    onAlertSecondaryButtonClick: handleAlertSecondaryButtonClick,
    onHideModal: handleHideModal,
    toggleFilterMatchedColumns,
    filterMatchedColumns,
    areAllColumnsMatched,
    onAddAllUnmatchedAsCustomFields: handleAddAllUnmatchedAsCustomFields,
    allowCustomFields,
    missingRequiredFieldKeys,
    onAddEmptyColumn: handleAddEmptyColumn,
    onRemoveEmptyColumn: handleRemoveEmptyColumn,
    getLabelByKey,
    emptyFieldCards,
    duplicates: duplicateColumnMappings,
    hideBackButton,
  };

  return version === VERSION_TWO ? (
    <ColumnMatchView {...props} />
  ) : (
    <ColumnMatchLegacy {...props} />
  );
};

export interface UIColumnMatchProps {
  alertModal: IAlertModal;
  blockNextButton: boolean;
  alertModalMessage: string;
  stepSettings: IDeveloperSettingsStrict["matchingStep"];
  alertModalShowSecondaryButton: boolean;
  globalStyle: { color: string };
  cards: (JSX.Element | null)[];
  emptyFieldCards: (JSX.Element | null)[];
  showSpinner: boolean;
  onGoBack: () => void;
  onNextClick: () => void;
  onShowAlert: (show: boolean) => void;
  onAlertPrimaryButtonClick: () => void;
  onAlertSecondaryButtonClick: () => void;
  onHideModal: () => void;
  toggleFilterMatchedColumns: () => void;
  filterMatchedColumns: boolean;
  areAllColumnsMatched: boolean;
  onAddAllUnmatchedAsCustomFields: () => void;
  allowCustomFields: boolean;
  missingRequiredFieldKeys: string[];
  onAddEmptyColumn: (columnKey: string) => void;
  onRemoveEmptyColumn: (columnKey: string) => void;
  getLabelByKey: (key: string) => string;
  duplicates: string[];
  hideBackButton: boolean;
}

export default ColumnMatchModal;
